// -----------------------------------------------------------------------------
// This file contains all styles related to the input element.
// -----------------------------------------------------------------------------

.subnav {
  position: relative;
  background-color: $color-text;
  ul {
    position: relative;
    li {
      padding: 10px 14px;
      position: relative;
      display: inline-block;
      a {
        color: $color-footer-link;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
      &.active {
        a {
          color: #fff;
        }
        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 14px;
          right: 14px;
          height: 4px;
          background: #cacccd;
        }
      }
    }
    &::after {
      clear: both;
    }
  }
}
