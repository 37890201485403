// -----------------------------------------------------------------------------
// This file contains all styles related to the card component.
// -----------------------------------------------------------------------------
.intro {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 37.5px;
  h3 {
    font-size: $font-size-h1 !important;
    font-weight: bold;
    margin-bottom: 7px;
  }
  p {
    font-size: $font-size-subhead;
    margin-bottom: 15px;
  }
}
.photo-card {
  text-align: center;
  background-color: $color-content;
  background-size: cover;
  background-position: center center;
  margin-bottom: 17px;
  height: 340px;
  a {
    text-shadow: 0px 0px 5px rgba($color-grey-black, 1);
    display: block;
    height: 100%;
    width: 100%;
    color: $color-grey-white;
    &:hover {
      text-decoration: none;
      color: $color-grey-light;
      text-shadow: 0px 0px 7.5px rgba($color-grey-black, 1);
    }
    h4 {
      font-size: $font-size-h1;
      font-weight: bold;
      @include vertical-align();
    }
  }
}
