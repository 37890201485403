// -----------------------------------------------------------------------------
// This file contains all styles related to the search modal.
// -----------------------------------------------------------------------------

.loader-container {
  display: none;
  &.loader-spinner-container {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    .icon {
      font-size: $font-size-h1;
    }
    .message {
      margin-top: 10px;
    }
  }
  &.loader-icon-container {
    height: 100%;
    padding-top: 77px;
    min-height: 209px;
    padding-bottom: 55px;
  }
  &.loader-modal-container {
    height: 100%;
    width: 100%;
  }
  &.loader-show {
    display: block;
  }
  .loader-icon {
    &,
    &:before,
    &:after {
      background: $color-grey-white;
      -webkit-animation: load1 1s infinite ease-in-out;
      animation: load1 1s infinite ease-in-out;
      width: 1em;
      height: 4em;
    }
    &:before,
    &:after {
      position: absolute;
      top: 0;
      content: '';
    }
    &:before {
      left: -1.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    & {
      color: $color-grey-white;
      text-indent: -9999em;
      margin: 0 auto;
      position: relative;
      font-size: 11px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    &:after {
      left: 1.5em;
    }
  }
  .loader-modal {
    height:100%;
    width:100%;
    overflow:hidden;
    position: fixed;
    background-color: $color-brand-primary;
    z-index: 99999;
  }
}
