// -----------------------------------------------------------------------------
// This file contains grid system and other layout related stuffs.
// -----------------------------------------------------------------------------

/// Container's maximum width
/// @type Length
$container-width: 1080px !default;

/**
 * Main content containers
 */
.container {
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
*/
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**
 * Clear inner floats
 */
.clearfix::after {
   clear: both;
   content: '';
   display: table;
}

/**
 * Grid setup
 *
 * The grid will calculate dimensions based on these two variables:
 * $fg-columns will inform the grid loops how many columns there should be.
 * $fg-gutter will inform the grid loops how big eac column's gutters should be.
 *
 * The grid will name columns, rows, offsets based on these three variables:
 * $fg-class-row string used for the row class
 * $fg-class-col string used for the column class
 * $fg-class-off string used for the offset class
 */
$fg-columns: 12 !default;
$fg-gutter: 0.5rem !default;

$fg-class-row: 'row' !default;
$fg-class-col: 'col' !default;
$fg-class-off: 'off' !default;
$fg-class-padding: 'padding' !default;
$fg-class-hidden: 'hidden' !default;
$fg-class-visible: 'visible' !default;
$fg-class-remove: 'rm' !default;
$fg-class-restore: 're' !default;



/**
 * Break point namespace object
 *
 * Set the default namespace object with these defaults with the
 * understanding that you can pass in whatever you might require for your site.
 *
 * $fg-breakpoints is a Sass list with nested lists inside. Each sub list defines two things.
 * 1. The namespace for that breakpoint. (Required) (i.e. xs, sm, md, lg)
 * 2. The min-width measurement for the breakpoint for that namespace. (i.e. 48em, 62em, 75em)
 *
 * Note: These should be in the proper order (at least till libsass handles map keys properly).
 *
 * Note: If the measurement is left out then it will be skipped when generating
 * the grid and applied to global styles.
 *
 */
$breakpoints: (
  sm: 768px,
  md: 992px,
  lg: 1200px
) !default;

$fg-breakpoints: (
  (xs),
  (sm, 768px),
  (md, 992px),
  (lg, 1200px)
) !default;

/**
 * Class Name Defaults
 *
 * Define class names for columns, rows and offsets in case compatibility with other
 * libraries is necessary.


/**
 * Calculate column size percentage
 */
@function get-col-percent($column-num) {
  @return $column-num / $fg-columns * 100%;
}

/**
 * Spacing mixin to create uniform margin/padding
 */
@mixin spacing( $value, $type: margin, $orientation: vertical ) {
  @if $orientation == vertical {
    #{ $type }-top: $value;
    #{ $type }-bottom: $value;
  } @else {
    #{ $type }-left: $value;
    #{ $type }-right: $value;
  }
}

/**
 * Row wrapper class, flex box parent.
 */
.#{ $fg-class-row } {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include spacing( ( -$fg-gutter ), margin, horizontal );
}


%#{ $fg-class-col } {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  @include spacing( ( $fg-gutter ), padding, horizontal );
}

%#{ $fg-class-col }-flex {
  @extend %#{ $fg-class-col };
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}


/**
 * Generate a set of grid column classes using a namespace
 *
 * .#{ $fg-class-col }-[namespace] for intelligent column division
 * .#{ $fg-class-col }-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
 * .#{ $fg-class-off }-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
 */

@mixin grid-base( $namespace ) {
  .#{ $fg-class-col }-#{$namespace} {
    @extend %#{ $fg-class-col }-flex;
  }

  @for $i from 1 through $fg-columns {
    .#{ $fg-class-col }-#{$namespace}-#{$i} {
      @extend %#{ $fg-class-col };
    }
  }
}

@mixin grid-sizes( $namespace ) {

  // Run the loop for as many columns as speficied
  @for $i from 1 through $fg-columns {
    .#{ $fg-class-col }-#{$namespace}-#{$i} {
      flex-basis: get-col-percent($i);
      max-width:  get-col-percent($i);
    }
  }

  @for $i from 1 through $fg-columns {

    // Don't include the .off class for the last column
    @if ( $i != $fg-columns ) {
      .#{ $fg-class-off }-#{$namespace}-#{$i} {
        margin-left: get-col-percent($i);
      }
    }

  }

}


/**
 * Build the grid in two steps, to help minimize file size
 * Step 1, for each namespace, create the grid-base
 * Step 2, for each namespace, wrap the col width/offset measurements in their breakpoint media query
 */
@each $val in $fg-breakpoints {
  $namespace: nth($val, 1);
  @include grid-base( $namespace );
}

@each $val in $fg-breakpoints {
  @if length($val) == 1 {

    $namespace: nth($val, 1);
    @include grid-sizes( $namespace );

  } @else {

    $namespace: nth($val, 1);
    $size: nth($val, 2);

    @media only screen and (min-width: #{$size}) {
      @include grid-sizes( $namespace );
    }
  }
}

/**
 * Build the class for hide/show columns
 */
@each $val in $fg-breakpoints {
  @if length($val) == 1 {

    $namespace: nth($val, 1);

    .#{ $fg-class-hidden }-#{$namespace} {
      display: none !important;
    }
    .#{ $fg-class-visible }-#{$namespace} {
      display: inherit !important;
    }

  } @else {

    $namespace: nth($val, 1);
    $size: nth($val, 2);

    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-hidden }-#{$namespace} {
        display: none !important;
      }
    }
    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-visible }-#{$namespace} {
        display: inherit !important;
      }
    }
  }
}

/**
 * Build the class for remove and restore columns padding (both right and left)
 */

@each $val in $fg-breakpoints {
  @if length($val) == 1 {

    $namespace: nth($val, 1);

    .#{ $fg-class-remove }-#{$fg-class-padding}-#{$namespace} {
      padding-left: 0;
      padding-right: 0;
    }
    .#{ $fg-class-restore }-#{$fg-class-padding}-#{$namespace} {
      padding-left: $fg-gutter;
      padding-right: $fg-gutter;
    }

  } @else {

    $namespace: nth($val, 1);
    $size: nth($val, 2);

    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-remove }-#{$fg-class-padding}-#{$namespace} {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-restore }-#{$fg-class-padding}-#{$namespace} {
        padding-left: $fg-gutter;
        padding-right: $fg-gutter;
      }
    }
  }
}

/**
 * Build the class for remove/restore columns padding (right only)
 */
@each $val in $fg-breakpoints {
  @if length($val) == 1 {

    $namespace: nth($val, 1);

    .#{ $fg-class-remove }-#{$fg-class-padding}-right-#{$namespace} {
      padding-right: 0;
    }
    .#{ $fg-class-restore }-#{$fg-class-padding}-right-#{$namespace} {
      padding-right: $fg-gutter;
    }

  } @else {

    $namespace: nth($val, 1);
    $size: nth($val, 2);

    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-remove }-#{$fg-class-padding}-right-#{$namespace} {
        padding-right: 0;
      }
    }
    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-restore }-#{$fg-class-padding}-right-#{$namespace} {
        padding-right: $fg-gutter;
      }
    }
  }
}

/**
 * Build the class for remove/restore columns padding (left only)
 */
@each $val in $fg-breakpoints {
  @if length($val) == 1 {

    $namespace: nth($val, 1);

    .#{ $fg-class-remove }-#{$fg-class-padding}-left-#{$namespace} {
      padding-left: 0;
    }
    .#{ $fg-class-restore }-#{$fg-class-padding}-left-#{$namespace} {
      padding-left: $fg-gutter;
    }

  } @else {

    $namespace: nth($val, 1);
    $size: nth($val, 2);

    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-remove }-#{$fg-class-padding}-left-#{$namespace} {
        padding-left: 0;
      }
    }
    @media only screen and (min-width: #{$size}) {
      .#{ $fg-class-restore }-#{$fg-class-padding}-left-#{$namespace} {
        padding-left: $fg-gutter;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
