/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import '_sass/abstracts/___mixins.scss';
@import '_sass/abstracts/___paths.scss';
@import '_sass/abstracts/__colors.scss';
@import '_sass/abstracts/__layout.scss';
@import '_sass/abstracts/__typography.scss';
@import '_sass/base/__bootstrap.scss';
@import '_sass/base/__reset.scss';
@import '_sass/base/_base.scss';
@import '_sass/components/_animations.scss';
@import '_sass/components/_breadcumb.scss';
@import '_sass/components/_button.scss';
@import '_sass/components/_card.scss';
@import '_sass/components/_divider.scss';
@import '_sass/components/_fonts.scss';
@import '_sass/components/_horizontal-trip-steps.scss';
@import '_sass/components/_icons.scss';
@import '_sass/components/_input.scss';
@import '_sass/components/_jumbo.scss';
@import '_sass/components/_label.scss';
@import '_sass/components/_loader.scss';
@import '_sass/components/_mapview.scss';
@import '_sass/components/_modal.scss';
@import '_sass/components/_slider.scss';
@import '_sass/components/_subnav.scss';
@import '_sass/components/_tooltip.scss';
@import '_sass/components/_trip-steps.scss';
@import '_sass/components/_whiteframe.scss';
@import 'components/alert/alert.modal.scss';
@import 'components/attachment/attachment.scss';
@import 'components/border-maps/maps.scss';
@import 'components/colorpicker/colorpicker.scss';
@import 'components/cookielaw/cookielaw.scss';
@import 'components/datepicker/angular-datepicker.scss';
@import 'components/dropdown/dropdown.scss';
@import 'components/filter-save/filter.modal.scss';
@import 'components/maps/maps.scss';
@import 'components/markers/markers.scss';
@import 'components/notifications/notifications.scss';
@import 'components/placepicker-fast/placepicker.scss';
@import 'components/placepicker-slim/placepicker.scss';
@import 'components/placepicker/placepicker.scss';
@import 'components/quote-details/classic/classic.scss';
@import 'components/quote-details/new/new.scss';
@import 'components/quote-details/quote-details.scss';
@import 'components/quote-details/tour/tour.scss';
@import 'components/simple-dropdown/simple.dropdown.scss';
@import 'components/slider/slider.scss';
@import 'components/supplier-map/supplier-map.scss';
@import 'components/table/table.scss';
@import 'components/timepicker/timepicker.scss';
@import 'components/uploader-doc/uploader.scss';
@import 'components/uploader/uploader.scss';
@import 'elements/auth/auth.scss';
@import 'elements/footer/footer.scss';
@import 'elements/light-planner/light-planner.scss';
@import 'elements/navbar/navbar.modal.scss';
@import 'elements/navbar/navbar.scss';
@import 'elements/planner-shuttle/planner.scss';
@import 'elements/planner-topic/planner.scss';
@import 'elements/planner-topic/services/bus/bus.scss';
@import 'elements/planner-topic/services/citytour/citytour.scss';
@import 'elements/planner-topic/services/disposal/disposal.scss';
@import 'elements/planner-topic/services/end/end.scss';
@import 'elements/planner-topic/services/pickup/pickup.scss';
@import 'elements/planner-topic/services/return/return.scss';
@import 'elements/planner-topic/services/shuttle/shuttle.scss';
@import 'elements/planner-topic/services/transfer/transfer.scss';
@import 'elements/planner-topic/services/transit/transit.scss';
@import 'elements/planner-tour/planner.scss';
@import 'elements/planner/planner.scss';
@import 'views/admin/accepted.scss';
@import 'views/admin/book/attachments/attachments.scss';
@import 'views/admin/book/driver/driver.scss';
@import 'views/admin/book/drivers/drivers.scss';
@import 'views/admin/book/email/email.scss';
@import 'views/admin/book/network/network.scss';
@import 'views/admin/book/newquote.scss';
@import 'views/admin/book/overview/overview.scss';
@import 'views/admin/book/partners/partners.scss';
@import 'views/admin/book/partners/phone/alert.modal.scss';
@import 'views/admin/book/partners/sms/alert.modal.scss';
@import 'views/admin/book/plan/plan.scss';
@import 'views/admin/book/price/price.scss';
@import 'views/admin/book/publish/publish.scss';
@import 'views/admin/book/standards/standards.scss';
@import 'views/admin/book/supplier/supplier.scss';
@import 'views/admin/book/users/users.scss';
@import 'views/admin/collaborators.scss';
@import 'views/admin/credentials.scss';
@import 'views/admin/dashboard.scss';
@import 'views/admin/edit/supplier/edit.scss';
@import 'views/admin/edit/supplier/pricing/modal/alert.modal.scss';
@import 'views/admin/edit/supplier/pricing/pricing.scss';
@import 'views/admin/edit/supplier/step0/subscription.scss';
@import 'views/admin/edit/supplier/step1/name.scss';
@import 'views/admin/edit/supplier/step2/poc.scss';
@import 'views/admin/edit/supplier/step3/company.scss';
@import 'views/admin/edit/supplier/step4/cheer.scss';
@import 'views/admin/edit/supplier/step5/additional.scss';
@import 'views/admin/edit/supplier/step6/firstcompleted.scss';
@import 'views/admin/edit/supplier/step7/notification.scss';
@import 'views/admin/edit/supplier/step8/requesttypes.scss';
@import 'views/admin/edit/supplier/step9/vehicle.scss';
@import 'views/admin/edit/supplier/vehicles.scss';
@import 'views/admin/external/supplier.scss';
@import 'views/admin/locations/dashboard.scss';
@import 'views/admin/locations/location/location.scss';
@import 'views/admin/places.scss';
@import 'views/admin/priceranges/dashboard.scss';
@import 'views/admin/pricing/dashboard.scss';
@import 'views/admin/pricing/hub/hub.scss';
@import 'views/admin/profile.scss';
@import 'views/admin/quotations/dashboard.scss';
@import 'views/admin/quotations/resultquotation/prettyprint/prettyprint.scss';
@import 'views/admin/quotations/resultquotation/resultquotation.scss';
@import 'views/admin/quotes.scss';
@import 'views/admin/reviews.scss';
@import 'views/admin/standardvehicles/dashboard.scss';
@import 'views/admin/standardvehicles/standardvehicle/standardvehicle.scss';
@import 'views/admin/tables/partner/partner.scss';
@import 'views/admin/translations/dashboard.scss';
@import 'views/admin/users.scss';
@import 'views/admin/verify.scss';
@import 'views/landing/e404.scss';
// endinjector
