// -----------------------------------------------------------------------------
// This file contains all styles related to the trip component.
// -----------------------------------------------------------------------------
.horizontal-trip-steps {
  cursor: grab;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  font-size: $font-size-regular;
  line-height: $font-size-regular;
  text-align: center;
  ul {
    white-space: nowrap;
    display: inline-block;
    margin: 0 auto;
  }
  li {
    position: relative;
    display: inline-block;
  }
  .horizontal-trip-steps-timeline {
    &.layover {
      min-width: 125px;
      &:before {
        content: ' ';
        position: absolute;
        height: 4px;
        width: 225px;
        border-top: $color-border 4px dotted;
        top: (3*$font-size-regular + 4px);
        left: -(100px / 2);
      }
    }
    &:not(.layover) {
      min-width: 100px * 3;
      &:before {
        content: ' ';
        position: absolute;
        height: 4px;
        width: 210px;
        border-top: $color-brand-primary 4px solid;
        top: (3*$font-size-regular + 4px);
        left: (100px / 2);
      }
      &.warehouse:before {
        border-top-color: $color-brand-light;
      }
    }
  }
  .horizontal-trip-steps-timeline {
    position: relative;
    display: inline-block;
    .horizontal-trip-steps-dot {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: ' ';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $color-grey-white;
      border: 6px solid $color-brand-primary;
      top: ((3*$font-size-regular)-(20px-4px)/2 + 4px);
      z-index: 100;
      &.warehouse {
        border-color: $color-brand-light !important;
      }
    }
    .horizontal-trip-steps-from, .horizontal-trip-steps-to, .horizontal-trip-steps-layover  {
      position: relative;
      display: inline-block;
      width: 100px;
      text-align: center;
      .horizontal-trip-steps-top {
        height: ((3*$font-size-regular)-(20px/2));
        margin-top: 4px;
        margin-bottom: (20px + 4px);
      }
      .horizontal-trip-steps-bottom {
        height: (2*$font-size-regular);
        margin-top: (20px + 4px);
        margin-bottom: 4px;
        font-size: $font-size-small;
      }
    }
    .horizontal-trip-steps-info {
      position: relative;
      display: inline-block;
      width: 100px;
      text-align: center;
      font-size: $font-size-small;
      .horizontal-trip-steps-top {
        height: ((3*$font-size-regular)-(20px/2));
        margin-top: 4px;
        margin-bottom: (20px + 4px);
        padding-top: 20px
      }
      .horizontal-trip-steps-bottom {
        height: (2*$font-size-regular);
        margin-top: (20px + 4px);
        margin-bottom: 4px;
      }
    }
    &.layover {
      .horizontal-trip-steps-info {
        width: 125px;
      }
    }
  }
}
