@charset "UTF-8";
/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
/**
 * Main content containers
 */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
.container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
*/
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap; }

/**
 * Hide element while making it readable for screen readers
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table; }

/**
 * Grid setup
 *
 * The grid will calculate dimensions based on these two variables:
 * $fg-columns will inform the grid loops how many columns there should be.
 * $fg-gutter will inform the grid loops how big eac column's gutters should be.
 *
 * The grid will name columns, rows, offsets based on these three variables:
 * $fg-class-row string used for the row class
 * $fg-class-col string used for the column class
 * $fg-class-off string used for the offset class
 */
/**
 * Break point namespace object
 *
 * Set the default namespace object with these defaults with the
 * understanding that you can pass in whatever you might require for your site.
 *
 * $fg-breakpoints is a Sass list with nested lists inside. Each sub list defines two things.
 * 1. The namespace for that breakpoint. (Required) (i.e. xs, sm, md, lg)
 * 2. The min-width measurement for the breakpoint for that namespace. (i.e. 48em, 62em, 75em)
 *
 * Note: These should be in the proper order (at least till libsass handles map keys properly).
 *
 * Note: If the measurement is left out then it will be skipped when generating
 * the grid and applied to global styles.
 *
 */
/**
 * Class Name Defaults
 *
 * Define class names for columns, rows and offsets in case compatibility with other
 * libraries is necessary.


/**
 * Calculate column size percentage
 */
/**
 * Spacing mixin to create uniform margin/padding
 */
/**
 * Row wrapper class, flex box parent.
 */
.row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.col-xs, .col-sm, .col-md, .col-lg, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.col-xs, .col-sm, .col-md, .col-lg {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

/**
 * Generate a set of grid column classes using a namespace
 *
 * .col-[namespace] for intelligent column division
 * .col-[namespace]-[number] for a column that covers a specific number of columns (e.g. 1-12 by default)
 * .off-[namespace]-[number] for pushing a col a specific number of columns (e.g. 1-11 by default)
 */
/**
 * Build the grid in two steps, to help minimize file size
 * Step 1, for each namespace, create the grid-base
 * Step 2, for each namespace, wrap the col width/offset measurements in their breakpoint media query
 */
.col-xs-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.off-xs-1 {
  margin-left: 8.33333%; }

.off-xs-2 {
  margin-left: 16.66667%; }

.off-xs-3 {
  margin-left: 25%; }

.off-xs-4 {
  margin-left: 33.33333%; }

.off-xs-5 {
  margin-left: 41.66667%; }

.off-xs-6 {
  margin-left: 50%; }

.off-xs-7 {
  margin-left: 58.33333%; }

.off-xs-8 {
  margin-left: 66.66667%; }

.off-xs-9 {
  margin-left: 75%; }

.off-xs-10 {
  margin-left: 83.33333%; }

.off-xs-11 {
  margin-left: 91.66667%; }

@media only screen and (min-width: 768px) {
  .col-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-sm-1 {
    margin-left: 8.33333%; }
  .off-sm-2 {
    margin-left: 16.66667%; }
  .off-sm-3 {
    margin-left: 25%; }
  .off-sm-4 {
    margin-left: 33.33333%; }
  .off-sm-5 {
    margin-left: 41.66667%; }
  .off-sm-6 {
    margin-left: 50%; }
  .off-sm-7 {
    margin-left: 58.33333%; }
  .off-sm-8 {
    margin-left: 66.66667%; }
  .off-sm-9 {
    margin-left: 75%; }
  .off-sm-10 {
    margin-left: 83.33333%; }
  .off-sm-11 {
    margin-left: 91.66667%; } }

@media only screen and (min-width: 992px) {
  .col-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-md-1 {
    margin-left: 8.33333%; }
  .off-md-2 {
    margin-left: 16.66667%; }
  .off-md-3 {
    margin-left: 25%; }
  .off-md-4 {
    margin-left: 33.33333%; }
  .off-md-5 {
    margin-left: 41.66667%; }
  .off-md-6 {
    margin-left: 50%; }
  .off-md-7 {
    margin-left: 58.33333%; }
  .off-md-8 {
    margin-left: 66.66667%; }
  .off-md-9 {
    margin-left: 75%; }
  .off-md-10 {
    margin-left: 83.33333%; }
  .off-md-11 {
    margin-left: 91.66667%; } }

@media only screen and (min-width: 1200px) {
  .col-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .off-lg-1 {
    margin-left: 8.33333%; }
  .off-lg-2 {
    margin-left: 16.66667%; }
  .off-lg-3 {
    margin-left: 25%; }
  .off-lg-4 {
    margin-left: 33.33333%; }
  .off-lg-5 {
    margin-left: 41.66667%; }
  .off-lg-6 {
    margin-left: 50%; }
  .off-lg-7 {
    margin-left: 58.33333%; }
  .off-lg-8 {
    margin-left: 66.66667%; }
  .off-lg-9 {
    margin-left: 75%; }
  .off-lg-10 {
    margin-left: 83.33333%; }
  .off-lg-11 {
    margin-left: 91.66667%; } }

/**
 * Build the class for hide/show columns
 */
.hidden-xs {
  display: none !important; }

.visible-xs {
  display: inherit !important; }

@media only screen and (min-width: 768px) {
  .hidden-sm {
    display: none !important; } }

@media only screen and (min-width: 768px) {
  .visible-sm {
    display: inherit !important; } }

@media only screen and (min-width: 992px) {
  .hidden-md {
    display: none !important; } }

@media only screen and (min-width: 992px) {
  .visible-md {
    display: inherit !important; } }

@media only screen and (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

@media only screen and (min-width: 1200px) {
  .visible-lg {
    display: inherit !important; } }

/**
 * Build the class for remove and restore columns padding (both right and left)
 */
.rm-padding-xs {
  padding-left: 0;
  padding-right: 0; }

.re-padding-xs {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

@media only screen and (min-width: 768px) {
  .rm-padding-sm {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (min-width: 768px) {
  .re-padding-sm {
    padding-left: 0.5rem;
    padding-right: 0.5rem; } }

@media only screen and (min-width: 992px) {
  .rm-padding-md {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (min-width: 992px) {
  .re-padding-md {
    padding-left: 0.5rem;
    padding-right: 0.5rem; } }

@media only screen and (min-width: 1200px) {
  .rm-padding-lg {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (min-width: 1200px) {
  .re-padding-lg {
    padding-left: 0.5rem;
    padding-right: 0.5rem; } }

/**
 * Build the class for remove/restore columns padding (right only)
 */
.rm-padding-right-xs {
  padding-right: 0; }

.re-padding-right-xs {
  padding-right: 0.5rem; }

@media only screen and (min-width: 768px) {
  .rm-padding-right-sm {
    padding-right: 0; } }

@media only screen and (min-width: 768px) {
  .re-padding-right-sm {
    padding-right: 0.5rem; } }

@media only screen and (min-width: 992px) {
  .rm-padding-right-md {
    padding-right: 0; } }

@media only screen and (min-width: 992px) {
  .re-padding-right-md {
    padding-right: 0.5rem; } }

@media only screen and (min-width: 1200px) {
  .rm-padding-right-lg {
    padding-right: 0; } }

@media only screen and (min-width: 1200px) {
  .re-padding-right-lg {
    padding-right: 0.5rem; } }

/**
 * Build the class for remove/restore columns padding (left only)
 */
.rm-padding-left-xs {
  padding-left: 0; }

.re-padding-left-xs {
  padding-left: 0.5rem; }

@media only screen and (min-width: 768px) {
  .rm-padding-left-sm {
    padding-left: 0; } }

@media only screen and (min-width: 768px) {
  .re-padding-left-sm {
    padding-left: 0.5rem; } }

@media only screen and (min-width: 992px) {
  .rm-padding-left-md {
    padding-left: 0; } }

@media only screen and (min-width: 992px) {
  .re-padding-left-md {
    padding-left: 0.5rem; } }

@media only screen and (min-width: 1200px) {
  .rm-padding-left-lg {
    padding-left: 0; } }

@media only screen and (min-width: 1200px) {
  .re-padding-left-lg {
    padding-left: 0.5rem; } }

.clickable {
  cursor: pointer; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.bootstrap {
  /* copy the popover code from bootstrap so this will work even without it */
  /*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */ }
  .bootstrap ul, .bootstrap ol {
    list-style: disc;
    padding-left: 40px; }
  .bootstrap pre {
    font-family: inherit;
    font-size: 100%; }
  .bootstrap i {
    font-style: italic; }
  .bootstrap .input-group > .input-group-prepend {
    flex: 0 0; }
  .bootstrap .input-group > .input-group-prepend-7 {
    flex: 0 0 15%; }
  .bootstrap .input-group .input-group-text {
    width: 100%; }
  .bootstrap .ta-hidden-input {
    width: 1px;
    height: 1px;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -10000px;
    left: -10000px;
    opacity: 0;
    overflow: hidden; }
  .bootstrap .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .bootstrap .popover.top {
    margin-top: -10px; }
  .bootstrap .popover.bottom {
    margin-top: 10px; }
  .bootstrap .popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0; }
  .bootstrap .popover-content {
    padding: 9px 14px; }
  .bootstrap .popover > .arrow,
  .bootstrap .popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }
  .bootstrap .popover > .arrow {
    border-width: 11px; }
  .bootstrap .popover > .arrow:after {
    content: "";
    border-width: 10px; }
  .bootstrap .popover.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0; }
  .bootstrap .popover.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0; }
  .bootstrap .popover.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bootstrap .popover.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff; }
  .bootstrap :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .bootstrap *,
  .bootstrap *::before,
  .bootstrap *::after {
    box-sizing: border-box; }
  .bootstrap html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent; }
  .bootstrap article, .bootstrap aside, .bootstrap dialog, .bootstrap figcaption, .bootstrap figure, .bootstrap footer, .bootstrap header, .bootstrap hgroup, .bootstrap main, .bootstrap nav, .bootstrap section {
    display: block; }
  .bootstrap body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff; }
  .bootstrap [tabindex="-1"]:focus {
    outline: 0 !important; }
  .bootstrap hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  .bootstrap h1, .bootstrap h2, .bootstrap h3, .bootstrap h4, .bootstrap h5, .bootstrap h6 {
    margin-top: 0;
    margin-bottom: 0.5rem; }
  .bootstrap p {
    margin-top: 0;
    margin-bottom: 1rem; }
  .bootstrap abbr[title],
  .bootstrap abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0; }
  .bootstrap address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  .bootstrap ol,
  .bootstrap ul,
  .bootstrap dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  .bootstrap ol ol,
  .bootstrap ul ul,
  .bootstrap ol ul,
  .bootstrap ul ol {
    margin-bottom: 0; }
  .bootstrap dt {
    font-weight: 700; }
  .bootstrap dd {
    margin-bottom: .5rem;
    margin-left: 0; }
  .bootstrap blockquote {
    margin: 0 0 1rem; }
  .bootstrap dfn {
    font-style: italic; }
  .bootstrap b,
  .bootstrap strong {
    font-weight: bolder; }
  .bootstrap small {
    font-size: 80%; }
  .bootstrap sub,
  .bootstrap sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  .bootstrap sub {
    bottom: -.25em; }
  .bootstrap sup {
    top: -.5em; }
  .bootstrap a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects; }
  .bootstrap a:hover {
    color: #0056b3;
    text-decoration: underline; }
  .bootstrap a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none; }
  .bootstrap a:not([href]):not([tabindex]):hover, .bootstrap a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  .bootstrap a:not([href]):not([tabindex]):focus {
    outline: 0; }
  .bootstrap pre,
  .bootstrap code,
  .bootstrap kbd,
  .bootstrap samp {
    font-size: 1em; }
  .bootstrap pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto; }
  .bootstrap figure {
    margin: 0 0 1rem; }
  .bootstrap img {
    vertical-align: middle;
    border-style: none; }
  .bootstrap svg:not(:root) {
    overflow: hidden; }
  .bootstrap table {
    border-collapse: collapse; }
  .bootstrap caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom; }
  .bootstrap th {
    text-align: inherit; }
  .bootstrap label {
    display: inline-block;
    margin-bottom: .5rem; }
  .bootstrap button {
    border-radius: 0; }
  .bootstrap button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .bootstrap input,
  .bootstrap button,
  .bootstrap select,
  .bootstrap optgroup,
  .bootstrap textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  .bootstrap button,
  .bootstrap input {
    overflow: visible; }
  .bootstrap button,
  .bootstrap select {
    text-transform: none; }
  .bootstrap button,
  .bootstrap html [type="button"],
  .bootstrap [type="reset"],
  .bootstrap [type="submit"] {
    -webkit-appearance: button; }
  .bootstrap button::-moz-focus-inner,
  .bootstrap [type="button"]::-moz-focus-inner,
  .bootstrap [type="reset"]::-moz-focus-inner,
  .bootstrap [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  .bootstrap input[type="radio"],
  .bootstrap input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0; }
  .bootstrap input[type="date"],
  .bootstrap input[type="time"],
  .bootstrap input[type="datetime-local"],
  .bootstrap input[type="month"] {
    -webkit-appearance: listbox; }
  .bootstrap textarea {
    overflow: auto;
    resize: vertical; }
  .bootstrap fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  .bootstrap legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  .bootstrap progress {
    vertical-align: baseline; }
  .bootstrap [type="number"]::-webkit-inner-spin-button,
  .bootstrap [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  .bootstrap [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  .bootstrap [type="search"]::-webkit-search-cancel-button,
  .bootstrap [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  .bootstrap ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  .bootstrap output {
    display: inline-block; }
  .bootstrap summary {
    display: list-item;
    cursor: pointer; }
  .bootstrap template {
    display: none; }
  .bootstrap [hidden] {
    display: none !important; }
  .bootstrap h1, .bootstrap h2, .bootstrap h3, .bootstrap h4, .bootstrap h5, .bootstrap h6,
  .bootstrap .h1, .bootstrap .h2, .bootstrap .h3, .bootstrap .h4, .bootstrap .h5, .bootstrap .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit; }
  .bootstrap h1, .bootstrap .h1 {
    font-size: 2.5rem; }
  .bootstrap h2, .bootstrap .h2 {
    font-size: 2rem; }
  .bootstrap h3, .bootstrap .h3 {
    font-size: 1.75rem; }
  .bootstrap h4, .bootstrap .h4 {
    font-size: 1.5rem; }
  .bootstrap h5, .bootstrap .h5 {
    font-size: 1.25rem; }
  .bootstrap h6, .bootstrap .h6 {
    font-size: 1rem; }
  .bootstrap .lead {
    font-size: 1.25rem;
    font-weight: 300; }
  .bootstrap .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .bootstrap hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .bootstrap small,
  .bootstrap .small {
    font-size: 80%;
    font-weight: 400; }
  .bootstrap mark,
  .bootstrap .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  .bootstrap .list-unstyled {
    padding-left: 0;
    list-style: none; }
  .bootstrap .list-inline {
    padding-left: 0;
    list-style: none; }
  .bootstrap .list-inline-item {
    display: inline-block; }
  .bootstrap .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }
  .bootstrap .initialism {
    font-size: 90%;
    text-transform: uppercase; }
  .bootstrap .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem; }
  .bootstrap .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d; }
  .bootstrap .blockquote-footer::before {
    content: "\2014 \00A0"; }
  .bootstrap .img-fluid {
    max-width: 100%;
    height: auto; }
  .bootstrap .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto; }
  .bootstrap .figure {
    display: inline-block; }
  .bootstrap .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  .bootstrap .figure-caption {
    font-size: 90%;
    color: #6c757d; }
  .bootstrap code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word; }
  .bootstrap a > code {
    color: inherit; }
  .bootstrap kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem; }
  .bootstrap kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }
  .bootstrap pre {
    display: block;
    color: #212529; }
  .bootstrap pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }
  .bootstrap .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }
  .bootstrap .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    .bootstrap .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .bootstrap .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .bootstrap .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .bootstrap .container {
      max-width: 1140px; } }
  .bootstrap .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  .bootstrap .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .bootstrap .no-gutters {
    margin-right: 0;
    margin-left: 0; }
  .bootstrap .no-gutters > .col,
  .bootstrap .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }
  .bootstrap .col-1, .bootstrap .col-2, .bootstrap .col-3, .bootstrap .col-4, .bootstrap .col-5, .bootstrap .col-6, .bootstrap .col-7, .bootstrap .col-8, .bootstrap .col-9, .bootstrap .col-10, .bootstrap .col-11, .bootstrap .col-12, .bootstrap .col,
  .bootstrap .col-auto, .bootstrap .col-sm-1, .bootstrap .col-sm-2, .bootstrap .col-sm-3, .bootstrap .col-sm-4, .bootstrap .col-sm-5, .bootstrap .col-sm-6, .bootstrap .col-sm-7, .bootstrap .col-sm-8, .bootstrap .col-sm-9, .bootstrap .col-sm-10, .bootstrap .col-sm-11, .bootstrap .col-sm-12, .bootstrap .col-sm,
  .bootstrap .col-sm-auto, .bootstrap .col-md-1, .bootstrap .col-md-2, .bootstrap .col-md-3, .bootstrap .col-md-4, .bootstrap .col-md-5, .bootstrap .col-md-6, .bootstrap .col-md-7, .bootstrap .col-md-8, .bootstrap .col-md-9, .bootstrap .col-md-10, .bootstrap .col-md-11, .bootstrap .col-md-12, .bootstrap .col-md,
  .bootstrap .col-md-auto, .bootstrap .col-lg-1, .bootstrap .col-lg-2, .bootstrap .col-lg-3, .bootstrap .col-lg-4, .bootstrap .col-lg-5, .bootstrap .col-lg-6, .bootstrap .col-lg-7, .bootstrap .col-lg-8, .bootstrap .col-lg-9, .bootstrap .col-lg-10, .bootstrap .col-lg-11, .bootstrap .col-lg-12, .bootstrap .col-lg,
  .bootstrap .col-lg-auto, .bootstrap .col-xl-1, .bootstrap .col-xl-2, .bootstrap .col-xl-3, .bootstrap .col-xl-4, .bootstrap .col-xl-5, .bootstrap .col-xl-6, .bootstrap .col-xl-7, .bootstrap .col-xl-8, .bootstrap .col-xl-9, .bootstrap .col-xl-10, .bootstrap .col-xl-11, .bootstrap .col-xl-12, .bootstrap .col-xl,
  .bootstrap .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }
  .bootstrap .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .bootstrap .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .bootstrap .col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .bootstrap .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .bootstrap .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .bootstrap .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .bootstrap .col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .bootstrap .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .bootstrap .col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .bootstrap .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .bootstrap .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .bootstrap .col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .bootstrap .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .bootstrap .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .bootstrap .order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .bootstrap .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .bootstrap .order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .bootstrap .order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .bootstrap .order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .bootstrap .order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .bootstrap .order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .bootstrap .order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .bootstrap .order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .bootstrap .order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .bootstrap .order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .bootstrap .order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .bootstrap .order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .bootstrap .order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .bootstrap .order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .bootstrap .offset-1 {
    margin-left: 8.333333%; }
  .bootstrap .offset-2 {
    margin-left: 16.666667%; }
  .bootstrap .offset-3 {
    margin-left: 25%; }
  .bootstrap .offset-4 {
    margin-left: 33.333333%; }
  .bootstrap .offset-5 {
    margin-left: 41.666667%; }
  .bootstrap .offset-6 {
    margin-left: 50%; }
  .bootstrap .offset-7 {
    margin-left: 58.333333%; }
  .bootstrap .offset-8 {
    margin-left: 66.666667%; }
  .bootstrap .offset-9 {
    margin-left: 75%; }
  .bootstrap .offset-10 {
    margin-left: 83.333333%; }
  .bootstrap .offset-11 {
    margin-left: 91.666667%; }
  @media (min-width: 576px) {
    .bootstrap .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .bootstrap .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%; }
    .bootstrap .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%; }
    .bootstrap .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%; }
    .bootstrap .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%; }
    .bootstrap .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%; }
    .bootstrap .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%; }
    .bootstrap .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%; }
    .bootstrap .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%; }
    .bootstrap .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap .order-sm-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .bootstrap .order-sm-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .bootstrap .order-sm-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .bootstrap .order-sm-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .bootstrap .order-sm-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .bootstrap .order-sm-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .bootstrap .order-sm-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .bootstrap .order-sm-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .bootstrap .order-sm-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .bootstrap .order-sm-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .bootstrap .order-sm-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .bootstrap .order-sm-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .bootstrap .order-sm-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .bootstrap .order-sm-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .bootstrap .order-sm-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .bootstrap .offset-sm-0 {
      margin-left: 0; }
    .bootstrap .offset-sm-1 {
      margin-left: 8.333333%; }
    .bootstrap .offset-sm-2 {
      margin-left: 16.666667%; }
    .bootstrap .offset-sm-3 {
      margin-left: 25%; }
    .bootstrap .offset-sm-4 {
      margin-left: 33.333333%; }
    .bootstrap .offset-sm-5 {
      margin-left: 41.666667%; }
    .bootstrap .offset-sm-6 {
      margin-left: 50%; }
    .bootstrap .offset-sm-7 {
      margin-left: 58.333333%; }
    .bootstrap .offset-sm-8 {
      margin-left: 66.666667%; }
    .bootstrap .offset-sm-9 {
      margin-left: 75%; }
    .bootstrap .offset-sm-10 {
      margin-left: 83.333333%; }
    .bootstrap .offset-sm-11 {
      margin-left: 91.666667%; } }
  @media (min-width: 768px) {
    .bootstrap .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .bootstrap .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%; }
    .bootstrap .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%; }
    .bootstrap .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%; }
    .bootstrap .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%; }
    .bootstrap .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%; }
    .bootstrap .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%; }
    .bootstrap .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%; }
    .bootstrap .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%; }
    .bootstrap .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap .order-md-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .bootstrap .order-md-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .bootstrap .order-md-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .bootstrap .order-md-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .bootstrap .order-md-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .bootstrap .order-md-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .bootstrap .order-md-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .bootstrap .order-md-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .bootstrap .order-md-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .bootstrap .order-md-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .bootstrap .order-md-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .bootstrap .order-md-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .bootstrap .order-md-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .bootstrap .order-md-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .bootstrap .order-md-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .bootstrap .offset-md-0 {
      margin-left: 0; }
    .bootstrap .offset-md-1 {
      margin-left: 8.333333%; }
    .bootstrap .offset-md-2 {
      margin-left: 16.666667%; }
    .bootstrap .offset-md-3 {
      margin-left: 25%; }
    .bootstrap .offset-md-4 {
      margin-left: 33.333333%; }
    .bootstrap .offset-md-5 {
      margin-left: 41.666667%; }
    .bootstrap .offset-md-6 {
      margin-left: 50%; }
    .bootstrap .offset-md-7 {
      margin-left: 58.333333%; }
    .bootstrap .offset-md-8 {
      margin-left: 66.666667%; }
    .bootstrap .offset-md-9 {
      margin-left: 75%; }
    .bootstrap .offset-md-10 {
      margin-left: 83.333333%; }
    .bootstrap .offset-md-11 {
      margin-left: 91.666667%; } }
  @media (min-width: 992px) {
    .bootstrap .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .bootstrap .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%; }
    .bootstrap .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%; }
    .bootstrap .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%; }
    .bootstrap .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%; }
    .bootstrap .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%; }
    .bootstrap .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%; }
    .bootstrap .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%; }
    .bootstrap .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%; }
    .bootstrap .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap .order-lg-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .bootstrap .order-lg-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .bootstrap .order-lg-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .bootstrap .order-lg-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .bootstrap .order-lg-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .bootstrap .order-lg-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .bootstrap .order-lg-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .bootstrap .order-lg-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .bootstrap .order-lg-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .bootstrap .order-lg-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .bootstrap .order-lg-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .bootstrap .order-lg-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .bootstrap .order-lg-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .bootstrap .order-lg-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .bootstrap .order-lg-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .bootstrap .offset-lg-0 {
      margin-left: 0; }
    .bootstrap .offset-lg-1 {
      margin-left: 8.333333%; }
    .bootstrap .offset-lg-2 {
      margin-left: 16.666667%; }
    .bootstrap .offset-lg-3 {
      margin-left: 25%; }
    .bootstrap .offset-lg-4 {
      margin-left: 33.333333%; }
    .bootstrap .offset-lg-5 {
      margin-left: 41.666667%; }
    .bootstrap .offset-lg-6 {
      margin-left: 50%; }
    .bootstrap .offset-lg-7 {
      margin-left: 58.333333%; }
    .bootstrap .offset-lg-8 {
      margin-left: 66.666667%; }
    .bootstrap .offset-lg-9 {
      margin-left: 75%; }
    .bootstrap .offset-lg-10 {
      margin-left: 83.333333%; }
    .bootstrap .offset-lg-11 {
      margin-left: 91.666667%; } }
  @media (min-width: 1200px) {
    .bootstrap .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .bootstrap .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .bootstrap .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%; }
    .bootstrap .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%; }
    .bootstrap .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .bootstrap .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%; }
    .bootstrap .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%; }
    .bootstrap .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .bootstrap .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%; }
    .bootstrap .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%; }
    .bootstrap .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .bootstrap .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%; }
    .bootstrap .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%; }
    .bootstrap .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .bootstrap .order-xl-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .bootstrap .order-xl-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .bootstrap .order-xl-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .bootstrap .order-xl-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .bootstrap .order-xl-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .bootstrap .order-xl-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .bootstrap .order-xl-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .bootstrap .order-xl-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .bootstrap .order-xl-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .bootstrap .order-xl-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .bootstrap .order-xl-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .bootstrap .order-xl-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .bootstrap .order-xl-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .bootstrap .order-xl-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .bootstrap .order-xl-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .bootstrap .offset-xl-0 {
      margin-left: 0; }
    .bootstrap .offset-xl-1 {
      margin-left: 8.333333%; }
    .bootstrap .offset-xl-2 {
      margin-left: 16.666667%; }
    .bootstrap .offset-xl-3 {
      margin-left: 25%; }
    .bootstrap .offset-xl-4 {
      margin-left: 33.333333%; }
    .bootstrap .offset-xl-5 {
      margin-left: 41.666667%; }
    .bootstrap .offset-xl-6 {
      margin-left: 50%; }
    .bootstrap .offset-xl-7 {
      margin-left: 58.333333%; }
    .bootstrap .offset-xl-8 {
      margin-left: 66.666667%; }
    .bootstrap .offset-xl-9 {
      margin-left: 75%; }
    .bootstrap .offset-xl-10 {
      margin-left: 83.333333%; }
    .bootstrap .offset-xl-11 {
      margin-left: 91.666667%; } }
  .bootstrap .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent; }
  .bootstrap .table th,
  .bootstrap .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .bootstrap .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .bootstrap .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .bootstrap .table .table {
    background-color: #fff; }
  .bootstrap .table-sm th,
  .bootstrap .table-sm td {
    padding: 0.3rem; }
  .bootstrap .table-bordered {
    border: 1px solid #dee2e6; }
  .bootstrap .table-bordered th,
  .bootstrap .table-bordered td {
    border: 1px solid #dee2e6; }
  .bootstrap .table-bordered thead th,
  .bootstrap .table-bordered thead td {
    border-bottom-width: 2px; }
  .bootstrap .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  .bootstrap .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075); }
  .bootstrap .table-primary,
  .bootstrap .table-primary > th,
  .bootstrap .table-primary > td {
    background-color: #b8daff; }
  .bootstrap .table-hover .table-primary:hover {
    background-color: #9fcdff; }
  .bootstrap .table-hover .table-primary:hover > td,
  .bootstrap .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }
  .bootstrap .table-secondary,
  .bootstrap .table-secondary > th,
  .bootstrap .table-secondary > td {
    background-color: #d6d8db; }
  .bootstrap .table-hover .table-secondary:hover {
    background-color: #c8cbcf; }
  .bootstrap .table-hover .table-secondary:hover > td,
  .bootstrap .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }
  .bootstrap .table-success,
  .bootstrap .table-success > th,
  .bootstrap .table-success > td {
    background-color: #c3e6cb; }
  .bootstrap .table-hover .table-success:hover {
    background-color: #b1dfbb; }
  .bootstrap .table-hover .table-success:hover > td,
  .bootstrap .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }
  .bootstrap .table-info,
  .bootstrap .table-info > th,
  .bootstrap .table-info > td {
    background-color: #bee5eb; }
  .bootstrap .table-hover .table-info:hover {
    background-color: #abdde5; }
  .bootstrap .table-hover .table-info:hover > td,
  .bootstrap .table-hover .table-info:hover > th {
    background-color: #abdde5; }
  .bootstrap .table-warning,
  .bootstrap .table-warning > th,
  .bootstrap .table-warning > td {
    background-color: #ffeeba; }
  .bootstrap .table-hover .table-warning:hover {
    background-color: #ffe8a1; }
  .bootstrap .table-hover .table-warning:hover > td,
  .bootstrap .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }
  .bootstrap .table-danger,
  .bootstrap .table-danger > th,
  .bootstrap .table-danger > td {
    background-color: #f5c6cb; }
  .bootstrap .table-hover .table-danger:hover {
    background-color: #f1b0b7; }
  .bootstrap .table-hover .table-danger:hover > td,
  .bootstrap .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }
  .bootstrap .table-light,
  .bootstrap .table-light > th,
  .bootstrap .table-light > td {
    background-color: #fdfdfe; }
  .bootstrap .table-hover .table-light:hover {
    background-color: #ececf6; }
  .bootstrap .table-hover .table-light:hover > td,
  .bootstrap .table-hover .table-light:hover > th {
    background-color: #ececf6; }
  .bootstrap .table-dark,
  .bootstrap .table-dark > th,
  .bootstrap .table-dark > td {
    background-color: #c6c8ca; }
  .bootstrap .table-hover .table-dark:hover {
    background-color: #b9bbbe; }
  .bootstrap .table-hover .table-dark:hover > td,
  .bootstrap .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }
  .bootstrap .table-active,
  .bootstrap .table-active > th,
  .bootstrap .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }
  .bootstrap .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
  .bootstrap .table-hover .table-active:hover > td,
  .bootstrap .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }
  .bootstrap .table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e; }
  .bootstrap .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .bootstrap .table-dark {
    color: #fff;
    background-color: #212529; }
  .bootstrap .table-dark th,
  .bootstrap .table-dark td,
  .bootstrap .table-dark thead th {
    border-color: #32383e; }
  .bootstrap .table-dark.table-bordered {
    border: 0; }
  .bootstrap .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .bootstrap .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }
  @media (max-width: 575.98px) {
    .bootstrap .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .bootstrap .table-responsive-sm > .table-bordered {
      border: 0; } }
  @media (max-width: 767.98px) {
    .bootstrap .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .bootstrap .table-responsive-md > .table-bordered {
      border: 0; } }
  @media (max-width: 991.98px) {
    .bootstrap .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .bootstrap .table-responsive-lg > .table-bordered {
      border: 0; } }
  @media (max-width: 1199.98px) {
    .bootstrap .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .bootstrap .table-responsive-xl > .table-bordered {
      border: 0; } }
  .bootstrap .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .bootstrap .table-responsive > .table-bordered {
    border: 0; }
  .bootstrap .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .bootstrap .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .bootstrap .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .bootstrap .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .bootstrap .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .bootstrap .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .bootstrap .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .bootstrap .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .bootstrap .form-control:disabled, .bootstrap .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .bootstrap select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px); }
  .bootstrap select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  .bootstrap .form-control-file,
  .bootstrap .form-control-range {
    display: block;
    width: 100%; }
  .bootstrap .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  .bootstrap .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5; }
  .bootstrap .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5; }
  .bootstrap .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
  .bootstrap .form-control-plaintext.form-control-sm, .bootstrap .input-group-sm > .form-control-plaintext.form-control,
  .bootstrap .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .bootstrap .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .bootstrap .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .bootstrap .input-group-sm > .input-group-append > .form-control-plaintext.btn, .bootstrap .form-control-plaintext.form-control-lg, .bootstrap .input-group-lg > .form-control-plaintext.form-control,
  .bootstrap .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .bootstrap .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .bootstrap .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .bootstrap .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }
  .bootstrap .form-control-sm, .bootstrap .input-group-sm > .form-control,
  .bootstrap .input-group-sm > .input-group-prepend > .input-group-text,
  .bootstrap .input-group-sm > .input-group-append > .input-group-text,
  .bootstrap .input-group-sm > .input-group-prepend > .btn,
  .bootstrap .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  .bootstrap select.form-control-sm:not([size]):not([multiple]), .bootstrap .input-group-sm > select.form-control:not([size]):not([multiple]),
  .bootstrap .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .bootstrap .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .bootstrap .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .bootstrap .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px); }
  .bootstrap .form-control-lg, .bootstrap .input-group-lg > .form-control,
  .bootstrap .input-group-lg > .input-group-prepend > .input-group-text,
  .bootstrap .input-group-lg > .input-group-append > .input-group-text,
  .bootstrap .input-group-lg > .input-group-prepend > .btn,
  .bootstrap .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  .bootstrap select.form-control-lg:not([size]):not([multiple]), .bootstrap .input-group-lg > select.form-control:not([size]):not([multiple]),
  .bootstrap .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .bootstrap .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .bootstrap .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .bootstrap .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px); }
  .bootstrap .form-group {
    margin-bottom: 1rem; }
  .bootstrap .form-text {
    display: block;
    margin-top: 0.25rem; }
  .bootstrap .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
  .bootstrap .form-row > .col,
  .bootstrap .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }
  .bootstrap .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }
  .bootstrap .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
  .bootstrap .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }
  .bootstrap .form-check-label {
    margin-bottom: 0; }
  .bootstrap .form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
  .bootstrap .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }
  .bootstrap .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745; }
  .bootstrap .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.8);
    border-radius: .2rem; }
  .bootstrap .was-validated .form-control:valid, .bootstrap .form-control.is-valid, .bootstrap .was-validated .custom-select:valid,
  .bootstrap .custom-select.is-valid {
    border-color: #28a745; }
  .bootstrap .was-validated .form-control:valid:focus, .bootstrap .form-control.is-valid:focus, .bootstrap .was-validated .custom-select:valid:focus,
  .bootstrap .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .bootstrap .was-validated .form-control:valid ~ .valid-feedback,
  .bootstrap .was-validated .form-control:valid ~ .valid-tooltip, .bootstrap .form-control.is-valid ~ .valid-feedback,
  .bootstrap .form-control.is-valid ~ .valid-tooltip, .bootstrap .was-validated .custom-select:valid ~ .valid-feedback,
  .bootstrap .was-validated .custom-select:valid ~ .valid-tooltip,
  .bootstrap .custom-select.is-valid ~ .valid-feedback,
  .bootstrap .custom-select.is-valid ~ .valid-tooltip {
    display: block; }
  .bootstrap .was-validated .form-check-input:valid ~ .form-check-label, .bootstrap .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }
  .bootstrap .was-validated .form-check-input:valid ~ .valid-feedback,
  .bootstrap .was-validated .form-check-input:valid ~ .valid-tooltip, .bootstrap .form-check-input.is-valid ~ .valid-feedback,
  .bootstrap .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }
  .bootstrap .was-validated .custom-control-input:valid ~ .custom-control-label, .bootstrap .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745; }
  .bootstrap .was-validated .custom-control-input:valid ~ .custom-control-label::before, .bootstrap .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }
  .bootstrap .was-validated .custom-control-input:valid ~ .valid-feedback,
  .bootstrap .was-validated .custom-control-input:valid ~ .valid-tooltip, .bootstrap .custom-control-input.is-valid ~ .valid-feedback,
  .bootstrap .custom-control-input.is-valid ~ .valid-tooltip {
    display: block; }
  .bootstrap .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .bootstrap .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #34ce57; }
  .bootstrap .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .bootstrap .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .bootstrap .was-validated .custom-file-input:valid ~ .custom-file-label, .bootstrap .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745; }
  .bootstrap .was-validated .custom-file-input:valid ~ .custom-file-label::before, .bootstrap .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }
  .bootstrap .was-validated .custom-file-input:valid ~ .valid-feedback,
  .bootstrap .was-validated .custom-file-input:valid ~ .valid-tooltip, .bootstrap .custom-file-input.is-valid ~ .valid-feedback,
  .bootstrap .custom-file-input.is-valid ~ .valid-tooltip {
    display: block; }
  .bootstrap .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .bootstrap .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .bootstrap .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545; }
  .bootstrap .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.8);
    border-radius: .2rem; }
  .bootstrap .was-validated .form-control:invalid, .bootstrap .form-control.is-invalid, .bootstrap .was-validated .custom-select:invalid,
  .bootstrap .custom-select.is-invalid {
    border-color: #dc3545; }
  .bootstrap .was-validated .form-control:invalid:focus, .bootstrap .form-control.is-invalid:focus, .bootstrap .was-validated .custom-select:invalid:focus,
  .bootstrap .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .bootstrap .was-validated .form-control:invalid ~ .invalid-feedback,
  .bootstrap .was-validated .form-control:invalid ~ .invalid-tooltip, .bootstrap .form-control.is-invalid ~ .invalid-feedback,
  .bootstrap .form-control.is-invalid ~ .invalid-tooltip, .bootstrap .was-validated .custom-select:invalid ~ .invalid-feedback,
  .bootstrap .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .bootstrap .custom-select.is-invalid ~ .invalid-feedback,
  .bootstrap .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }
  .bootstrap .was-validated .form-check-input:invalid ~ .form-check-label, .bootstrap .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }
  .bootstrap .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .bootstrap .was-validated .form-check-input:invalid ~ .invalid-tooltip, .bootstrap .form-check-input.is-invalid ~ .invalid-feedback,
  .bootstrap .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .bootstrap .was-validated .custom-control-input:invalid ~ .custom-control-label, .bootstrap .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545; }
  .bootstrap .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .bootstrap .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }
  .bootstrap .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  .bootstrap .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .bootstrap .custom-control-input.is-invalid ~ .invalid-feedback,
  .bootstrap .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .bootstrap .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .bootstrap .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #e4606d; }
  .bootstrap .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .bootstrap .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .bootstrap .was-validated .custom-file-input:invalid ~ .custom-file-label, .bootstrap .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545; }
  .bootstrap .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .bootstrap .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }
  .bootstrap .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  .bootstrap .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .bootstrap .custom-file-input.is-invalid ~ .invalid-feedback,
  .bootstrap .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .bootstrap .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .bootstrap .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .bootstrap .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .bootstrap .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .bootstrap .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0; }
    .bootstrap .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0; }
    .bootstrap .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .bootstrap .form-inline .form-control-plaintext {
      display: inline-block; }
    .bootstrap .form-inline .input-group {
      width: auto; }
    .bootstrap .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .bootstrap .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .bootstrap .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .bootstrap .form-inline .custom-control-label {
      margin-bottom: 0; } }
  .bootstrap .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .bootstrap .btn:hover, .bootstrap .btn:focus {
    text-decoration: none; }
  .bootstrap .btn:focus, .bootstrap .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .bootstrap .btn.disabled, .bootstrap .btn:disabled {
    opacity: 0.65; }
  .bootstrap .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .bootstrap .btn:not(:disabled):not(.disabled):active, .bootstrap .btn:not(:disabled):not(.disabled).active {
    background-image: none; }
  .bootstrap a.btn.disabled,
  .bootstrap fieldset:disabled a.btn {
    pointer-events: none; }
  .bootstrap .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .bootstrap .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .bootstrap .btn-primary:focus, .bootstrap .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .bootstrap .btn-primary.disabled, .bootstrap .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .bootstrap .btn-primary:not(:disabled):not(.disabled):active, .bootstrap .btn-primary:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
  .bootstrap .btn-primary:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-primary:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .bootstrap .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .bootstrap .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .bootstrap .btn-secondary:focus, .bootstrap .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .bootstrap .btn-secondary.disabled, .bootstrap .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .bootstrap .btn-secondary:not(:disabled):not(.disabled):active, .bootstrap .btn-secondary:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
  .bootstrap .btn-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .bootstrap .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .bootstrap .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .bootstrap .btn-success:focus, .bootstrap .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .bootstrap .btn-success.disabled, .bootstrap .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .bootstrap .btn-success:not(:disabled):not(.disabled):active, .bootstrap .btn-success:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
  .bootstrap .btn-success:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-success:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .bootstrap .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .bootstrap .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .bootstrap .btn-info:focus, .bootstrap .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .bootstrap .btn-info.disabled, .bootstrap .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .bootstrap .btn-info:not(:disabled):not(.disabled):active, .bootstrap .btn-info:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
  .bootstrap .btn-info:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-info:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .bootstrap .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .bootstrap .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .bootstrap .btn-warning:focus, .bootstrap .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .bootstrap .btn-warning.disabled, .bootstrap .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .bootstrap .btn-warning:not(:disabled):not(.disabled):active, .bootstrap .btn-warning:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
  .bootstrap .btn-warning:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-warning:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .bootstrap .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .bootstrap .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .bootstrap .btn-danger:focus, .bootstrap .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .bootstrap .btn-danger.disabled, .bootstrap .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .bootstrap .btn-danger:not(:disabled):not(.disabled):active, .bootstrap .btn-danger:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
  .bootstrap .btn-danger:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-danger:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .bootstrap .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .bootstrap .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .bootstrap .btn-light:focus, .bootstrap .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .bootstrap .btn-light.disabled, .bootstrap .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .bootstrap .btn-light:not(:disabled):not(.disabled):active, .bootstrap .btn-light:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
  .bootstrap .btn-light:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-light:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .bootstrap .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .bootstrap .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .bootstrap .btn-dark:focus, .bootstrap .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .bootstrap .btn-dark.disabled, .bootstrap .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .bootstrap .btn-dark:not(:disabled):not(.disabled):active, .bootstrap .btn-dark:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
  .bootstrap .btn-dark:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-dark:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .bootstrap .btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff; }
  .bootstrap .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .bootstrap .btn-outline-primary:focus, .bootstrap .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .bootstrap .btn-outline-primary.disabled, .bootstrap .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .bootstrap .btn-outline-primary:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-primary:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .bootstrap .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .bootstrap .btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d; }
  .bootstrap .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .bootstrap .btn-outline-secondary:focus, .bootstrap .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .bootstrap .btn-outline-secondary.disabled, .bootstrap .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .bootstrap .btn-outline-secondary:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .bootstrap .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .bootstrap .btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745; }
  .bootstrap .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .bootstrap .btn-outline-success:focus, .bootstrap .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .bootstrap .btn-outline-success.disabled, .bootstrap .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .bootstrap .btn-outline-success:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-success:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .bootstrap .btn-outline-success:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .bootstrap .btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8; }
  .bootstrap .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .bootstrap .btn-outline-info:focus, .bootstrap .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .bootstrap .btn-outline-info.disabled, .bootstrap .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .bootstrap .btn-outline-info:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-info:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .bootstrap .btn-outline-info:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .bootstrap .btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107; }
  .bootstrap .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .bootstrap .btn-outline-warning:focus, .bootstrap .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .bootstrap .btn-outline-warning.disabled, .bootstrap .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .bootstrap .btn-outline-warning:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-warning:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .bootstrap .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .bootstrap .btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545; }
  .bootstrap .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .bootstrap .btn-outline-danger:focus, .bootstrap .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .bootstrap .btn-outline-danger.disabled, .bootstrap .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .bootstrap .btn-outline-danger:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-danger:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .bootstrap .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .bootstrap .btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa; }
  .bootstrap .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .bootstrap .btn-outline-light:focus, .bootstrap .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .bootstrap .btn-outline-light.disabled, .bootstrap .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .bootstrap .btn-outline-light:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-light:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .bootstrap .btn-outline-light:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .bootstrap .btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40; }
  .bootstrap .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .bootstrap .btn-outline-dark:focus, .bootstrap .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .bootstrap .btn-outline-dark.disabled, .bootstrap .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .bootstrap .btn-outline-dark:not(:disabled):not(.disabled):active, .bootstrap .btn-outline-dark:not(:disabled):not(.disabled).active,
  .bootstrap .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .bootstrap .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .bootstrap .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .bootstrap .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .bootstrap .btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent; }
  .bootstrap .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .bootstrap .btn-link:focus, .bootstrap .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .bootstrap .btn-link:disabled, .bootstrap .btn-link.disabled {
    color: #6c757d; }
  .bootstrap .btn-lg, .bootstrap .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  .bootstrap .btn-sm, .bootstrap .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  .bootstrap .btn-block {
    display: block;
    width: 100%; }
  .bootstrap .btn-block + .btn-block {
    margin-top: 0.5rem; }
  .bootstrap input[type="submit"].btn-block,
  .bootstrap input[type="reset"].btn-block,
  .bootstrap input[type="button"].btn-block {
    width: 100%; }
  .bootstrap .fade {
    opacity: 0;
    transition: opacity 0.15s linear; }
  .bootstrap .fade.show {
    opacity: 1; }
  .bootstrap .collapse {
    display: none; }
  .bootstrap .collapse.show {
    display: block; }
  .bootstrap tr.collapse.show {
    display: table-row; }
  .bootstrap tbody.collapse.show {
    display: table-row-group; }
  .bootstrap .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
  .bootstrap .dropup,
  .bootstrap .dropdown {
    position: relative; }
  .bootstrap .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .bootstrap .dropdown-toggle:empty::after {
    margin-left: 0; }
  .bootstrap .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
  .bootstrap .dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: 0.125rem; }
  .bootstrap .dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  .bootstrap .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  .bootstrap .dropright .dropdown-menu {
    margin-top: 0;
    margin-left: 0.125rem; }
  .bootstrap .dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  .bootstrap .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }
  .bootstrap .dropright .dropdown-toggle::after {
    vertical-align: 0; }
  .bootstrap .dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: 0.125rem; }
  .bootstrap .dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  .bootstrap .dropleft .dropdown-toggle::after {
    display: none; }
  .bootstrap .dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  .bootstrap .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }
  .bootstrap .dropleft .dropdown-toggle::before {
    vertical-align: 0; }
  .bootstrap .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef; }
  .bootstrap .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  .bootstrap .dropdown-item:hover, .bootstrap .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .bootstrap .dropdown-item.active, .bootstrap .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .bootstrap .dropdown-item.disabled, .bootstrap .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }
  .bootstrap .dropdown-menu.show {
    display: block; }
  .bootstrap .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap; }
  .bootstrap .btn-group,
  .bootstrap .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle; }
  .bootstrap .btn-group > .btn,
  .bootstrap .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .bootstrap .btn-group > .btn:hover,
  .bootstrap .btn-group-vertical > .btn:hover {
    z-index: 1; }
  .bootstrap .btn-group > .btn:focus, .bootstrap .btn-group > .btn:active, .bootstrap .btn-group > .btn.active,
  .bootstrap .btn-group-vertical > .btn:focus,
  .bootstrap .btn-group-vertical > .btn:active,
  .bootstrap .btn-group-vertical > .btn.active {
    z-index: 1; }
  .bootstrap .btn-group .btn + .btn,
  .bootstrap .btn-group .btn + .btn-group,
  .bootstrap .btn-group .btn-group + .btn,
  .bootstrap .btn-group .btn-group + .btn-group,
  .bootstrap .btn-group-vertical .btn + .btn,
  .bootstrap .btn-group-vertical .btn + .btn-group,
  .bootstrap .btn-group-vertical .btn-group + .btn,
  .bootstrap .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }
  .bootstrap .btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .bootstrap .btn-toolbar .input-group {
    width: auto; }
  .bootstrap .btn-group > .btn:first-child {
    margin-left: 0; }
  .bootstrap .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .bootstrap .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bootstrap .btn-group > .btn:not(:first-child),
  .bootstrap .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .bootstrap .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }
  .bootstrap .dropdown-toggle-split::after {
    margin-left: 0; }
  .bootstrap .btn-sm + .dropdown-toggle-split, .bootstrap .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }
  .bootstrap .btn-lg + .dropdown-toggle-split, .bootstrap .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .bootstrap .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .bootstrap .btn-group-vertical .btn,
  .bootstrap .btn-group-vertical .btn-group {
    width: 100%; }
  .bootstrap .btn-group-vertical > .btn + .btn,
  .bootstrap .btn-group-vertical > .btn + .btn-group,
  .bootstrap .btn-group-vertical > .btn-group + .btn,
  .bootstrap .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .bootstrap .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .bootstrap .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .bootstrap .btn-group-vertical > .btn:not(:first-child),
  .bootstrap .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .bootstrap .btn-group-toggle > .btn,
  .bootstrap .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
  .bootstrap .btn-group-toggle > .btn input[type="radio"],
  .bootstrap .btn-group-toggle > .btn input[type="checkbox"],
  .bootstrap .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .bootstrap .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }
  .bootstrap .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%; }
  .bootstrap .input-group > .form-control,
  .bootstrap .input-group > .custom-select,
  .bootstrap .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
  .bootstrap .input-group > .form-control:focus,
  .bootstrap .input-group > .custom-select:focus,
  .bootstrap .input-group > .custom-file:focus {
    z-index: 3; }
  .bootstrap .input-group > .form-control + .form-control,
  .bootstrap .input-group > .form-control + .custom-select,
  .bootstrap .input-group > .form-control + .custom-file,
  .bootstrap .input-group > .custom-select + .form-control,
  .bootstrap .input-group > .custom-select + .custom-select,
  .bootstrap .input-group > .custom-select + .custom-file,
  .bootstrap .input-group > .custom-file + .form-control,
  .bootstrap .input-group > .custom-file + .custom-select,
  .bootstrap .input-group > .custom-file + .custom-file {
    margin-left: -1px; }
  .bootstrap .input-group > .form-control:not(:last-child),
  .bootstrap .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bootstrap .input-group > .form-control:not(:first-child),
  .bootstrap .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .bootstrap .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .bootstrap .input-group > .custom-file:not(:last-child) .custom-file-label,
  .bootstrap .input-group > .custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bootstrap .input-group > .custom-file:not(:first-child) .custom-file-label,
  .bootstrap .input-group > .custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .bootstrap .input-group-prepend,
  .bootstrap .input-group-append {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .bootstrap .input-group-prepend .btn,
  .bootstrap .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .bootstrap .input-group-prepend .btn + .btn,
  .bootstrap .input-group-prepend .btn + .input-group-text,
  .bootstrap .input-group-prepend .input-group-text + .input-group-text,
  .bootstrap .input-group-prepend .input-group-text + .btn,
  .bootstrap .input-group-append .btn + .btn,
  .bootstrap .input-group-append .btn + .input-group-text,
  .bootstrap .input-group-append .input-group-text + .input-group-text,
  .bootstrap .input-group-append .input-group-text + .btn {
    margin-left: -1px; }
  .bootstrap .input-group-prepend {
    margin-right: -1px; }
  .bootstrap .input-group-append {
    margin-left: -1px; }
  .bootstrap .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
  .bootstrap .input-group-text input[type="radio"],
  .bootstrap .input-group-text input[type="checkbox"] {
    margin-top: 0; }
  .bootstrap .input-group > .input-group-prepend > .btn,
  .bootstrap .input-group > .input-group-prepend > .input-group-text,
  .bootstrap .input-group > .input-group-append:not(:last-child) > .btn,
  .bootstrap .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .bootstrap .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .bootstrap .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .bootstrap .input-group > .input-group-append > .btn,
  .bootstrap .input-group > .input-group-append > .input-group-text,
  .bootstrap .input-group > .input-group-prepend:not(:first-child) > .btn,
  .bootstrap .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .bootstrap .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .bootstrap .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .bootstrap .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem; }
  .bootstrap .custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem; }
  .bootstrap .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .bootstrap .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .bootstrap .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .bootstrap .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .bootstrap .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
  .bootstrap .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef; }
  .bootstrap .custom-control-label {
    margin-bottom: 0; }
  .bootstrap .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6; }
  .bootstrap .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }
  .bootstrap .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem; }
  .bootstrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007bff; }
  .bootstrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
  .bootstrap .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #007bff; }
  .bootstrap .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }
  .bootstrap .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  .bootstrap .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  .bootstrap .custom-radio .custom-control-label::before {
    border-radius: 50%; }
  .bootstrap .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007bff; }
  .bootstrap .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }
  .bootstrap .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }
  .bootstrap .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .bootstrap .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
  .bootstrap .custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  .bootstrap .custom-select[multiple], .bootstrap .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .bootstrap .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .bootstrap .custom-select::-ms-expand {
    opacity: 0; }
  .bootstrap .custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%; }
  .bootstrap .custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%; }
  .bootstrap .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0; }
  .bootstrap .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0; }
  .bootstrap .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .bootstrap .custom-file-input:focus ~ .custom-file-control::before {
    border-color: #80bdff; }
  .bootstrap .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .bootstrap .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
  .bootstrap .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }
  .bootstrap .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .bootstrap .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
  .bootstrap .nav-link:hover, .bootstrap .nav-link:focus {
    text-decoration: none; }
  .bootstrap .nav-link.disabled {
    color: #6c757d; }
  .bootstrap .nav-tabs {
    border-bottom: 1px solid #dee2e6; }
  .bootstrap .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .bootstrap .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .bootstrap .nav-tabs .nav-link:hover, .bootstrap .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6; }
  .bootstrap .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent; }
  .bootstrap .nav-tabs .nav-link.active,
  .bootstrap .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .bootstrap .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .bootstrap .nav-pills .nav-link {
    border-radius: 0.25rem; }
  .bootstrap .nav-pills .nav-link.active,
  .bootstrap .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff; }
  .bootstrap .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center; }
  .bootstrap .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center; }
  .bootstrap .tab-content > .tab-pane {
    display: none; }
  .bootstrap .tab-content > .active {
    display: block; }
  .bootstrap .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
  .bootstrap .navbar > .container,
  .bootstrap .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .bootstrap .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
  .bootstrap .navbar-brand:hover, .bootstrap .navbar-brand:focus {
    text-decoration: none; }
  .bootstrap .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .bootstrap .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .bootstrap .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .bootstrap .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .bootstrap .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .bootstrap .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  .bootstrap .navbar-toggler:hover, .bootstrap .navbar-toggler:focus {
    text-decoration: none; }
  .bootstrap .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .bootstrap .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }
  @media (max-width: 575.98px) {
    .bootstrap .navbar-expand-sm > .container,
    .bootstrap .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 576px) {
    .bootstrap .navbar-expand-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .bootstrap .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .bootstrap .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute; }
    .bootstrap .navbar-expand-sm .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .bootstrap .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .bootstrap .navbar-expand-sm > .container,
    .bootstrap .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .bootstrap .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .bootstrap .navbar-expand-sm .navbar-toggler {
      display: none; }
    .bootstrap .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
  @media (max-width: 767.98px) {
    .bootstrap .navbar-expand-md > .container,
    .bootstrap .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 768px) {
    .bootstrap .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .bootstrap .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .bootstrap .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute; }
    .bootstrap .navbar-expand-md .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .bootstrap .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .bootstrap .navbar-expand-md > .container,
    .bootstrap .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .bootstrap .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .bootstrap .navbar-expand-md .navbar-toggler {
      display: none; }
    .bootstrap .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
  @media (max-width: 991.98px) {
    .bootstrap .navbar-expand-lg > .container,
    .bootstrap .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 992px) {
    .bootstrap .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .bootstrap .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .bootstrap .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute; }
    .bootstrap .navbar-expand-lg .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .bootstrap .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .bootstrap .navbar-expand-lg > .container,
    .bootstrap .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .bootstrap .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .bootstrap .navbar-expand-lg .navbar-toggler {
      display: none; }
    .bootstrap .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
  @media (max-width: 1199.98px) {
    .bootstrap .navbar-expand-xl > .container,
    .bootstrap .navbar-expand-xl > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 1200px) {
    .bootstrap .navbar-expand-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .bootstrap .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .bootstrap .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute; }
    .bootstrap .navbar-expand-xl .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .bootstrap .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .bootstrap .navbar-expand-xl > .container,
    .bootstrap .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .bootstrap .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .bootstrap .navbar-expand-xl .navbar-toggler {
      display: none; }
    .bootstrap .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }
  .bootstrap .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .bootstrap .navbar-expand > .container,
  .bootstrap .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .bootstrap .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .bootstrap .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
  .bootstrap .navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .bootstrap .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .bootstrap .navbar-expand > .container,
  .bootstrap .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .bootstrap .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .bootstrap .navbar-expand .navbar-toggler {
    display: none; }
  .bootstrap .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }
  .bootstrap .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
  .bootstrap .navbar-light .navbar-brand:hover, .bootstrap .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }
  .bootstrap .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5); }
  .bootstrap .navbar-light .navbar-nav .nav-link:hover, .bootstrap .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .bootstrap .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }
  .bootstrap .navbar-light .navbar-nav .show > .nav-link,
  .bootstrap .navbar-light .navbar-nav .active > .nav-link,
  .bootstrap .navbar-light .navbar-nav .nav-link.show,
  .bootstrap .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }
  .bootstrap .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1); }
  .bootstrap .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .bootstrap .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5); }
  .bootstrap .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
  .bootstrap .navbar-light .navbar-text a:hover, .bootstrap .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }
  .bootstrap .navbar-dark .navbar-brand {
    color: #fff; }
  .bootstrap .navbar-dark .navbar-brand:hover, .bootstrap .navbar-dark .navbar-brand:focus {
    color: #fff; }
  .bootstrap .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
  .bootstrap .navbar-dark .navbar-nav .nav-link:hover, .bootstrap .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .bootstrap .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
  .bootstrap .navbar-dark .navbar-nav .show > .nav-link,
  .bootstrap .navbar-dark .navbar-nav .active > .nav-link,
  .bootstrap .navbar-dark .navbar-nav .nav-link.show,
  .bootstrap .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }
  .bootstrap .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }
  .bootstrap .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .bootstrap .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5); }
  .bootstrap .navbar-dark .navbar-text a {
    color: #fff; }
  .bootstrap .navbar-dark .navbar-text a:hover, .bootstrap .navbar-dark .navbar-text a:focus {
    color: #fff; }
  .bootstrap .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem; }
  .bootstrap .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .bootstrap .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .bootstrap .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .bootstrap .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem; }
  .bootstrap .card-title {
    margin-bottom: 0.75rem; }
  .bootstrap .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }
  .bootstrap .card-text:last-child {
    margin-bottom: 0; }
  .bootstrap .card-link:hover {
    text-decoration: none; }
  .bootstrap .card-link + .card-link {
    margin-left: 1.25rem; }
  .bootstrap .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .bootstrap .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .bootstrap .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }
  .bootstrap .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .bootstrap .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  .bootstrap .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0; }
  .bootstrap .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
  .bootstrap .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem; }
  .bootstrap .card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px); }
  .bootstrap .card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .bootstrap .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .bootstrap .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .bootstrap .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .bootstrap .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
    .bootstrap .card-deck .card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }
  .bootstrap .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .bootstrap .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .bootstrap .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
    .bootstrap .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0; }
    .bootstrap .card-group > .card + .card {
      margin-left: 0;
      border-left: 0; }
    .bootstrap .card-group > .card:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .bootstrap .card-group > .card:first-child .card-img-top,
    .bootstrap .card-group > .card:first-child .card-header {
      border-top-right-radius: 0; }
    .bootstrap .card-group > .card:first-child .card-img-bottom,
    .bootstrap .card-group > .card:first-child .card-footer {
      border-bottom-right-radius: 0; }
    .bootstrap .card-group > .card:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .bootstrap .card-group > .card:last-child .card-img-top,
    .bootstrap .card-group > .card:last-child .card-header {
      border-top-left-radius: 0; }
    .bootstrap .card-group > .card:last-child .card-img-bottom,
    .bootstrap .card-group > .card:last-child .card-footer {
      border-bottom-left-radius: 0; }
    .bootstrap .card-group > .card:only-child {
      border-radius: 0.25rem; }
    .bootstrap .card-group > .card:only-child .card-img-top,
    .bootstrap .card-group > .card:only-child .card-header {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .bootstrap .card-group > .card:only-child .card-img-bottom,
    .bootstrap .card-group > .card:only-child .card-footer {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
    .bootstrap .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
      border-radius: 0; }
    .bootstrap .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .bootstrap .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .bootstrap .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .bootstrap .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
      border-radius: 0; } }
  .bootstrap .card-columns .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    .bootstrap .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem; }
    .bootstrap .card-columns .card {
      display: inline-block;
      width: 100%; } }
  .bootstrap .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  .bootstrap .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/"; }
  .bootstrap .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .bootstrap .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .bootstrap .breadcrumb-item.active {
    color: #6c757d; }
  .bootstrap .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem; }
  .bootstrap .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6; }
  .bootstrap .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .bootstrap .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .bootstrap .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .bootstrap .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .bootstrap .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  .bootstrap .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .bootstrap .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6; }
  .bootstrap .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5; }
  .bootstrap .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }
  .bootstrap .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .bootstrap .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5; }
  .bootstrap .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem; }
  .bootstrap .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem; }
  .bootstrap .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem; }
  .bootstrap .badge:empty {
    display: none; }
  .bootstrap .btn .badge {
    position: relative;
    top: -1px; }
  .bootstrap .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem; }
  .bootstrap .badge-primary {
    color: #fff;
    background-color: #007bff; }
  .bootstrap .badge-primary[href]:hover, .bootstrap .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }
  .bootstrap .badge-secondary {
    color: #fff;
    background-color: #6c757d; }
  .bootstrap .badge-secondary[href]:hover, .bootstrap .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }
  .bootstrap .badge-success {
    color: #fff;
    background-color: #28a745; }
  .bootstrap .badge-success[href]:hover, .bootstrap .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }
  .bootstrap .badge-info {
    color: #fff;
    background-color: #17a2b8; }
  .bootstrap .badge-info[href]:hover, .bootstrap .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }
  .bootstrap .badge-warning {
    color: #212529;
    background-color: #ffc107; }
  .bootstrap .badge-warning[href]:hover, .bootstrap .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }
  .bootstrap .badge-danger {
    color: #fff;
    background-color: #dc3545; }
  .bootstrap .badge-danger[href]:hover, .bootstrap .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }
  .bootstrap .badge-light {
    color: #212529;
    background-color: #f8f9fa; }
  .bootstrap .badge-light[href]:hover, .bootstrap .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }
  .bootstrap .badge-dark {
    color: #fff;
    background-color: #343a40; }
  .bootstrap .badge-dark[href]:hover, .bootstrap .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }
  .bootstrap .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .bootstrap .jumbotron {
      padding: 4rem 2rem; } }
  .bootstrap .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0; }
  .bootstrap .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  .bootstrap .alert-heading {
    color: inherit; }
  .bootstrap .alert-link {
    font-weight: 700; }
  .bootstrap .alert-dismissible {
    padding-right: 4rem; }
  .bootstrap .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }
  .bootstrap .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff; }
  .bootstrap .alert-primary hr {
    border-top-color: #9fcdff; }
  .bootstrap .alert-primary .alert-link {
    color: #002752; }
  .bootstrap .alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db; }
  .bootstrap .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .bootstrap .alert-secondary .alert-link {
    color: #202326; }
  .bootstrap .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
  .bootstrap .alert-success hr {
    border-top-color: #b1dfbb; }
  .bootstrap .alert-success .alert-link {
    color: #0b2e13; }
  .bootstrap .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb; }
  .bootstrap .alert-info hr {
    border-top-color: #abdde5; }
  .bootstrap .alert-info .alert-link {
    color: #062c33; }
  .bootstrap .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba; }
  .bootstrap .alert-warning hr {
    border-top-color: #ffe8a1; }
  .bootstrap .alert-warning .alert-link {
    color: #533f03; }
  .bootstrap .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .bootstrap .alert-danger hr {
    border-top-color: #f1b0b7; }
  .bootstrap .alert-danger .alert-link {
    color: #491217; }
  .bootstrap .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe; }
  .bootstrap .alert-light hr {
    border-top-color: #ececf6; }
  .bootstrap .alert-light .alert-link {
    color: #686868; }
  .bootstrap .alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca; }
  .bootstrap .alert-dark hr {
    border-top-color: #b9bbbe; }
  .bootstrap .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
  .bootstrap .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  .bootstrap .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width 0.6s ease; }
  .bootstrap .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  .bootstrap .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite; }
  .bootstrap .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .bootstrap .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .bootstrap .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0; }
  .bootstrap .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit; }
  .bootstrap .list-group-item-action:hover, .bootstrap .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .bootstrap .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }
  .bootstrap .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
  .bootstrap .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .bootstrap .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .bootstrap .list-group-item:hover, .bootstrap .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .bootstrap .list-group-item.disabled, .bootstrap .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .bootstrap .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .bootstrap .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0; }
  .bootstrap .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0; }
  .bootstrap .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0; }
  .bootstrap .list-group-item-primary {
    color: #004085;
    background-color: #b8daff; }
  .bootstrap .list-group-item-primary.list-group-item-action:hover, .bootstrap .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .bootstrap .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }
  .bootstrap .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db; }
  .bootstrap .list-group-item-secondary.list-group-item-action:hover, .bootstrap .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .bootstrap .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }
  .bootstrap .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb; }
  .bootstrap .list-group-item-success.list-group-item-action:hover, .bootstrap .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .bootstrap .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }
  .bootstrap .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb; }
  .bootstrap .list-group-item-info.list-group-item-action:hover, .bootstrap .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .bootstrap .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }
  .bootstrap .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba; }
  .bootstrap .list-group-item-warning.list-group-item-action:hover, .bootstrap .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .bootstrap .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }
  .bootstrap .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb; }
  .bootstrap .list-group-item-danger.list-group-item-action:hover, .bootstrap .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .bootstrap .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }
  .bootstrap .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe; }
  .bootstrap .list-group-item-light.list-group-item-action:hover, .bootstrap .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .bootstrap .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }
  .bootstrap .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca; }
  .bootstrap .list-group-item-dark.list-group-item-action:hover, .bootstrap .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .bootstrap .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }
  .bootstrap .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5; }
  .bootstrap .close:hover, .bootstrap .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .bootstrap .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .bootstrap button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
  .bootstrap .modal-open {
    overflow: hidden; }
  .bootstrap .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0; }
  .bootstrap .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }
  .bootstrap .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
  .bootstrap .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%); }
  .bootstrap .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  .bootstrap .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2)); }
  .bootstrap .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0; }
  .bootstrap .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000; }
  .bootstrap .modal-backdrop.fade {
    opacity: 0; }
  .bootstrap .modal-backdrop.show {
    opacity: 0.5; }
  .bootstrap .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
  .bootstrap .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }
  .bootstrap .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .bootstrap .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem; }
  .bootstrap .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef; }
  .bootstrap .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .bootstrap .modal-footer > :not(:last-child) {
    margin-right: .25rem; }
  .bootstrap .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  @media (min-width: 576px) {
    .bootstrap .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .bootstrap .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2)); }
    .bootstrap .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    .bootstrap .modal-lg {
      max-width: 800px; } }
  .bootstrap .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0; }
  .bootstrap .tooltip.show {
    opacity: 0.9; }
  .bootstrap .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
  .bootstrap .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid; }
  .bootstrap .bs-tooltip-top, .bootstrap .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0; }
  .bootstrap .bs-tooltip-top .arrow, .bootstrap .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
  .bootstrap .bs-tooltip-top .arrow::before, .bootstrap .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000; }
  .bootstrap .bs-tooltip-right, .bootstrap .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem; }
  .bootstrap .bs-tooltip-right .arrow, .bootstrap .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .bootstrap .bs-tooltip-right .arrow::before, .bootstrap .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000; }
  .bootstrap .bs-tooltip-bottom, .bootstrap .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0; }
  .bootstrap .bs-tooltip-bottom .arrow, .bootstrap .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
  .bootstrap .bs-tooltip-bottom .arrow::before, .bootstrap .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000; }
  .bootstrap .bs-tooltip-left, .bootstrap .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem; }
  .bootstrap .bs-tooltip-left .arrow, .bootstrap .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .bootstrap .bs-tooltip-left .arrow::before, .bootstrap .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000; }
  .bootstrap .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem; }
  .bootstrap .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem; }
  .bootstrap .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
  .bootstrap .popover .arrow::before, .bootstrap .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid; }
  .bootstrap .bs-popover-top, .bootstrap .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem; }
  .bootstrap .bs-popover-top .arrow, .bootstrap .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bootstrap .bs-popover-top .arrow::before, .bootstrap .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bootstrap .bs-popover-top .arrow::after, .bootstrap .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bootstrap .bs-popover-top .arrow::before, .bootstrap .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bootstrap .bs-popover-top .arrow::after, .bootstrap .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }
  .bootstrap .bs-popover-right, .bootstrap .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem; }
  .bootstrap .bs-popover-right .arrow, .bootstrap .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bootstrap .bs-popover-right .arrow::before, .bootstrap .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bootstrap .bs-popover-right .arrow::after, .bootstrap .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bootstrap .bs-popover-right .arrow::before, .bootstrap .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bootstrap .bs-popover-right .arrow::after, .bootstrap .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }
  .bootstrap .bs-popover-bottom, .bootstrap .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem; }
  .bootstrap .bs-popover-bottom .arrow, .bootstrap .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bootstrap .bs-popover-bottom .arrow::before, .bootstrap .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bootstrap .bs-popover-bottom .arrow::after, .bootstrap .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bootstrap .bs-popover-bottom .arrow::before, .bootstrap .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bootstrap .bs-popover-bottom .arrow::after, .bootstrap .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bootstrap .bs-popover-bottom .popover-header::before, .bootstrap .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }
  .bootstrap .bs-popover-left, .bootstrap .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem; }
  .bootstrap .bs-popover-left .arrow, .bootstrap .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bootstrap .bs-popover-left .arrow::before, .bootstrap .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bootstrap .bs-popover-left .arrow::after, .bootstrap .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bootstrap .bs-popover-left .arrow::before, .bootstrap .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bootstrap .bs-popover-left .arrow::after, .bootstrap .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }
  .bootstrap .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
  .bootstrap .popover-header:empty {
    display: none; }
  .bootstrap .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529; }
  .bootstrap .carousel {
    position: relative; }
  .bootstrap .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
  .bootstrap .carousel-item {
    position: relative;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px; }
  .bootstrap .carousel-item.active,
  .bootstrap .carousel-item-next,
  .bootstrap .carousel-item-prev {
    display: block; }
  .bootstrap .carousel-item-next,
  .bootstrap .carousel-item-prev {
    position: absolute;
    top: 0; }
  .bootstrap .carousel-item-next.carousel-item-left,
  .bootstrap .carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  @supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
    .bootstrap .carousel-item-next.carousel-item-left,
    .bootstrap .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); } }
  .bootstrap .carousel-item-next,
  .bootstrap .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  @supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
    .bootstrap .carousel-item-next,
    .bootstrap .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0); } }
  .bootstrap .carousel-item-prev,
  .bootstrap .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  @supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
    .bootstrap .carousel-item-prev,
    .bootstrap .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0); } }
  .bootstrap .carousel-control-prev,
  .bootstrap .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5; }
  .bootstrap .carousel-control-prev:hover, .bootstrap .carousel-control-prev:focus,
  .bootstrap .carousel-control-next:hover,
  .bootstrap .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }
  .bootstrap .carousel-control-prev {
    left: 0; }
  .bootstrap .carousel-control-next {
    right: 0; }
  .bootstrap .carousel-control-prev-icon,
  .bootstrap .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%; }
  .bootstrap .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }
  .bootstrap .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }
  .bootstrap .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; }
  .bootstrap .carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
  .bootstrap .carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""; }
  .bootstrap .carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""; }
  .bootstrap .carousel-indicators .active {
    background-color: #fff; }
  .bootstrap .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center; }
  .bootstrap .align-baseline {
    vertical-align: baseline !important; }
  .bootstrap .align-top {
    vertical-align: top !important; }
  .bootstrap .align-middle {
    vertical-align: middle !important; }
  .bootstrap .align-bottom {
    vertical-align: bottom !important; }
  .bootstrap .align-text-bottom {
    vertical-align: text-bottom !important; }
  .bootstrap .align-text-top {
    vertical-align: text-top !important; }
  .bootstrap .bg-primary {
    background-color: #007bff !important; }
  .bootstrap a.bg-primary:hover, .bootstrap a.bg-primary:focus,
  .bootstrap button.bg-primary:hover,
  .bootstrap button.bg-primary:focus {
    background-color: #0062cc !important; }
  .bootstrap .bg-secondary {
    background-color: #6c757d !important; }
  .bootstrap a.bg-secondary:hover, .bootstrap a.bg-secondary:focus,
  .bootstrap button.bg-secondary:hover,
  .bootstrap button.bg-secondary:focus {
    background-color: #545b62 !important; }
  .bootstrap .bg-success {
    background-color: #28a745 !important; }
  .bootstrap a.bg-success:hover, .bootstrap a.bg-success:focus,
  .bootstrap button.bg-success:hover,
  .bootstrap button.bg-success:focus {
    background-color: #1e7e34 !important; }
  .bootstrap .bg-info {
    background-color: #17a2b8 !important; }
  .bootstrap a.bg-info:hover, .bootstrap a.bg-info:focus,
  .bootstrap button.bg-info:hover,
  .bootstrap button.bg-info:focus {
    background-color: #117a8b !important; }
  .bootstrap .bg-warning {
    background-color: #ffc107 !important; }
  .bootstrap a.bg-warning:hover, .bootstrap a.bg-warning:focus,
  .bootstrap button.bg-warning:hover,
  .bootstrap button.bg-warning:focus {
    background-color: #d39e00 !important; }
  .bootstrap .bg-danger {
    background-color: #dc3545 !important; }
  .bootstrap a.bg-danger:hover, .bootstrap a.bg-danger:focus,
  .bootstrap button.bg-danger:hover,
  .bootstrap button.bg-danger:focus {
    background-color: #bd2130 !important; }
  .bootstrap .bg-light {
    background-color: #f8f9fa !important; }
  .bootstrap a.bg-light:hover, .bootstrap a.bg-light:focus,
  .bootstrap button.bg-light:hover,
  .bootstrap button.bg-light:focus {
    background-color: #dae0e5 !important; }
  .bootstrap .bg-dark {
    background-color: #343a40 !important; }
  .bootstrap a.bg-dark:hover, .bootstrap a.bg-dark:focus,
  .bootstrap button.bg-dark:hover,
  .bootstrap button.bg-dark:focus {
    background-color: #1d2124 !important; }
  .bootstrap .bg-white {
    background-color: #fff !important; }
  .bootstrap .bg-transparent {
    background-color: transparent !important; }
  .bootstrap .border {
    border: 1px solid #dee2e6 !important; }
  .bootstrap .border-top {
    border-top: 1px solid #dee2e6 !important; }
  .bootstrap .border-right {
    border-right: 1px solid #dee2e6 !important; }
  .bootstrap .border-bottom {
    border-bottom: 1px solid #dee2e6 !important; }
  .bootstrap .border-left {
    border-left: 1px solid #dee2e6 !important; }
  .bootstrap .border-0 {
    border: 0 !important; }
  .bootstrap .border-top-0 {
    border-top: 0 !important; }
  .bootstrap .border-right-0 {
    border-right: 0 !important; }
  .bootstrap .border-bottom-0 {
    border-bottom: 0 !important; }
  .bootstrap .border-left-0 {
    border-left: 0 !important; }
  .bootstrap .border-primary {
    border-color: #007bff !important; }
  .bootstrap .border-secondary {
    border-color: #6c757d !important; }
  .bootstrap .border-success {
    border-color: #28a745 !important; }
  .bootstrap .border-info {
    border-color: #17a2b8 !important; }
  .bootstrap .border-warning {
    border-color: #ffc107 !important; }
  .bootstrap .border-danger {
    border-color: #dc3545 !important; }
  .bootstrap .border-light {
    border-color: #f8f9fa !important; }
  .bootstrap .border-dark {
    border-color: #343a40 !important; }
  .bootstrap .border-white {
    border-color: #fff !important; }
  .bootstrap .rounded {
    border-radius: 0.25rem !important; }
  .bootstrap .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important; }
  .bootstrap .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important; }
  .bootstrap .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  .bootstrap .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  .bootstrap .rounded-circle {
    border-radius: 50% !important; }
  .bootstrap .rounded-0 {
    border-radius: 0 !important; }
  .bootstrap .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  .bootstrap .d-none {
    display: none !important; }
  .bootstrap .d-inline {
    display: inline !important; }
  .bootstrap .d-inline-block {
    display: inline-block !important; }
  .bootstrap .d-block {
    display: block !important; }
  .bootstrap .d-table {
    display: table !important; }
  .bootstrap .d-table-row {
    display: table-row !important; }
  .bootstrap .d-table-cell {
    display: table-cell !important; }
  .bootstrap .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .bootstrap .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  @media (min-width: 576px) {
    .bootstrap .d-sm-none {
      display: none !important; }
    .bootstrap .d-sm-inline {
      display: inline !important; }
    .bootstrap .d-sm-inline-block {
      display: inline-block !important; }
    .bootstrap .d-sm-block {
      display: block !important; }
    .bootstrap .d-sm-table {
      display: table !important; }
    .bootstrap .d-sm-table-row {
      display: table-row !important; }
    .bootstrap .d-sm-table-cell {
      display: table-cell !important; }
    .bootstrap .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .bootstrap .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media (min-width: 768px) {
    .bootstrap .d-md-none {
      display: none !important; }
    .bootstrap .d-md-inline {
      display: inline !important; }
    .bootstrap .d-md-inline-block {
      display: inline-block !important; }
    .bootstrap .d-md-block {
      display: block !important; }
    .bootstrap .d-md-table {
      display: table !important; }
    .bootstrap .d-md-table-row {
      display: table-row !important; }
    .bootstrap .d-md-table-cell {
      display: table-cell !important; }
    .bootstrap .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .bootstrap .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media (min-width: 992px) {
    .bootstrap .d-lg-none {
      display: none !important; }
    .bootstrap .d-lg-inline {
      display: inline !important; }
    .bootstrap .d-lg-inline-block {
      display: inline-block !important; }
    .bootstrap .d-lg-block {
      display: block !important; }
    .bootstrap .d-lg-table {
      display: table !important; }
    .bootstrap .d-lg-table-row {
      display: table-row !important; }
    .bootstrap .d-lg-table-cell {
      display: table-cell !important; }
    .bootstrap .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .bootstrap .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media (min-width: 1200px) {
    .bootstrap .d-xl-none {
      display: none !important; }
    .bootstrap .d-xl-inline {
      display: inline !important; }
    .bootstrap .d-xl-inline-block {
      display: inline-block !important; }
    .bootstrap .d-xl-block {
      display: block !important; }
    .bootstrap .d-xl-table {
      display: table !important; }
    .bootstrap .d-xl-table-row {
      display: table-row !important; }
    .bootstrap .d-xl-table-cell {
      display: table-cell !important; }
    .bootstrap .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .bootstrap .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media print {
    .bootstrap .d-print-none {
      display: none !important; }
    .bootstrap .d-print-inline {
      display: inline !important; }
    .bootstrap .d-print-inline-block {
      display: inline-block !important; }
    .bootstrap .d-print-block {
      display: block !important; }
    .bootstrap .d-print-table {
      display: table !important; }
    .bootstrap .d-print-table-row {
      display: table-row !important; }
    .bootstrap .d-print-table-cell {
      display: table-cell !important; }
    .bootstrap .d-print-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .bootstrap .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  .bootstrap .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden; }
  .bootstrap .embed-responsive::before {
    display: block;
    content: ""; }
  .bootstrap .embed-responsive .embed-responsive-item,
  .bootstrap .embed-responsive iframe,
  .bootstrap .embed-responsive embed,
  .bootstrap .embed-responsive object,
  .bootstrap .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  .bootstrap .embed-responsive-21by9::before {
    padding-top: 42.857143%; }
  .bootstrap .embed-responsive-16by9::before {
    padding-top: 56.25%; }
  .bootstrap .embed-responsive-4by3::before {
    padding-top: 75%; }
  .bootstrap .embed-responsive-1by1::before {
    padding-top: 100%; }
  .bootstrap .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .bootstrap .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .bootstrap .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .bootstrap .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .bootstrap .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .bootstrap .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .bootstrap .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .bootstrap .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .bootstrap .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .bootstrap .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .bootstrap .justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .bootstrap .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .bootstrap .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .bootstrap .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .bootstrap .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .bootstrap .align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .bootstrap .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .bootstrap .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .bootstrap .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .bootstrap .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .bootstrap .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .bootstrap .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .bootstrap .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .bootstrap .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .bootstrap .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .bootstrap .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .bootstrap .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .bootstrap .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .bootstrap .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; }
  @media (min-width: 576px) {
    .bootstrap .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .bootstrap .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .bootstrap .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .bootstrap .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .bootstrap .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .bootstrap .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .bootstrap .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .bootstrap .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .bootstrap .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .bootstrap .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .bootstrap .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .bootstrap .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .bootstrap .align-items-sm-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .bootstrap .align-items-sm-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .bootstrap .align-items-sm-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .bootstrap .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .bootstrap .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .bootstrap .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .bootstrap .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .bootstrap .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .bootstrap .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .bootstrap .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .bootstrap .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .bootstrap .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important; }
    .bootstrap .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .bootstrap .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .bootstrap .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important; }
    .bootstrap .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .bootstrap .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important; } }
  @media (min-width: 768px) {
    .bootstrap .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .bootstrap .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .bootstrap .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .bootstrap .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .bootstrap .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .bootstrap .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .bootstrap .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .bootstrap .justify-content-md-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .bootstrap .justify-content-md-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .bootstrap .justify-content-md-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .bootstrap .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .bootstrap .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .bootstrap .align-items-md-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .bootstrap .align-items-md-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .bootstrap .align-items-md-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .bootstrap .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .bootstrap .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .bootstrap .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .bootstrap .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .bootstrap .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .bootstrap .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .bootstrap .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .bootstrap .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .bootstrap .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important; }
    .bootstrap .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .bootstrap .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .bootstrap .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important; }
    .bootstrap .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .bootstrap .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important; } }
  @media (min-width: 992px) {
    .bootstrap .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .bootstrap .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .bootstrap .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .bootstrap .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .bootstrap .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .bootstrap .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .bootstrap .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .bootstrap .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .bootstrap .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .bootstrap .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .bootstrap .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .bootstrap .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .bootstrap .align-items-lg-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .bootstrap .align-items-lg-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .bootstrap .align-items-lg-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .bootstrap .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .bootstrap .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .bootstrap .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .bootstrap .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .bootstrap .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .bootstrap .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .bootstrap .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .bootstrap .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .bootstrap .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important; }
    .bootstrap .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .bootstrap .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .bootstrap .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important; }
    .bootstrap .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .bootstrap .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important; } }
  @media (min-width: 1200px) {
    .bootstrap .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .bootstrap .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .bootstrap .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .bootstrap .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .bootstrap .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .bootstrap .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .bootstrap .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .bootstrap .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .bootstrap .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .bootstrap .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .bootstrap .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .bootstrap .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .bootstrap .align-items-xl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .bootstrap .align-items-xl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .bootstrap .align-items-xl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .bootstrap .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .bootstrap .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .bootstrap .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .bootstrap .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .bootstrap .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .bootstrap .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .bootstrap .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .bootstrap .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .bootstrap .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important; }
    .bootstrap .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .bootstrap .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .bootstrap .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important; }
    .bootstrap .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .bootstrap .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important; } }
  .bootstrap .float-left {
    float: left !important; }
  .bootstrap .float-right {
    float: right !important; }
  .bootstrap .float-none {
    float: none !important; }
  @media (min-width: 576px) {
    .bootstrap .float-sm-left {
      float: left !important; }
    .bootstrap .float-sm-right {
      float: right !important; }
    .bootstrap .float-sm-none {
      float: none !important; } }
  @media (min-width: 768px) {
    .bootstrap .float-md-left {
      float: left !important; }
    .bootstrap .float-md-right {
      float: right !important; }
    .bootstrap .float-md-none {
      float: none !important; } }
  @media (min-width: 992px) {
    .bootstrap .float-lg-left {
      float: left !important; }
    .bootstrap .float-lg-right {
      float: right !important; }
    .bootstrap .float-lg-none {
      float: none !important; } }
  @media (min-width: 1200px) {
    .bootstrap .float-xl-left {
      float: left !important; }
    .bootstrap .float-xl-right {
      float: right !important; }
    .bootstrap .float-xl-none {
      float: none !important; } }
  .bootstrap .position-static {
    position: static !important; }
  .bootstrap .position-relative {
    position: relative !important; }
  .bootstrap .position-absolute {
    position: absolute !important; }
  .bootstrap .position-fixed {
    position: fixed !important; }
  .bootstrap .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; }
  .bootstrap .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  .bootstrap .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  @supports (position: -webkit-sticky) or (position: sticky) {
    .bootstrap .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  .bootstrap .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0; }
  .bootstrap .sr-only-focusable:active, .bootstrap .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none; }
  .bootstrap .w-25 {
    width: 25% !important; }
  .bootstrap .w-50 {
    width: 50% !important; }
  .bootstrap .w-75 {
    width: 75% !important; }
  .bootstrap .w-100 {
    width: 100% !important; }
  .bootstrap .h-25 {
    height: 25% !important; }
  .bootstrap .h-50 {
    height: 50% !important; }
  .bootstrap .h-75 {
    height: 75% !important; }
  .bootstrap .h-100 {
    height: 100% !important; }
  .bootstrap .mw-100 {
    max-width: 100% !important; }
  .bootstrap .mh-100 {
    max-height: 100% !important; }
  .bootstrap .m-0 {
    margin: 0 !important; }
  .bootstrap .mt-0,
  .bootstrap .my-0 {
    margin-top: 0 !important; }
  .bootstrap .mr-0,
  .bootstrap .mx-0 {
    margin-right: 0 !important; }
  .bootstrap .mb-0,
  .bootstrap .my-0 {
    margin-bottom: 0 !important; }
  .bootstrap .ml-0,
  .bootstrap .mx-0 {
    margin-left: 0 !important; }
  .bootstrap .m-1 {
    margin: 0.25rem !important; }
  .bootstrap .mt-1,
  .bootstrap .my-1 {
    margin-top: 0.25rem !important; }
  .bootstrap .mr-1,
  .bootstrap .mx-1 {
    margin-right: 0.25rem !important; }
  .bootstrap .mb-1,
  .bootstrap .my-1 {
    margin-bottom: 0.25rem !important; }
  .bootstrap .ml-1,
  .bootstrap .mx-1 {
    margin-left: 0.25rem !important; }
  .bootstrap .m-2 {
    margin: 0.5rem !important; }
  .bootstrap .mt-2,
  .bootstrap .my-2 {
    margin-top: 0.5rem !important; }
  .bootstrap .mr-2,
  .bootstrap .mx-2 {
    margin-right: 0.5rem !important; }
  .bootstrap .mb-2,
  .bootstrap .my-2 {
    margin-bottom: 0.5rem !important; }
  .bootstrap .ml-2,
  .bootstrap .mx-2 {
    margin-left: 0.5rem !important; }
  .bootstrap .m-3 {
    margin: 1rem !important; }
  .bootstrap .mt-3,
  .bootstrap .my-3 {
    margin-top: 1rem !important; }
  .bootstrap .mr-3,
  .bootstrap .mx-3 {
    margin-right: 1rem !important; }
  .bootstrap .mb-3,
  .bootstrap .my-3 {
    margin-bottom: 1rem !important; }
  .bootstrap .ml-3,
  .bootstrap .mx-3 {
    margin-left: 1rem !important; }
  .bootstrap .m-4 {
    margin: 1.5rem !important; }
  .bootstrap .mt-4,
  .bootstrap .my-4 {
    margin-top: 1.5rem !important; }
  .bootstrap .mr-4,
  .bootstrap .mx-4 {
    margin-right: 1.5rem !important; }
  .bootstrap .mb-4,
  .bootstrap .my-4 {
    margin-bottom: 1.5rem !important; }
  .bootstrap .ml-4,
  .bootstrap .mx-4 {
    margin-left: 1.5rem !important; }
  .bootstrap .m-5 {
    margin: 3rem !important; }
  .bootstrap .mt-5,
  .bootstrap .my-5 {
    margin-top: 3rem !important; }
  .bootstrap .mr-5,
  .bootstrap .mx-5 {
    margin-right: 3rem !important; }
  .bootstrap .mb-5,
  .bootstrap .my-5 {
    margin-bottom: 3rem !important; }
  .bootstrap .ml-5,
  .bootstrap .mx-5 {
    margin-left: 3rem !important; }
  .bootstrap .p-0 {
    padding: 0 !important; }
  .bootstrap .pt-0,
  .bootstrap .py-0 {
    padding-top: 0 !important; }
  .bootstrap .pr-0,
  .bootstrap .px-0 {
    padding-right: 0 !important; }
  .bootstrap .pb-0,
  .bootstrap .py-0 {
    padding-bottom: 0 !important; }
  .bootstrap .pl-0,
  .bootstrap .px-0 {
    padding-left: 0 !important; }
  .bootstrap .p-1 {
    padding: 0.25rem !important; }
  .bootstrap .pt-1,
  .bootstrap .py-1 {
    padding-top: 0.25rem !important; }
  .bootstrap .pr-1,
  .bootstrap .px-1 {
    padding-right: 0.25rem !important; }
  .bootstrap .pb-1,
  .bootstrap .py-1 {
    padding-bottom: 0.25rem !important; }
  .bootstrap .pl-1,
  .bootstrap .px-1 {
    padding-left: 0.25rem !important; }
  .bootstrap .p-2 {
    padding: 0.5rem !important; }
  .bootstrap .pt-2,
  .bootstrap .py-2 {
    padding-top: 0.5rem !important; }
  .bootstrap .pr-2,
  .bootstrap .px-2 {
    padding-right: 0.5rem !important; }
  .bootstrap .pb-2,
  .bootstrap .py-2 {
    padding-bottom: 0.5rem !important; }
  .bootstrap .pl-2,
  .bootstrap .px-2 {
    padding-left: 0.5rem !important; }
  .bootstrap .p-3 {
    padding: 1rem !important; }
  .bootstrap .pt-3,
  .bootstrap .py-3 {
    padding-top: 1rem !important; }
  .bootstrap .pr-3,
  .bootstrap .px-3 {
    padding-right: 1rem !important; }
  .bootstrap .pb-3,
  .bootstrap .py-3 {
    padding-bottom: 1rem !important; }
  .bootstrap .pl-3,
  .bootstrap .px-3 {
    padding-left: 1rem !important; }
  .bootstrap .p-4 {
    padding: 1.5rem !important; }
  .bootstrap .pt-4,
  .bootstrap .py-4 {
    padding-top: 1.5rem !important; }
  .bootstrap .pr-4,
  .bootstrap .px-4 {
    padding-right: 1.5rem !important; }
  .bootstrap .pb-4,
  .bootstrap .py-4 {
    padding-bottom: 1.5rem !important; }
  .bootstrap .pl-4,
  .bootstrap .px-4 {
    padding-left: 1.5rem !important; }
  .bootstrap .p-5 {
    padding: 3rem !important; }
  .bootstrap .pt-5,
  .bootstrap .py-5 {
    padding-top: 3rem !important; }
  .bootstrap .pr-5,
  .bootstrap .px-5 {
    padding-right: 3rem !important; }
  .bootstrap .pb-5,
  .bootstrap .py-5 {
    padding-bottom: 3rem !important; }
  .bootstrap .pl-5,
  .bootstrap .px-5 {
    padding-left: 3rem !important; }
  .bootstrap .m-auto {
    margin: auto !important; }
  .bootstrap .mt-auto,
  .bootstrap .my-auto {
    margin-top: auto !important; }
  .bootstrap .mr-auto,
  .bootstrap .mx-auto {
    margin-right: auto !important; }
  .bootstrap .mb-auto,
  .bootstrap .my-auto {
    margin-bottom: auto !important; }
  .bootstrap .ml-auto,
  .bootstrap .mx-auto {
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .bootstrap .m-sm-0 {
      margin: 0 !important; }
    .bootstrap .mt-sm-0,
    .bootstrap .my-sm-0 {
      margin-top: 0 !important; }
    .bootstrap .mr-sm-0,
    .bootstrap .mx-sm-0 {
      margin-right: 0 !important; }
    .bootstrap .mb-sm-0,
    .bootstrap .my-sm-0 {
      margin-bottom: 0 !important; }
    .bootstrap .ml-sm-0,
    .bootstrap .mx-sm-0 {
      margin-left: 0 !important; }
    .bootstrap .m-sm-1 {
      margin: 0.25rem !important; }
    .bootstrap .mt-sm-1,
    .bootstrap .my-sm-1 {
      margin-top: 0.25rem !important; }
    .bootstrap .mr-sm-1,
    .bootstrap .mx-sm-1 {
      margin-right: 0.25rem !important; }
    .bootstrap .mb-sm-1,
    .bootstrap .my-sm-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap .ml-sm-1,
    .bootstrap .mx-sm-1 {
      margin-left: 0.25rem !important; }
    .bootstrap .m-sm-2 {
      margin: 0.5rem !important; }
    .bootstrap .mt-sm-2,
    .bootstrap .my-sm-2 {
      margin-top: 0.5rem !important; }
    .bootstrap .mr-sm-2,
    .bootstrap .mx-sm-2 {
      margin-right: 0.5rem !important; }
    .bootstrap .mb-sm-2,
    .bootstrap .my-sm-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap .ml-sm-2,
    .bootstrap .mx-sm-2 {
      margin-left: 0.5rem !important; }
    .bootstrap .m-sm-3 {
      margin: 1rem !important; }
    .bootstrap .mt-sm-3,
    .bootstrap .my-sm-3 {
      margin-top: 1rem !important; }
    .bootstrap .mr-sm-3,
    .bootstrap .mx-sm-3 {
      margin-right: 1rem !important; }
    .bootstrap .mb-sm-3,
    .bootstrap .my-sm-3 {
      margin-bottom: 1rem !important; }
    .bootstrap .ml-sm-3,
    .bootstrap .mx-sm-3 {
      margin-left: 1rem !important; }
    .bootstrap .m-sm-4 {
      margin: 1.5rem !important; }
    .bootstrap .mt-sm-4,
    .bootstrap .my-sm-4 {
      margin-top: 1.5rem !important; }
    .bootstrap .mr-sm-4,
    .bootstrap .mx-sm-4 {
      margin-right: 1.5rem !important; }
    .bootstrap .mb-sm-4,
    .bootstrap .my-sm-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap .ml-sm-4,
    .bootstrap .mx-sm-4 {
      margin-left: 1.5rem !important; }
    .bootstrap .m-sm-5 {
      margin: 3rem !important; }
    .bootstrap .mt-sm-5,
    .bootstrap .my-sm-5 {
      margin-top: 3rem !important; }
    .bootstrap .mr-sm-5,
    .bootstrap .mx-sm-5 {
      margin-right: 3rem !important; }
    .bootstrap .mb-sm-5,
    .bootstrap .my-sm-5 {
      margin-bottom: 3rem !important; }
    .bootstrap .ml-sm-5,
    .bootstrap .mx-sm-5 {
      margin-left: 3rem !important; }
    .bootstrap .p-sm-0 {
      padding: 0 !important; }
    .bootstrap .pt-sm-0,
    .bootstrap .py-sm-0 {
      padding-top: 0 !important; }
    .bootstrap .pr-sm-0,
    .bootstrap .px-sm-0 {
      padding-right: 0 !important; }
    .bootstrap .pb-sm-0,
    .bootstrap .py-sm-0 {
      padding-bottom: 0 !important; }
    .bootstrap .pl-sm-0,
    .bootstrap .px-sm-0 {
      padding-left: 0 !important; }
    .bootstrap .p-sm-1 {
      padding: 0.25rem !important; }
    .bootstrap .pt-sm-1,
    .bootstrap .py-sm-1 {
      padding-top: 0.25rem !important; }
    .bootstrap .pr-sm-1,
    .bootstrap .px-sm-1 {
      padding-right: 0.25rem !important; }
    .bootstrap .pb-sm-1,
    .bootstrap .py-sm-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap .pl-sm-1,
    .bootstrap .px-sm-1 {
      padding-left: 0.25rem !important; }
    .bootstrap .p-sm-2 {
      padding: 0.5rem !important; }
    .bootstrap .pt-sm-2,
    .bootstrap .py-sm-2 {
      padding-top: 0.5rem !important; }
    .bootstrap .pr-sm-2,
    .bootstrap .px-sm-2 {
      padding-right: 0.5rem !important; }
    .bootstrap .pb-sm-2,
    .bootstrap .py-sm-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap .pl-sm-2,
    .bootstrap .px-sm-2 {
      padding-left: 0.5rem !important; }
    .bootstrap .p-sm-3 {
      padding: 1rem !important; }
    .bootstrap .pt-sm-3,
    .bootstrap .py-sm-3 {
      padding-top: 1rem !important; }
    .bootstrap .pr-sm-3,
    .bootstrap .px-sm-3 {
      padding-right: 1rem !important; }
    .bootstrap .pb-sm-3,
    .bootstrap .py-sm-3 {
      padding-bottom: 1rem !important; }
    .bootstrap .pl-sm-3,
    .bootstrap .px-sm-3 {
      padding-left: 1rem !important; }
    .bootstrap .p-sm-4 {
      padding: 1.5rem !important; }
    .bootstrap .pt-sm-4,
    .bootstrap .py-sm-4 {
      padding-top: 1.5rem !important; }
    .bootstrap .pr-sm-4,
    .bootstrap .px-sm-4 {
      padding-right: 1.5rem !important; }
    .bootstrap .pb-sm-4,
    .bootstrap .py-sm-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap .pl-sm-4,
    .bootstrap .px-sm-4 {
      padding-left: 1.5rem !important; }
    .bootstrap .p-sm-5 {
      padding: 3rem !important; }
    .bootstrap .pt-sm-5,
    .bootstrap .py-sm-5 {
      padding-top: 3rem !important; }
    .bootstrap .pr-sm-5,
    .bootstrap .px-sm-5 {
      padding-right: 3rem !important; }
    .bootstrap .pb-sm-5,
    .bootstrap .py-sm-5 {
      padding-bottom: 3rem !important; }
    .bootstrap .pl-sm-5,
    .bootstrap .px-sm-5 {
      padding-left: 3rem !important; }
    .bootstrap .m-sm-auto {
      margin: auto !important; }
    .bootstrap .mt-sm-auto,
    .bootstrap .my-sm-auto {
      margin-top: auto !important; }
    .bootstrap .mr-sm-auto,
    .bootstrap .mx-sm-auto {
      margin-right: auto !important; }
    .bootstrap .mb-sm-auto,
    .bootstrap .my-sm-auto {
      margin-bottom: auto !important; }
    .bootstrap .ml-sm-auto,
    .bootstrap .mx-sm-auto {
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .bootstrap .m-md-0 {
      margin: 0 !important; }
    .bootstrap .mt-md-0,
    .bootstrap .my-md-0 {
      margin-top: 0 !important; }
    .bootstrap .mr-md-0,
    .bootstrap .mx-md-0 {
      margin-right: 0 !important; }
    .bootstrap .mb-md-0,
    .bootstrap .my-md-0 {
      margin-bottom: 0 !important; }
    .bootstrap .ml-md-0,
    .bootstrap .mx-md-0 {
      margin-left: 0 !important; }
    .bootstrap .m-md-1 {
      margin: 0.25rem !important; }
    .bootstrap .mt-md-1,
    .bootstrap .my-md-1 {
      margin-top: 0.25rem !important; }
    .bootstrap .mr-md-1,
    .bootstrap .mx-md-1 {
      margin-right: 0.25rem !important; }
    .bootstrap .mb-md-1,
    .bootstrap .my-md-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap .ml-md-1,
    .bootstrap .mx-md-1 {
      margin-left: 0.25rem !important; }
    .bootstrap .m-md-2 {
      margin: 0.5rem !important; }
    .bootstrap .mt-md-2,
    .bootstrap .my-md-2 {
      margin-top: 0.5rem !important; }
    .bootstrap .mr-md-2,
    .bootstrap .mx-md-2 {
      margin-right: 0.5rem !important; }
    .bootstrap .mb-md-2,
    .bootstrap .my-md-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap .ml-md-2,
    .bootstrap .mx-md-2 {
      margin-left: 0.5rem !important; }
    .bootstrap .m-md-3 {
      margin: 1rem !important; }
    .bootstrap .mt-md-3,
    .bootstrap .my-md-3 {
      margin-top: 1rem !important; }
    .bootstrap .mr-md-3,
    .bootstrap .mx-md-3 {
      margin-right: 1rem !important; }
    .bootstrap .mb-md-3,
    .bootstrap .my-md-3 {
      margin-bottom: 1rem !important; }
    .bootstrap .ml-md-3,
    .bootstrap .mx-md-3 {
      margin-left: 1rem !important; }
    .bootstrap .m-md-4 {
      margin: 1.5rem !important; }
    .bootstrap .mt-md-4,
    .bootstrap .my-md-4 {
      margin-top: 1.5rem !important; }
    .bootstrap .mr-md-4,
    .bootstrap .mx-md-4 {
      margin-right: 1.5rem !important; }
    .bootstrap .mb-md-4,
    .bootstrap .my-md-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap .ml-md-4,
    .bootstrap .mx-md-4 {
      margin-left: 1.5rem !important; }
    .bootstrap .m-md-5 {
      margin: 3rem !important; }
    .bootstrap .mt-md-5,
    .bootstrap .my-md-5 {
      margin-top: 3rem !important; }
    .bootstrap .mr-md-5,
    .bootstrap .mx-md-5 {
      margin-right: 3rem !important; }
    .bootstrap .mb-md-5,
    .bootstrap .my-md-5 {
      margin-bottom: 3rem !important; }
    .bootstrap .ml-md-5,
    .bootstrap .mx-md-5 {
      margin-left: 3rem !important; }
    .bootstrap .p-md-0 {
      padding: 0 !important; }
    .bootstrap .pt-md-0,
    .bootstrap .py-md-0 {
      padding-top: 0 !important; }
    .bootstrap .pr-md-0,
    .bootstrap .px-md-0 {
      padding-right: 0 !important; }
    .bootstrap .pb-md-0,
    .bootstrap .py-md-0 {
      padding-bottom: 0 !important; }
    .bootstrap .pl-md-0,
    .bootstrap .px-md-0 {
      padding-left: 0 !important; }
    .bootstrap .p-md-1 {
      padding: 0.25rem !important; }
    .bootstrap .pt-md-1,
    .bootstrap .py-md-1 {
      padding-top: 0.25rem !important; }
    .bootstrap .pr-md-1,
    .bootstrap .px-md-1 {
      padding-right: 0.25rem !important; }
    .bootstrap .pb-md-1,
    .bootstrap .py-md-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap .pl-md-1,
    .bootstrap .px-md-1 {
      padding-left: 0.25rem !important; }
    .bootstrap .p-md-2 {
      padding: 0.5rem !important; }
    .bootstrap .pt-md-2,
    .bootstrap .py-md-2 {
      padding-top: 0.5rem !important; }
    .bootstrap .pr-md-2,
    .bootstrap .px-md-2 {
      padding-right: 0.5rem !important; }
    .bootstrap .pb-md-2,
    .bootstrap .py-md-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap .pl-md-2,
    .bootstrap .px-md-2 {
      padding-left: 0.5rem !important; }
    .bootstrap .p-md-3 {
      padding: 1rem !important; }
    .bootstrap .pt-md-3,
    .bootstrap .py-md-3 {
      padding-top: 1rem !important; }
    .bootstrap .pr-md-3,
    .bootstrap .px-md-3 {
      padding-right: 1rem !important; }
    .bootstrap .pb-md-3,
    .bootstrap .py-md-3 {
      padding-bottom: 1rem !important; }
    .bootstrap .pl-md-3,
    .bootstrap .px-md-3 {
      padding-left: 1rem !important; }
    .bootstrap .p-md-4 {
      padding: 1.5rem !important; }
    .bootstrap .pt-md-4,
    .bootstrap .py-md-4 {
      padding-top: 1.5rem !important; }
    .bootstrap .pr-md-4,
    .bootstrap .px-md-4 {
      padding-right: 1.5rem !important; }
    .bootstrap .pb-md-4,
    .bootstrap .py-md-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap .pl-md-4,
    .bootstrap .px-md-4 {
      padding-left: 1.5rem !important; }
    .bootstrap .p-md-5 {
      padding: 3rem !important; }
    .bootstrap .pt-md-5,
    .bootstrap .py-md-5 {
      padding-top: 3rem !important; }
    .bootstrap .pr-md-5,
    .bootstrap .px-md-5 {
      padding-right: 3rem !important; }
    .bootstrap .pb-md-5,
    .bootstrap .py-md-5 {
      padding-bottom: 3rem !important; }
    .bootstrap .pl-md-5,
    .bootstrap .px-md-5 {
      padding-left: 3rem !important; }
    .bootstrap .m-md-auto {
      margin: auto !important; }
    .bootstrap .mt-md-auto,
    .bootstrap .my-md-auto {
      margin-top: auto !important; }
    .bootstrap .mr-md-auto,
    .bootstrap .mx-md-auto {
      margin-right: auto !important; }
    .bootstrap .mb-md-auto,
    .bootstrap .my-md-auto {
      margin-bottom: auto !important; }
    .bootstrap .ml-md-auto,
    .bootstrap .mx-md-auto {
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .bootstrap .m-lg-0 {
      margin: 0 !important; }
    .bootstrap .mt-lg-0,
    .bootstrap .my-lg-0 {
      margin-top: 0 !important; }
    .bootstrap .mr-lg-0,
    .bootstrap .mx-lg-0 {
      margin-right: 0 !important; }
    .bootstrap .mb-lg-0,
    .bootstrap .my-lg-0 {
      margin-bottom: 0 !important; }
    .bootstrap .ml-lg-0,
    .bootstrap .mx-lg-0 {
      margin-left: 0 !important; }
    .bootstrap .m-lg-1 {
      margin: 0.25rem !important; }
    .bootstrap .mt-lg-1,
    .bootstrap .my-lg-1 {
      margin-top: 0.25rem !important; }
    .bootstrap .mr-lg-1,
    .bootstrap .mx-lg-1 {
      margin-right: 0.25rem !important; }
    .bootstrap .mb-lg-1,
    .bootstrap .my-lg-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap .ml-lg-1,
    .bootstrap .mx-lg-1 {
      margin-left: 0.25rem !important; }
    .bootstrap .m-lg-2 {
      margin: 0.5rem !important; }
    .bootstrap .mt-lg-2,
    .bootstrap .my-lg-2 {
      margin-top: 0.5rem !important; }
    .bootstrap .mr-lg-2,
    .bootstrap .mx-lg-2 {
      margin-right: 0.5rem !important; }
    .bootstrap .mb-lg-2,
    .bootstrap .my-lg-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap .ml-lg-2,
    .bootstrap .mx-lg-2 {
      margin-left: 0.5rem !important; }
    .bootstrap .m-lg-3 {
      margin: 1rem !important; }
    .bootstrap .mt-lg-3,
    .bootstrap .my-lg-3 {
      margin-top: 1rem !important; }
    .bootstrap .mr-lg-3,
    .bootstrap .mx-lg-3 {
      margin-right: 1rem !important; }
    .bootstrap .mb-lg-3,
    .bootstrap .my-lg-3 {
      margin-bottom: 1rem !important; }
    .bootstrap .ml-lg-3,
    .bootstrap .mx-lg-3 {
      margin-left: 1rem !important; }
    .bootstrap .m-lg-4 {
      margin: 1.5rem !important; }
    .bootstrap .mt-lg-4,
    .bootstrap .my-lg-4 {
      margin-top: 1.5rem !important; }
    .bootstrap .mr-lg-4,
    .bootstrap .mx-lg-4 {
      margin-right: 1.5rem !important; }
    .bootstrap .mb-lg-4,
    .bootstrap .my-lg-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap .ml-lg-4,
    .bootstrap .mx-lg-4 {
      margin-left: 1.5rem !important; }
    .bootstrap .m-lg-5 {
      margin: 3rem !important; }
    .bootstrap .mt-lg-5,
    .bootstrap .my-lg-5 {
      margin-top: 3rem !important; }
    .bootstrap .mr-lg-5,
    .bootstrap .mx-lg-5 {
      margin-right: 3rem !important; }
    .bootstrap .mb-lg-5,
    .bootstrap .my-lg-5 {
      margin-bottom: 3rem !important; }
    .bootstrap .ml-lg-5,
    .bootstrap .mx-lg-5 {
      margin-left: 3rem !important; }
    .bootstrap .p-lg-0 {
      padding: 0 !important; }
    .bootstrap .pt-lg-0,
    .bootstrap .py-lg-0 {
      padding-top: 0 !important; }
    .bootstrap .pr-lg-0,
    .bootstrap .px-lg-0 {
      padding-right: 0 !important; }
    .bootstrap .pb-lg-0,
    .bootstrap .py-lg-0 {
      padding-bottom: 0 !important; }
    .bootstrap .pl-lg-0,
    .bootstrap .px-lg-0 {
      padding-left: 0 !important; }
    .bootstrap .p-lg-1 {
      padding: 0.25rem !important; }
    .bootstrap .pt-lg-1,
    .bootstrap .py-lg-1 {
      padding-top: 0.25rem !important; }
    .bootstrap .pr-lg-1,
    .bootstrap .px-lg-1 {
      padding-right: 0.25rem !important; }
    .bootstrap .pb-lg-1,
    .bootstrap .py-lg-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap .pl-lg-1,
    .bootstrap .px-lg-1 {
      padding-left: 0.25rem !important; }
    .bootstrap .p-lg-2 {
      padding: 0.5rem !important; }
    .bootstrap .pt-lg-2,
    .bootstrap .py-lg-2 {
      padding-top: 0.5rem !important; }
    .bootstrap .pr-lg-2,
    .bootstrap .px-lg-2 {
      padding-right: 0.5rem !important; }
    .bootstrap .pb-lg-2,
    .bootstrap .py-lg-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap .pl-lg-2,
    .bootstrap .px-lg-2 {
      padding-left: 0.5rem !important; }
    .bootstrap .p-lg-3 {
      padding: 1rem !important; }
    .bootstrap .pt-lg-3,
    .bootstrap .py-lg-3 {
      padding-top: 1rem !important; }
    .bootstrap .pr-lg-3,
    .bootstrap .px-lg-3 {
      padding-right: 1rem !important; }
    .bootstrap .pb-lg-3,
    .bootstrap .py-lg-3 {
      padding-bottom: 1rem !important; }
    .bootstrap .pl-lg-3,
    .bootstrap .px-lg-3 {
      padding-left: 1rem !important; }
    .bootstrap .p-lg-4 {
      padding: 1.5rem !important; }
    .bootstrap .pt-lg-4,
    .bootstrap .py-lg-4 {
      padding-top: 1.5rem !important; }
    .bootstrap .pr-lg-4,
    .bootstrap .px-lg-4 {
      padding-right: 1.5rem !important; }
    .bootstrap .pb-lg-4,
    .bootstrap .py-lg-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap .pl-lg-4,
    .bootstrap .px-lg-4 {
      padding-left: 1.5rem !important; }
    .bootstrap .p-lg-5 {
      padding: 3rem !important; }
    .bootstrap .pt-lg-5,
    .bootstrap .py-lg-5 {
      padding-top: 3rem !important; }
    .bootstrap .pr-lg-5,
    .bootstrap .px-lg-5 {
      padding-right: 3rem !important; }
    .bootstrap .pb-lg-5,
    .bootstrap .py-lg-5 {
      padding-bottom: 3rem !important; }
    .bootstrap .pl-lg-5,
    .bootstrap .px-lg-5 {
      padding-left: 3rem !important; }
    .bootstrap .m-lg-auto {
      margin: auto !important; }
    .bootstrap .mt-lg-auto,
    .bootstrap .my-lg-auto {
      margin-top: auto !important; }
    .bootstrap .mr-lg-auto,
    .bootstrap .mx-lg-auto {
      margin-right: auto !important; }
    .bootstrap .mb-lg-auto,
    .bootstrap .my-lg-auto {
      margin-bottom: auto !important; }
    .bootstrap .ml-lg-auto,
    .bootstrap .mx-lg-auto {
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .bootstrap .m-xl-0 {
      margin: 0 !important; }
    .bootstrap .mt-xl-0,
    .bootstrap .my-xl-0 {
      margin-top: 0 !important; }
    .bootstrap .mr-xl-0,
    .bootstrap .mx-xl-0 {
      margin-right: 0 !important; }
    .bootstrap .mb-xl-0,
    .bootstrap .my-xl-0 {
      margin-bottom: 0 !important; }
    .bootstrap .ml-xl-0,
    .bootstrap .mx-xl-0 {
      margin-left: 0 !important; }
    .bootstrap .m-xl-1 {
      margin: 0.25rem !important; }
    .bootstrap .mt-xl-1,
    .bootstrap .my-xl-1 {
      margin-top: 0.25rem !important; }
    .bootstrap .mr-xl-1,
    .bootstrap .mx-xl-1 {
      margin-right: 0.25rem !important; }
    .bootstrap .mb-xl-1,
    .bootstrap .my-xl-1 {
      margin-bottom: 0.25rem !important; }
    .bootstrap .ml-xl-1,
    .bootstrap .mx-xl-1 {
      margin-left: 0.25rem !important; }
    .bootstrap .m-xl-2 {
      margin: 0.5rem !important; }
    .bootstrap .mt-xl-2,
    .bootstrap .my-xl-2 {
      margin-top: 0.5rem !important; }
    .bootstrap .mr-xl-2,
    .bootstrap .mx-xl-2 {
      margin-right: 0.5rem !important; }
    .bootstrap .mb-xl-2,
    .bootstrap .my-xl-2 {
      margin-bottom: 0.5rem !important; }
    .bootstrap .ml-xl-2,
    .bootstrap .mx-xl-2 {
      margin-left: 0.5rem !important; }
    .bootstrap .m-xl-3 {
      margin: 1rem !important; }
    .bootstrap .mt-xl-3,
    .bootstrap .my-xl-3 {
      margin-top: 1rem !important; }
    .bootstrap .mr-xl-3,
    .bootstrap .mx-xl-3 {
      margin-right: 1rem !important; }
    .bootstrap .mb-xl-3,
    .bootstrap .my-xl-3 {
      margin-bottom: 1rem !important; }
    .bootstrap .ml-xl-3,
    .bootstrap .mx-xl-3 {
      margin-left: 1rem !important; }
    .bootstrap .m-xl-4 {
      margin: 1.5rem !important; }
    .bootstrap .mt-xl-4,
    .bootstrap .my-xl-4 {
      margin-top: 1.5rem !important; }
    .bootstrap .mr-xl-4,
    .bootstrap .mx-xl-4 {
      margin-right: 1.5rem !important; }
    .bootstrap .mb-xl-4,
    .bootstrap .my-xl-4 {
      margin-bottom: 1.5rem !important; }
    .bootstrap .ml-xl-4,
    .bootstrap .mx-xl-4 {
      margin-left: 1.5rem !important; }
    .bootstrap .m-xl-5 {
      margin: 3rem !important; }
    .bootstrap .mt-xl-5,
    .bootstrap .my-xl-5 {
      margin-top: 3rem !important; }
    .bootstrap .mr-xl-5,
    .bootstrap .mx-xl-5 {
      margin-right: 3rem !important; }
    .bootstrap .mb-xl-5,
    .bootstrap .my-xl-5 {
      margin-bottom: 3rem !important; }
    .bootstrap .ml-xl-5,
    .bootstrap .mx-xl-5 {
      margin-left: 3rem !important; }
    .bootstrap .p-xl-0 {
      padding: 0 !important; }
    .bootstrap .pt-xl-0,
    .bootstrap .py-xl-0 {
      padding-top: 0 !important; }
    .bootstrap .pr-xl-0,
    .bootstrap .px-xl-0 {
      padding-right: 0 !important; }
    .bootstrap .pb-xl-0,
    .bootstrap .py-xl-0 {
      padding-bottom: 0 !important; }
    .bootstrap .pl-xl-0,
    .bootstrap .px-xl-0 {
      padding-left: 0 !important; }
    .bootstrap .p-xl-1 {
      padding: 0.25rem !important; }
    .bootstrap .pt-xl-1,
    .bootstrap .py-xl-1 {
      padding-top: 0.25rem !important; }
    .bootstrap .pr-xl-1,
    .bootstrap .px-xl-1 {
      padding-right: 0.25rem !important; }
    .bootstrap .pb-xl-1,
    .bootstrap .py-xl-1 {
      padding-bottom: 0.25rem !important; }
    .bootstrap .pl-xl-1,
    .bootstrap .px-xl-1 {
      padding-left: 0.25rem !important; }
    .bootstrap .p-xl-2 {
      padding: 0.5rem !important; }
    .bootstrap .pt-xl-2,
    .bootstrap .py-xl-2 {
      padding-top: 0.5rem !important; }
    .bootstrap .pr-xl-2,
    .bootstrap .px-xl-2 {
      padding-right: 0.5rem !important; }
    .bootstrap .pb-xl-2,
    .bootstrap .py-xl-2 {
      padding-bottom: 0.5rem !important; }
    .bootstrap .pl-xl-2,
    .bootstrap .px-xl-2 {
      padding-left: 0.5rem !important; }
    .bootstrap .p-xl-3 {
      padding: 1rem !important; }
    .bootstrap .pt-xl-3,
    .bootstrap .py-xl-3 {
      padding-top: 1rem !important; }
    .bootstrap .pr-xl-3,
    .bootstrap .px-xl-3 {
      padding-right: 1rem !important; }
    .bootstrap .pb-xl-3,
    .bootstrap .py-xl-3 {
      padding-bottom: 1rem !important; }
    .bootstrap .pl-xl-3,
    .bootstrap .px-xl-3 {
      padding-left: 1rem !important; }
    .bootstrap .p-xl-4 {
      padding: 1.5rem !important; }
    .bootstrap .pt-xl-4,
    .bootstrap .py-xl-4 {
      padding-top: 1.5rem !important; }
    .bootstrap .pr-xl-4,
    .bootstrap .px-xl-4 {
      padding-right: 1.5rem !important; }
    .bootstrap .pb-xl-4,
    .bootstrap .py-xl-4 {
      padding-bottom: 1.5rem !important; }
    .bootstrap .pl-xl-4,
    .bootstrap .px-xl-4 {
      padding-left: 1.5rem !important; }
    .bootstrap .p-xl-5 {
      padding: 3rem !important; }
    .bootstrap .pt-xl-5,
    .bootstrap .py-xl-5 {
      padding-top: 3rem !important; }
    .bootstrap .pr-xl-5,
    .bootstrap .px-xl-5 {
      padding-right: 3rem !important; }
    .bootstrap .pb-xl-5,
    .bootstrap .py-xl-5 {
      padding-bottom: 3rem !important; }
    .bootstrap .pl-xl-5,
    .bootstrap .px-xl-5 {
      padding-left: 3rem !important; }
    .bootstrap .m-xl-auto {
      margin: auto !important; }
    .bootstrap .mt-xl-auto,
    .bootstrap .my-xl-auto {
      margin-top: auto !important; }
    .bootstrap .mr-xl-auto,
    .bootstrap .mx-xl-auto {
      margin-right: auto !important; }
    .bootstrap .mb-xl-auto,
    .bootstrap .my-xl-auto {
      margin-bottom: auto !important; }
    .bootstrap .ml-xl-auto,
    .bootstrap .mx-xl-auto {
      margin-left: auto !important; } }
  .bootstrap .text-justify {
    text-align: justify !important; }
  .bootstrap .text-nowrap {
    white-space: nowrap !important; }
  .bootstrap .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .bootstrap .text-left {
    text-align: left !important; }
  .bootstrap .text-right {
    text-align: right !important; }
  .bootstrap .text-center {
    text-align: center !important; }
  @media (min-width: 576px) {
    .bootstrap .text-sm-left {
      text-align: left !important; }
    .bootstrap .text-sm-right {
      text-align: right !important; }
    .bootstrap .text-sm-center {
      text-align: center !important; } }
  @media (min-width: 768px) {
    .bootstrap .text-md-left {
      text-align: left !important; }
    .bootstrap .text-md-right {
      text-align: right !important; }
    .bootstrap .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    .bootstrap .text-lg-left {
      text-align: left !important; }
    .bootstrap .text-lg-right {
      text-align: right !important; }
    .bootstrap .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    .bootstrap .text-xl-left {
      text-align: left !important; }
    .bootstrap .text-xl-right {
      text-align: right !important; }
    .bootstrap .text-xl-center {
      text-align: center !important; } }
  .bootstrap .text-lowercase {
    text-transform: lowercase !important; }
  .bootstrap .text-uppercase {
    text-transform: uppercase !important; }
  .bootstrap .text-capitalize {
    text-transform: capitalize !important; }
  .bootstrap .font-weight-light {
    font-weight: 300 !important; }
  .bootstrap .font-weight-normal {
    font-weight: 400 !important; }
  .bootstrap .font-weight-bold {
    font-weight: 700 !important; }
  .bootstrap .font-italic {
    font-style: italic !important; }
  .bootstrap .text-white {
    color: #fff !important; }
  .bootstrap .text-primary {
    color: #007bff !important; }
  .bootstrap a.text-primary:hover, .bootstrap a.text-primary:focus {
    color: #0062cc !important; }
  .bootstrap .text-secondary {
    color: #6c757d !important; }
  .bootstrap a.text-secondary:hover, .bootstrap a.text-secondary:focus {
    color: #545b62 !important; }
  .bootstrap .text-success {
    color: #28a745 !important; }
  .bootstrap a.text-success:hover, .bootstrap a.text-success:focus {
    color: #1e7e34 !important; }
  .bootstrap .text-info {
    color: #17a2b8 !important; }
  .bootstrap a.text-info:hover, .bootstrap a.text-info:focus {
    color: #117a8b !important; }
  .bootstrap .text-warning {
    color: #ffc107 !important; }
  .bootstrap a.text-warning:hover, .bootstrap a.text-warning:focus {
    color: #d39e00 !important; }
  .bootstrap .text-danger {
    color: #dc3545 !important; }
  .bootstrap a.text-danger:hover, .bootstrap a.text-danger:focus {
    color: #bd2130 !important; }
  .bootstrap .text-light {
    color: #f8f9fa !important; }
  .bootstrap a.text-light:hover, .bootstrap a.text-light:focus {
    color: #dae0e5 !important; }
  .bootstrap .text-dark {
    color: #343a40 !important; }
  .bootstrap a.text-dark:hover, .bootstrap a.text-dark:focus {
    color: #1d2124 !important; }
  .bootstrap .text-muted {
    color: #6c757d !important; }
  .bootstrap .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }
  .bootstrap .visible {
    visibility: visible !important; }
  .bootstrap .invisible {
    visibility: hidden !important; }
  @media print {
    .bootstrap *,
    .bootstrap *::before,
    .bootstrap *::after {
      text-shadow: none !important;
      box-shadow: none !important; }
    .bootstrap a:not(.btn) {
      text-decoration: underline; }
    .bootstrap abbr[title]::after {
      content: " (" attr(title) ")"; }
    .bootstrap pre {
      white-space: pre-wrap !important; }
    .bootstrap pre,
    .bootstrap blockquote {
      border: 1px solid #999;
      page-break-inside: avoid; }
    .bootstrap thead {
      display: table-header-group; }
    .bootstrap tr,
    .bootstrap img {
      page-break-inside: avoid; }
    .bootstrap p,
    .bootstrap h2,
    .bootstrap h3 {
      orphans: 3;
      widows: 3; }
    .bootstrap h2,
    .bootstrap h3 {
      page-break-after: avoid; }
    @page {
      .bootstrap {
        size: a3; } }
    .bootstrap body {
      min-width: 992px !important; }
    .bootstrap .container {
      min-width: 992px !important; }
    .bootstrap .navbar {
      display: none; }
    .bootstrap .badge {
      border: 1px solid #000; }
    .bootstrap .table {
      border-collapse: collapse !important; }
    .bootstrap .table td,
    .bootstrap .table th {
      background-color: #fff !important; }
    .bootstrap .table-bordered th,
    .bootstrap .table-bordered td {
      border: 1px solid #ddd !important; } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button, select, html, textarea, input {
  font-family: inherit; }

/**
 * Set up a decent box model on the root element
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit; }

/**
 * Basic styles for page size
 */
html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  min-width: 600px; }

/**
 * Basic styles for body
 */
body {
  color: #555a5f;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  background-color: #ececec;
  line-height: normal; }

main {
  min-height: calc(100vh - (307px + 61px)); }

/**
 * Basic styles for links
 */
a, .a {
  color: #e74c3c;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:active, a:focus, .a:hover, .a:active, .a:focus {
    color: #e74c3c;
    text-decoration: underline; }

@keyframes collapse {
  0% {
    max-height: 999px; }
  100% {
    max-height: 0px; } }

@keyframes uncollapse {
  0% {
    max-height: 0px; }
  100% {
    max-height: 999px; } }

@keyframes slide {
  0% {
    max-width: 999px; }
  100% {
    max-width: 0px; } }

@keyframes unslide {
  0% {
    max-width: 0px; }
  100% {
    max-width: 999px; } }

@-webkit-keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

.breadcumb-container.breadcumb-order {
  height: 100%;
  background-color: #ed7a6e;
  padding: 35px 0 60px 0; }
  .breadcumb-container.breadcumb-order ol {
    padding: 0;
    border-top: 8px solid #ededed;
    list-style: none; }
  .breadcumb-container.breadcumb-order .breadcumb-step {
    float: left;
    width: 25%;
    position: relative; }
    .breadcumb-container.breadcumb-order .breadcumb-step:first-of-type:before, .breadcumb-container.breadcumb-order .breadcumb-step:last-of-type:before {
      content: "";
      width: 50%;
      height: 10px;
      background: #ed7a6e;
      position: absolute;
      top: -9px;
      left: 0; }
    .breadcumb-container.breadcumb-order .breadcumb-step:last-of-type:before {
      left: 50%; }
    .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-title {
      color: white;
      display: block;
      padding: 30px 0 0;
      font-size: 16px;
      text-align: center; }
      .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-title a {
        color: white; }
    .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-marker {
      width: 30px;
      height: 30px;
      margin-left: -15px;
      background: white;
      border: 2px solid white;
      border-radius: 50%;
      box-shadow: 0 0 0 5px white;
      text-align: center;
      line-height: 30px -10;
      color: #e74c3c;
      font-size: 16px;
      font-style: normal;
      line-height: 25.5px;
      font-weight: bold;
      position: absolute;
      top: -19px;
      left: 50%; }
      .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-marker a {
        color: #e74c3c; }
      .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-marker.done {
        cursor: pointer;
        background: #e74c3c;
        color: white; }
        .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-marker.done a {
          color: white; }
      .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-marker.active {
        background: #ed7a6e;
        color: white; }
        .breadcumb-container.breadcumb-order .breadcumb-step .breadcumb-step-marker.active a {
          color: white; }

.btn {
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none; }
  .btn {
    color: #555a5f;
    background-color: white;
    border: #c1c1c1 1px solid inset;
    border-radius: 2.5px; }
  .btna, .btna:hover, .btna:active, .btna:focus, .btn a, .btn a:hover, .btn a:active, .btn a:focus {
    color: #555a5f; }
  .btn:hover {
    text-decoration: none;
    border-color: #aaaaaa; }
  .btn:active {
    background-color: #f0f0f0; }
  .btn::-moz-focus-inner {
    border: 0; }
  .btn.btn-whiteframe {
    margin-bottom: 15px; }
  .btn.btn-mrg {
    margin-top: 10px;
    margin-bottom: 10px; }
  .btn.btn-mrg-top {
    margin-top: 10px; }
  .btn.btn-mrg-bottom {
    margin-bottom: 10px; }
  .btn.btn-sm {
    padding: 4px 12px; }
  .btn.btn-md {
    padding: 7px 21px; }
  .btn.btn-lg {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px; }
  .btn.btn-block {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%; }
  .btn.btn-transparent {
    color: white;
    background-color: rgba(0, 0, 0, 0.1);
    border: white 1px solid;
    border-radius: 2.5px; }
    .btn.btn-transparenta, .btn.btn-transparenta:hover, .btn.btn-transparenta:active, .btn.btn-transparenta:focus {
      color: white; }
    .btn.btn-transparent:hover {
      background-color: rgba(0, 0, 0, 0.3); }
    .btn.btn-transparent:active {
      background-color: rgba(0, 0, 0, 0.5); }
  .btn.btn-grey {
    color: white;
    background-color: rgba(0, 0, 0, 0.25);
    border: rgba(0, 0, 0, 0.01) 1px solid;
    border-radius: 2.5px; }
    .btn.btn-greya, .btn.btn-greya:hover, .btn.btn-greya:active, .btn.btn-greya:focus, .btn.btn-grey a, .btn.btn-grey a:hover, .btn.btn-grey a:active, .btn.btn-grey a:focus {
      text-decoration: none;
      color: white; }
    .btn.btn-grey:hover {
      background-color: rgba(0, 0, 0, 0.35); }
    .btn.btn-grey:active {
      background-color: rgba(0, 0, 0, 0.4); }
  .btn.btn-outline {
    color: #e74c3c;
    background-color: rgba(0, 0, 0, 0.01);
    border: #ed7a6e 1px solid;
    font-weight: bold;
    border-radius: 2.5px; }
    .btn.btn-outlinea, .btn.btn-outlinea:hover, .btn.btn-outlinea:active, .btn.btn-outlinea:focus, .btn.btn-outline a, .btn.btn-outline a:hover, .btn.btn-outline a:active, .btn.btn-outline a:focus {
      text-decoration: none;
      color: white; }
    .btn.btn-outline:hover {
      background-color: rgba(0, 0, 0, 0.1); }
    .btn.btn-outline:active {
      background-color: rgba(0, 0, 0, 0.01); }
    .btn.btn-outline.disabled {
      color: #81888a;
      border: #81888a 1px solid; }
  .btn.btn-green {
    color: white;
    background-color: #2ecc71;
    border: #00b16a 1px solid;
    border-radius: 2.5px; }
    .btn.btn-greena, .btn.btn-greena:hover, .btn.btn-greena:active, .btn.btn-greena:focus, .btn.btn-green a, .btn.btn-green a:hover, .btn.btn-green a:active, .btn.btn-green a:focus {
      text-decoration: none;
      color: white; }
    .btn.btn-green:hover {
      background-color: #00b16a; }
    .btn.btn-green:active {
      background-color: #2ecc71; }
  .btn.btn-primary {
    color: white;
    background-color: #e74c3c;
    border: #ff3a24 1px solid;
    border-radius: 2.5px; }
    .btn.btn-primarya, .btn.btn-primarya:hover, .btn.btn-primarya:active, .btn.btn-primarya:focus, .btn.btn-primary a, .btn.btn-primary a:hover, .btn.btn-primary a:active, .btn.btn-primary a:focus {
      text-decoration: none;
      color: white; }
    .btn.btn-primary:hover {
      background-color: #ff3a24; }
    .btn.btn-primary:active {
      background-color: #e74c3c; }
  .btn.btn-toactive {
    color: white;
    background-color: #ed7a6e;
    border: #ff3a24 1px solid;
    border-radius: 2.5px; }
    .btn.btn-toactivea, .btn.btn-toactivea:hover, .btn.btn-toactivea:active, .btn.btn-toactivea:focus, .btn.btn-toactive a, .btn.btn-toactive a:hover, .btn.btn-toactive a:active, .btn.btn-toactive a:focus {
      text-decoration: none;
      color: white;
      background-color: #e74c3c; }
    .btn.btn-toactive:hover {
      background-color: #ff3a24; }
    .btn.btn-toactive:active {
      background-color: #e74c3c; }
  .btn.btn-active {
    color: white;
    background-color: #e74c3c;
    border: #ff3a24 1px solid;
    border-radius: 2.5px; }
    .btn.btn-activea, .btn.btn-activea:hover, .btn.btn-activea:active, .btn.btn-activea:focus, .btn.btn-active a, .btn.btn-active a:hover, .btn.btn-active a:active, .btn.btn-active a:focus {
      text-decoration: none;
      color: white; }
    .btn.btn-active:hover {
      background-color: #ff3a24; }
    .btn.btn-active:active {
      background-color: #e74c3c; }

.intro {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 37.5px; }
  .intro h3 {
    font-size: 34px !important;
    font-weight: bold;
    margin-bottom: 7px; }
  .intro p {
    font-size: 16px;
    margin-bottom: 15px; }

.photo-card {
  text-align: center;
  background-color: whitesmoke;
  background-size: cover;
  background-position: center center;
  margin-bottom: 17px;
  height: 340px; }
  .photo-card a {
    text-shadow: 0px 0px 5px black;
    display: block;
    height: 100%;
    width: 100%;
    color: white; }
    .photo-card a:hover {
      text-decoration: none;
      color: #ededed;
      text-shadow: 0px 0px 7.5px black; }
    .photo-card a h4 {
      font-size: 34px;
      font-weight: bold;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }

hr.divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(215, 216, 217, 0), #d7d8d9, rgba(215, 216, 217, 0)); }

@font-face {
  font-family: 'homemade-apple';
  src: url("/assets/fonts/homemadeapple-webfont.eot");
  src: url("/assets/fonts/homemadeapple-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/homemadeapple-webfont.woff2") format("woff2"), url("/assets/fonts/homemadeapple-webfont.woff") format("woff"), url("/assets/fonts/homemadeapple-webfont.svg#homemade-apple") format("svg");
  font-weight: normal;
  font-style: normal; }

.horizontal-trip-steps {
  cursor: grab;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  font-size: 14px;
  line-height: 14px;
  text-align: center; }
  .horizontal-trip-steps ul {
    white-space: nowrap;
    display: inline-block;
    margin: 0 auto; }
  .horizontal-trip-steps li {
    position: relative;
    display: inline-block; }
  .horizontal-trip-steps .horizontal-trip-steps-timeline.layover {
    min-width: 125px; }
    .horizontal-trip-steps .horizontal-trip-steps-timeline.layover:before {
      content: ' ';
      position: absolute;
      height: 4px;
      width: 225px;
      border-top: #d7d8d9 4px dotted;
      top: 46px;
      left: -50px; }
  .horizontal-trip-steps .horizontal-trip-steps-timeline:not(.layover) {
    min-width: 300px; }
    .horizontal-trip-steps .horizontal-trip-steps-timeline:not(.layover):before {
      content: ' ';
      position: absolute;
      height: 4px;
      width: 210px;
      border-top: #e74c3c 4px solid;
      top: 46px;
      left: 50px; }
    .horizontal-trip-steps .horizontal-trip-steps-timeline:not(.layover).warehouse:before {
      border-top-color: #f1b2ab; }
  .horizontal-trip-steps .horizontal-trip-steps-timeline {
    position: relative;
    display: inline-block; }
    .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-dot {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: ' ';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: white;
      border: 6px solid #e74c3c;
      top: 38px;
      z-index: 100; }
      .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-dot.warehouse {
        border-color: #f1b2ab !important; }
    .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-from, .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-to, .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-layover {
      position: relative;
      display: inline-block;
      width: 100px;
      text-align: center; }
      .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-from .horizontal-trip-steps-top, .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-to .horizontal-trip-steps-top, .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-layover .horizontal-trip-steps-top {
        height: 32px;
        margin-top: 4px;
        margin-bottom: 24px; }
      .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-from .horizontal-trip-steps-bottom, .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-to .horizontal-trip-steps-bottom, .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-layover .horizontal-trip-steps-bottom {
        height: 28px;
        margin-top: 24px;
        margin-bottom: 4px;
        font-size: 12px; }
    .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-info {
      position: relative;
      display: inline-block;
      width: 100px;
      text-align: center;
      font-size: 12px; }
      .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-info .horizontal-trip-steps-top {
        height: 32px;
        margin-top: 4px;
        margin-bottom: 24px;
        padding-top: 20px; }
      .horizontal-trip-steps .horizontal-trip-steps-timeline .horizontal-trip-steps-info .horizontal-trip-steps-bottom {
        height: 28px;
        margin-top: 24px;
        margin-bottom: 4px; }
    .horizontal-trip-steps .horizontal-trip-steps-timeline.layover .horizontal-trip-steps-info {
      width: 125px; }

@font-face {
  font-family: 'br-icon';
  src: url("/assets/fonts/br-icon.eot");
  src: url("/assets/fonts/br-icon.eot#iefix") format("embedded-opentype"), url("/assets/fonts/br-icon.woff2") format("woff2"), url("/assets/fonts/br-icon.woff") format("woff"), url("/assets/fonts/br-icon.ttf") format("truetype"), url("/assets/fonts/br-icon.svg#br-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'br-icons-2';
  src: url("/assets/fonts/br-icons-2.eot");
  src: url("/assets/fonts/br-icons-2.eot#iefix") format("embedded-opentype"), url("/assets/fonts/br-icons-2.woff") format("woff"), url("/assets/fonts/br-icons-2.ttf") format("truetype"), url("/assets/fonts/br-icons-2.svg#br-icons-2") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'br-icon';
    src: url("/assets/fonts/br-iconsvg#br-icon") format("svg"); } }

.icon {
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'br-icon';
    src: url('../fonts/br-icon.svg?51431517#br-icon') format('svg');
  }
}
*/
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */ }
  .icon:before {
    font-family: "br-icon";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes */
    font-variant: normal;
    text-transform: none;
    /* fix buttons height */
    /* line-height: 1em; */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

@font-face {
  .icon {
    font-family: 'br-icon';
    src: url("../fonts/br-icon.eot?51431517");
    src: url("../fonts/br-icon.eot?51431517#iefix") format("embedded-opentype"), url("../fonts/br-icon.woff?51431517") format("woff"), url("../fonts/br-icon.ttf?51431517") format("truetype"), url("../fonts/br-icon.svg?51431517#br-icon") format("svg");
    font-weight: normal;
    font-style: normal; } }
  .icon [class^="icon-"]:before, .icon [class*=" icon-"]:before {
    font-family: "br-icon";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
  .icon.icon-heart:before {
    content: '\e800'; }
  .icon.icon-basket:before {
    content: '\e801'; }
  .icon.icon-coffee-machine:before {
    content: '\e802'; }
  .icon.icon-connection:before {
    content: '\e803'; }
  .icon.icon-dvd:before {
    content: '\e804'; }
  .icon.icon-fridge:before {
    content: '\e805'; }
  .icon.icon-idea:before {
    content: '\e806'; }
  .icon.icon-karaoke:before {
    content: '\e807'; }
  .icon.icon-leather:before {
    content: '\e808'; }
  .icon.icon-clock:before {
    content: '\e809'; }
  .icon.icon-map:before {
    content: '\e80a'; }
  .icon.icon-location:before {
    content: '\e80b'; }
  .icon.icon-share:before {
    content: '\e80c'; }
  .icon.icon-star:before {
    content: '\e80d'; }
  .icon.icon-luggage:before {
    content: '\e80e'; }
  .icon.icon-monitor:before {
    content: '\e80f'; }
  .icon.icon-music-player:before {
    content: '\e810'; }
  .icon.icon-oven:before {
    content: '\e811'; }
  .icon.icon-plug:before {
    content: '\e812'; }
  .icon.icon-radio:before {
    content: '\e813'; }
  .icon.icon-arrow-up:before {
    content: '\e814'; }
  .icon.icon-arrow-right:before {
    content: '\e815'; }
  .icon.icon-arrow-down:before {
    content: '\e816'; }
  .icon.icon-arrow-left:before {
    content: '\e817'; }
  .icon.icon-open-up:before {
    content: '\e818'; }
  .icon.icon-open-right:before {
    content: '\e819'; }
  .icon.icon-open-down:before {
    content: '\e81a'; }
  .icon.icon-open-left:before {
    content: '\e81b'; }
  .icon.icon-star-half:before {
    content: '\e81c'; }
  .icon.icon-menu:before {
    content: '\e81d'; }
  .icon.icon-thick:before {
    content: '\e81e'; }
  .icon.icon-television:before {
    content: '\e81f'; }
  .icon.icon-toilet:before {
    content: '\e820'; }
  .icon.icon-usb:before {
    content: '\e821'; }
  .icon.icon-wheelchair:before {
    content: '\e822'; }
  .icon.icon-cog:before {
    content: '\e823'; }
  .icon.icon-window:before {
    content: '\e824'; }
  .icon.icon-comment:before {
    content: '\e825'; }
  .icon.icon-driver:before {
    content: '\e826'; }
  .icon.icon-window-tinted:before {
    content: '\e827'; }
  .icon.icon-phone:before {
    content: '\e828'; }
  .icon.icon-info:before {
    content: '\e829'; }
  .icon.icon-emo-unhappy:before {
    content: '\e82a'; }
  .icon.icon-air-conditioner:before {
    content: '\e82b'; }
  .icon.icon-emo-happy:before {
    content: '\e82c'; }
  .icon.icon-warn:before {
    content: '\e82d'; }
  .icon.icon-lock-open:before {
    content: '\e82e'; }
  .icon.icon-cookie:before {
    content: '\e82f'; }
  .icon.icon-add:before {
    content: '\e830'; }
  .icon.icon-cancel:before {
    content: '\e831'; }
  .icon.icon-login:before {
    content: '\e832'; }
  .icon.icon-logout:before {
    content: '\e833'; }
  .icon.icon-search:before {
    content: '\e834'; }
  .icon.icon-peoples:before {
    content: '\e835'; }
  .icon.icon-user-male:before {
    content: '\e836'; }
  .icon.icon-leaf:before {
    content: '\e837'; }
  .icon.icon-chat:before {
    content: '\e838'; }
  .icon.icon-spin:before {
    content: '\e839'; }
  .icon.icon-flash:before {
    content: '\e83a'; }
  .icon.icon-download:before {
    content: '\e83b'; }
  .icon.icon-pencil-1:before {
    content: '\e83c'; }
  .icon.icon-credit-card:before {
    content: '\e83d'; }
  .icon.icon-bus:before {
    content: '\e83e'; }
  .icon.icon-child:before {
    content: '\e83f'; }
  .icon.icon-filter:before {
    content: '\e840'; }
  .icon.icon-calendar:before {
    content: '\e841'; }
  .icon.icon-pencil:before {
    content: '\e842'; }
  .icon.icon-chart-bar:before {
    content: '\e843'; }
  .icon.icon-eye-off:before {
    content: '\e844'; }
  .icon.icon-eye:before {
    content: '\e845'; }
  .icon.icon-updown-circle:before {
    content: '\e846'; }
  .icon.icon-export:before {
    content: '\e847'; }
  .icon.icon-arrows-cw:before {
    content: '\e848'; }
  .icon.icon-print:before {
    content: '\e849'; }
  .icon.icon-bold:before {
    content: '\e84a'; }
  .icon.icon-italic:before {
    content: '\e84b'; }
  .icon.icon-ccw:before {
    content: '\e84c'; }
  .icon.icon-cw:before {
    content: '\e84d'; }
  .icon.icon-align-left:before {
    content: '\e84e'; }
  .icon.icon-align-right:before {
    content: '\e84f'; }
  .icon.icon-align-center:before {
    content: '\e850'; }
  .icon.icon-cancel-circled:before {
    content: '\e851'; }
  .icon.icon-link:before {
    content: '\e852'; }
  .icon.icon-picture:before {
    content: '\e853'; }
  .icon.icon-block:before {
    content: '\e854'; }
  .icon.icon-align-justify:before {
    content: '\e855'; }
  .icon.icon-filter-1:before {
    content: '\f0b0'; }
  .icon.icon-docs:before {
    content: '\f0c5'; }
  .icon.icon-floppy:before {
    content: '\e856'; }
  .icon.icon-list-bullet:before {
    content: '\f0ca'; }
  .icon.icon-list-numbered:before {
    content: '\f0cb'; }
  .icon.icon-underline:before {
    content: '\f0cd'; }
  .icon.icon-mail-alt:before {
    content: '\f0e0'; }
  .icon.icon-chat-empty:before {
    content: '\f0e6'; }
  .icon.icon-paste:before {
    content: '\f0ea'; }
  .icon.icon-quote-left:before {
    content: '\f10d'; }
  .icon.icon-quote-right:before {
    content: '\f10e'; }
  .icon.icon-code:before {
    content: '\f121'; }
  .icon.icon-shield:before {
    content: '\f132'; }
  .icon.icon-tree:before {
    content: '\f1bb'; }
  .icon.icon-sliders:before {
    content: '\f1de'; }
  .icon.icon-whatsapp:before {
    content: '\f232'; }
  .icon.icon-twitter:before {
    content: '\f309'; }
  .icon.icon-facebook-squared:before {
    content: '\f30e'; }
  .icon.icon-globe:before {
    content: '\e857'; }
  .icon.icon-users:before {
    content: '\e858'; }
  .icon.icon-ok-circle:before {
    content: '\e859'; }
  .icon.icon-check:before {
    content: '\f30f'; }

.icon-2:before {
  font-family: "br-icons-2";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;
  /* fix buttons height */
  /* line-height: 1em; */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  vertical-align: middle; }

@font-face {
  .icon-2 {
    font-family: 'br-icons-2';
    src: url("../fonts/br-icons-2.eot?51431517");
    src: url("../fonts/br-icons-2.eot?51431517#iefix") format("embedded-opentype"), url("../fonts/br-icons-2.woff2?51431517") format("woff2"), url("../fonts/br-icons-2.woff?51431517") format("woff"), url("../fonts/br-icons-2.ttf?51431517") format("truetype"), url("../fonts/br-icons-2.svg?51431517#br-icons-2") format("svg");
    font-weight: normal;
    font-style: normal; } }

.icon-2 [class*='icon-']:before {
  display: inline-block;
  font-family: 'br-icons-2';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-2.icon-air-conditioner:before {
  content: '\0041'; }

.icon-2.icon-alarm:before {
  content: '\0042'; }

.icon-2.icon-coffee-machine:before {
  content: '\0043'; }

.icon-2.icon-fridge:before {
  content: '\0044'; }

.icon-2.icon-leather:before {
  content: '\0045'; }

.icon-2.icon-microphone:before {
  content: '\0046'; }

.icon-2.icon-obscured-glasses:before {
  content: '\0047'; }

.icon-2.icon-panoramic:before {
  content: '\0048'; }

.icon-2.icon-plugs:before {
  content: '\0049'; }

.icon-2.icon-radio:before {
  content: '\004a'; }

.icon-2.icon-reading-light:before {
  content: '\004b'; }

.icon-2.icon-services:before {
  content: '\004c'; }

.icon-2.icon-tv:before {
  content: '\004d'; }

.icon-2.icon-usb:before {
  content: '\004e'; }

.icon-2.icon-wc:before {
  content: '\004f'; }

.icon-2.icon-wheelchair:before {
  content: '\0050'; }

.icon-2.icon-wifi:before {
  content: '\0051'; }

.icon-2.icon-check:before {
  content: '\0052'; }

.icon-2.icon-edit:before {
  content: '\0053'; }

.icon-2.icon-lock:before {
  content: '\0054'; }

.icon-2.icon-share:before {
  content: '\0055'; }

.icon-2.icon-star-empty:before {
  content: '\0056'; }

.icon-2.icon-star-full:before {
  content: '\0057'; }

.icon-2.icon-zoom-in:before {
  content: '\0058'; }

.icon-2.icon-zoom-out:before {
  content: '\0059'; }

.icon-2.icon-blue-clock:before {
  content: '\005a'; }

.icon-2.icon-luggages:before {
  content: '\0061'; }

.icon-2.icon-kitchen:before {
  content: '\0062'; }

.icon-2.icon-services:before {
  content: '\0063'; }

.icon-2.icon-refresh:before {
  content: '\0064'; }

.icon-2.icon-view:before {
  content: '\0065'; }

.icon-2.icon-copy:before {
  content: '\0066'; }

.icon-2.icon-garbage:before {
  content: '\0067'; }

.icon-2.icon-moon:before {
  content: '\0068'; }

.icon-2.icon-alarm_active:before {
  content: '\0069'; }

/*
   Animation example, for spinners
*/
.animate-spin {
  animation: spin 0.5s infinite linear;
  display: inline-block; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

form .warn {
  background-color: #f5e3c7;
  border: 1px solid #f0c495;
  border-radius: 2.5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px; }
  form .warn.ng-enter {
    transition: 0.5s;
    opacity: 0;
    max-height: 0px; }
  form .warn.ng-enter-active {
    opacity: 1;
    max-height: 200px; }
  form .warn.ng-leave {
    transition: 0.5s;
    opacity: 1;
    max-height: 200px; }
  form .warn.ng-leave-active {
    opacity: 0;
    max-height: 0px; }

label, .label-tag {
  position: relative; }
  label .uppercase, .label-tag .uppercase {
    text-transform: uppercase; }
  label .top, .label-tag .top {
    margin: 10px 0 0 0;
    padding: 0 0 5px 0;
    text-align: left;
    font-size: 14px; }
    label .top .transparent, .label-tag .top .transparent {
      color: transparent;
      user-select: none; }
    label .top .label, .label-tag .top .label {
      display: inline-block; }
    label .top .info, .label-tag .top .info {
      display: inline-block;
      color: #ed7a6e; }
      label .top .info:hover, .label-tag .top .info:hover {
        cursor: pointer;
        color: #e74c3c; }
  label .bottom, .label-tag .bottom {
    font-size: 12px;
    text-align: left;
    padding: 2.5px 0 5px 0; }
  label .input, .label-tag .input {
    height: 34px; }
  label input[disabled], .label-tag input[disabled] {
    background-color: #ededed;
    color: #81888a; }
    label input[disabled]:hover, label input[disabled]:active, label input[disabled]:focus, .label-tag input[disabled]:hover, .label-tag input[disabled]:active, .label-tag input[disabled]:focus {
      cursor: not-allowed;
      border-color: #d7d8d9 !important; }
  label input[type=tel]::-webkit-inner-spin-button,
  label input[type=tel]::-webkit-outer-spin-button, .label-tag input[type=tel]::-webkit-inner-spin-button,
  .label-tag input[type=tel]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  label .password-toggle, .label-tag .password-toggle {
    margin-top: 1px;
    margin-left: -36px;
    position: absolute;
    height: 35px;
    width: 35px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    line-height: 34px; }
  label .loading-filed, .label-tag .loading-filed {
    margin-top: 0 !important;
    position: absolute;
    height: 35px;
    width: 35px;
    top: 32px;
    right: 0;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    line-height: 34px; }
  label input, label select, label textarea, label .input, .label-tag input, .label-tag select, .label-tag textarea, .label-tag .input {
    box-shadow: none !important;
    width: 100%;
    padding: 10px;
    border-radius: 2.5px;
    border: 1px solid;
    border-color: #d7d8d9 !important;
    background-color: white;
    transition: all 0.30s ease-in-out;
    outline: none !important; }
    label input:focus, label input:hover, label select:focus, label select:hover, label textarea:focus, label textarea:hover, label .input:focus, label .input:hover, .label-tag input:focus, .label-tag input:hover, .label-tag select:focus, .label-tag select:hover, .label-tag textarea:focus, .label-tag textarea:hover, .label-tag .input:focus, .label-tag .input:hover {
      outline: none !important; }
    label input:hover, label select:hover, label textarea:hover, label .input:hover, .label-tag input:hover, .label-tag select:hover, .label-tag textarea:hover, .label-tag .input:hover {
      border-color: rgba(231, 76, 60, 0.5) !important; }
    label input:focus, label select:focus, label textarea:focus, label .input:focus, .label-tag input:focus, .label-tag select:focus, .label-tag textarea:focus, .label-tag .input:focus {
      border-color: rgba(231, 76, 60, 0.6) !important;
      box-shadow: 0 0 5px 0px rgba(231, 76, 60, 0.5) !important; }
  label textarea, .label-tag textarea {
    min-height: 100px;
    max-width: 100%; }
  label select, .label-tag select {
    padding: 10px 10px;
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%), linear-gradient(to right, white, white);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat; }
    label select:invalid, .label-tag select:invalid {
      color: #81888a; }
    label select option, .label-tag select option {
      color: #555a5f; }
      label select option:checked, label select option:hover, .label-tag select option:checked, .label-tag select option:hover {
        color: white;
        box-shadow: 0 0 10px 100px #e74c3c inset; }
      label select option:checked, label select option:hover, .label-tag select option:checked, .label-tag select option:hover {
        color: white;
        background: #e74c3c; }

[date-picker] {
  background-color: white;
  border: #d7d8d9 1px solid;
  color: #555a5f;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35); }
  [date-picker] table td span {
    color: #555a5f !important;
    padding: 1px  !important;
    border-radius: 2.5px !important; }
    [date-picker] table td span.muted {
      color: #81888a !important; }
    [date-picker] table td span.disabled {
      background: none !important;
      color: rgba(129, 136, 138, 0.4) !important;
      cursor: default  !important; }
      [date-picker] table td span.disabled:hover {
        padding: 0  !important;
        background-color: whitesmoke !important;
        color: #81888a !important;
        border: #d7d8d9 1px solid !important;
        cursor: default  !important; }
      [date-picker] table td span.disabled:active, [date-picker] table td span.disabled.active, [date-picker] table td span.disabled:focus {
        background-color: none !important;
        pointer-events: none  !important; }
    [date-picker] table td span.now {
      padding: 0  !important;
      background: #ededed !important;
      border: #d7d8d9 1px solid !important;
      text-shadow: none !important; }
    [date-picker] table td span.active:not(.disabled), [date-picker] table td span:active:not(.disabled) {
      padding: 0  !important;
      background: #e74c3c !important;
      color: white !important;
      border: #ff3a24 1px solid !important; }
    [date-picker] table td span:hover:not(.disabled) {
      padding: 0  !important;
      background-color: #f1b2ab !important;
      color: white !important;
      border: #ed7a6e 1px solid !important; }
  [date-picker] table th:not(.weekday) span {
    color: #555a5f !important;
    border-radius: 2.5px !important;
    padding: 1px; }
    [date-picker] table th:not(.weekday) span:hover {
      padding: 0  !important;
      background-color: #f1b2ab !important;
      color: white !important;
      border: #ed7a6e 1px solid !important; }
    [date-picker] table th:not(.weekday) span:active {
      padding: 0  !important;
      background: #e74c3c !important;
      color: white !important;
      border: #ff3a24 1px solid !important; }
  [date-picker] .year, [date-picker] .month, [date-picker] .minutes {
    width: 222px; }
    [date-picker] .year td, [date-picker] .month td, [date-picker] .minutes td {
      width: 100%; }
      [date-picker] .year td span, [date-picker] .month td span, [date-picker] .minutes td span {
        height: 54px;
        line-height: 54px; }
  [date-picker] .date {
    width: 222px; }
    [date-picker] .date td span {
      height: 30px;
      line-height: 30px; }

.jumbo-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center top;
  background-size: cover; }
  .jumbo-container.jumbo-opacity:before {
    content: '';
    background-color: rgba(68, 68, 68, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .jumbo-container.jumbo-fill {
    height: 100%;
    width: 100%; }
  .jumbo-container.jumbo-video video {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    overflow: hidden;
    top: 50%;
    left: 50%; }
  .jumbo-container.jumbo-vertical {
    transform-style: preserve-3d; }
    .jumbo-container.jumbo-vertical .jumbo-content {
      top: 50%;
      transform: translateY(-50%); }
  .jumbo-container.jumbo-primary .jumbo-content {
    color: white; }
    .jumbo-container.jumbo-primary .jumbo-content h1 {
      font-weight: bold;
      font-size: 24px;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }
      @media (min-width: 768px) {
        .jumbo-container.jumbo-primary .jumbo-content h1 {
          font-size: 34px; } }
      @media (min-width: 992px) {
        .jumbo-container.jumbo-primary .jumbo-content h1 {
          font-size: 45px; } }
      @media (min-width: 1200px) {
        .jumbo-container.jumbo-primary .jumbo-content h1 {
          font-size: 56px; } }
      .jumbo-container.jumbo-primary .jumbo-content h1 span {
        color: #e74c3c; }
    .jumbo-container.jumbo-primary .jumbo-content h2 {
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      margin-top: 20px;
      font-weight: bold;
      font-size: 14px; }
      @media (min-width: 768px) {
        .jumbo-container.jumbo-primary .jumbo-content h2 {
          font-size: 16px; } }
      @media (min-width: 992px) {
        .jumbo-container.jumbo-primary .jumbo-content h2 {
          font-size: 18px; } }
    .jumbo-container.jumbo-primary .jumbo-content h3 {
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      margin-top: 20px;
      font-weight: bold;
      font-size: 12px; }
      @media (min-width: 768px) {
        .jumbo-container.jumbo-primary .jumbo-content h3 {
          font-size: 14px; } }
      @media (min-width: 992px) {
        .jumbo-container.jumbo-primary .jumbo-content h3 {
          font-size: 16px; } }
  .jumbo-container .jumbo-content {
    position: relative;
    text-align: center; }
    .jumbo-container .jumbo-content h1 {
      font-size: 45px; }
    .jumbo-container .jumbo-content h3 {
      font-size: 16px; }

.tag {
  font-size: 14px;
  display: inline;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none; }
  .tag {
    color: #555a5f;
    background-color: white;
    border: #c1c1c1 1px solid;
    border-radius: 2.5px; }
  .taga, .taga:hover, .taga:active, .taga:focus, .tag a, .tag a:hover, .tag a:active, .tag a:focus {
    color: #555a5f; }
  .tag:hover {
    text-decoration: none; }
  .tag.tag-grey {
    color: white;
    background-color: #f0f0f0;
    border-color: #aaaaaa; }
  .tag.tag-green {
    color: white;
    background-color: #2ecc71;
    border-color: #4daf7c; }
  .tag.tag-red {
    color: white;
    background-color: #d91e18;
    border-color: #cf000f; }
  .tag.tag-yellow {
    color: white;
    background-color: #f5ab35;
    border-color: #e87e04; }
  .tag::-moz-focus-inner {
    border: 0; }
  .tag.tag-mrg {
    margin-top: 10px;
    margin-bottom: 10px; }
  .tag.tag-mrg-top {
    margin-top: 10px; }
  .tag.tag-mrg-bottom {
    margin-top: 10px; }
  .tag.tag-sm {
    padding: 4px 12px; }
  .tag.tag-md {
    padding: 7px 21px; }
  .tag.tag-lg {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px; }
  .tag.tag-block {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%; }

.loader-container {
  display: none; }
  .loader-container.loader-spinner-container {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center; }
    .loader-container.loader-spinner-container .icon {
      font-size: 34px; }
    .loader-container.loader-spinner-container .message {
      margin-top: 10px; }
  .loader-container.loader-icon-container {
    height: 100%;
    padding-top: 77px;
    min-height: 209px;
    padding-bottom: 55px; }
  .loader-container.loader-modal-container {
    height: 100%;
    width: 100%; }
  .loader-container.loader-show {
    display: block; }
  .loader-container .loader-icon, .loader-container .loader-icon:before, .loader-container .loader-icon:after {
    background: white;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em; }
  .loader-container .loader-icon:before, .loader-container .loader-icon:after {
    position: absolute;
    top: 0;
    content: ''; }
  .loader-container .loader-icon:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .loader-container .loader-icon {
    color: white;
    text-indent: -9999em;
    margin: 0 auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }
  .loader-container .loader-icon:after {
    left: 1.5em; }
  .loader-container .loader-modal {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    background-color: #e74c3c;
    z-index: 99999; }

.mapview {
  width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  flex-direction: column; }
  .mapview.cookie {
    height: calc(100vh - 61px - 37px); }
  .mapview .view {
    background-color: whitesmoke;
    padding-left: 5px;
    flex: 1;
    flex-grow: 1;
    display: flex;
    position: relative; }
    .mapview .view > .row {
      width: 103%; }
    .mapview .view .content {
      flex: 1;
      flex-grow: 1;
      display: flex;
      width: 100%;
      border-right: 1px solid #d7d8d9;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden; }
    .mapview .view .maps {
      flex: 1;
      flex-grow: 1;
      display: flex;
      width: 100%;
      background-color: #b3d1ff;
      position: relative;
      background: url("/assets/images/placeholders/maps-placeholder-hq.jpg") no-repeat center center;
      background-size: cover; }
      .mapview .view .maps .trip {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 90px;
        z-index: 1000;
        background-color: rgba(245, 245, 245, 0.8);
        border-bottom: #d7d8d9 1px solid; }
        .mapview .view .maps .trip p {
          text-size: 16px;
          text-align: center;
          line-height: 90px; }
          .mapview .view .maps .trip p b {
            font-weight: bold; }
      .mapview .view .maps .angular-google-map-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px;
  text-align: center; }
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; }
  .modal .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background-color: whitesmoke;
    position: relative;
    width: auto;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    border-radius: 2.5px;
    max-width: 600px; }
    .modal .modal-dialog .close {
      position: absolute;
      right: -2px;
      top: -4px;
      font-size: 34px;
      cursor: pointer; }

.rzslider {
  margin-left: 1px;
  padding-left: 5px;
  padding-right: 5px; }
  .rzslider .rz-pointer {
    background-color: #e74c3c !important; }
    .rzslider .rz-pointer.rz-active::after {
      background-color: #f1b2ab !important; }
  .rzslider .rz-bar {
    background-color: #d7d8d9 !important; }
  .rzslider .rz-selection {
    background-color: #e74c3c !important; }

.subnav {
  position: relative;
  background-color: #555a5f; }
  .subnav ul {
    position: relative; }
    .subnav ul li {
      padding: 10px 14px;
      position: relative;
      display: inline-block; }
      .subnav ul li a {
        color: #cbcccd; }
        .subnav ul li a:hover {
          text-decoration: none;
          color: #fff; }
      .subnav ul li.active a {
        color: #fff; }
      .subnav ul li.active::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 14px;
        right: 14px;
        height: 4px;
        background: #cacccd; }
    .subnav ul::after {
      clear: both; }

tip-cont {
  cursor: pointer; }

tooltip tip {
  width: 240px;
  color: white;
  background-color: black;
  font-size: 14px;
  font-weight: normal;
  padding: 7.5px 10px;
  opacity: 0.8 !important;
  transition: none !important;
  animation: none !important; }

tooltip._top tip-arrow {
  border-top-color: black !important; }

tooltip._right tip-arrow {
  border-right-color: black !important; }

tooltip._bottom tip-arrow {
  border-bottom-color: black !important; }

tooltip._left tip-arrow {
  border-left-color: black !important; }

tooltip._multiline {
  display: inline-block; }

.tooltip {
  cursor: pointer;
  position: relative;
  display: inline; }
  .tooltip .tip {
    position: absolute;
    width: 140px;
    color: white;
    background-color: black;
    height: 30px;
    line-height: 30px;
    text-align: center;
    visibility: hidden;
    border-radius: 6px; }
    .tooltip .tip:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -2px;
      width: 0;
      height: 0;
      border-top: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent; }
  .tooltip:hover .tip {
    font-size: 14px;
    font-weight: normal;
    visibility: visible;
    opacity: 0.8;
    bottom: 30px;
    left: 50%;
    margin-left: -76px;
    z-index: 999; }

.trip-steps {
  position: relative; }
  .trip-steps .trip-steps-dot {
    top: 8px; }
  .trip-steps .trip-steps-revert {
    position: absolute;
    top: 55%;
    left: 7.5px; }
    .trip-steps .trip-steps-revert.disabled {
      display: none; }
    .trip-steps .trip-steps-revert .icon {
      color: #ed7a6e;
      background-color: white;
      font-size: 18px;
      border-radius: 50%; }
      .trip-steps .trip-steps-revert .icon:hover {
        color: #e74c3c;
        cursor: pointer; }
  .trip-steps .trip-steps-timeline {
    top: 18px; }
  .trip-steps li {
    position: relative; }
  .trip-steps .trip-steps-content {
    text-align: left;
    padding: 0 10px 10px 0;
    margin-left: 41px; }
    .trip-steps .trip-steps-content .icon {
      margin: 0 5px; }
    .trip-steps .trip-steps-content .city {
      font-size: 14px; }
    .trip-steps .trip-steps-content .address {
      font-size: 12px; }
    .trip-steps .trip-steps-content .date {
      text-align: right;
      font-size: 12px; }
    .trip-steps .trip-steps-content .layover {
      text-align: right;
      font-size: 12px; }
  .trip-steps .trip-steps-dot {
    left: 6.5px;
    position: absolute;
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    border: 6px solid #e74c3c; }
    .trip-steps .trip-steps-dot.warehouse {
      border-color: #f1b2ab !important; }
      .trip-steps .trip-steps-dot.warehouse.top:before {
        border-bottom-color: #f1b2ab !important; }
      .trip-steps .trip-steps-dot.warehouse.bottom:before {
        border-top-color: #f1b2ab !important; }
    .trip-steps .trip-steps-dot.top {
      border-color: #ed7a6e;
      background: #ed7a6e; }
      .trip-steps .trip-steps-dot.top:before {
        border: solid transparent;
        border-bottom-color: #ed7a6e;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 8px;
        left: -4px;
        top: -17px; }
      .trip-steps .trip-steps-dot.top:after {
        content: '+';
        color: white;
        position: absolute;
        top: -5.5px;
        left: -1px;
        font-weight: bold;
        font-size: 18px;
        text-align: center; }
    .trip-steps .trip-steps-dot.plus {
      width: 40px;
      height: 40px;
      border-color: #ed7a6e;
      background: #ed7a6e; }
      .trip-steps .trip-steps-dot.plus:after {
        content: '+';
        color: white;
        position: absolute;
        top: 6px;
        left: 8px;
        font-weight: bold;
        font-size: 18px;
        text-align: center; }
    .trip-steps .trip-steps-dot.bottom:before {
      border-top-color: #e74c3c;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 8px;
      left: -4px;
      top: 9px; }
    .trip-steps .trip-steps-dot.disabled {
      border-color: #f1b2ab; }
      .trip-steps .trip-steps-dot.disabled.top {
        background: #f1b2ab; }
        .trip-steps .trip-steps-dot.disabled.top:before {
          border-bottom-color: #f1b2ab; }
      .trip-steps .trip-steps-dot.disabled.bottom:before {
        border-top-color: #f1b2ab; }
  .trip-steps .trip-steps-timeline {
    left: 14.5px;
    position: absolute;
    height: 100%;
    width: 4px;
    border-left: 4px solid #e74c3c; }
    .trip-steps .trip-steps-timeline.lay {
      border-left-color: #d7d8d9;
      border-left-style: dotted; }
    .trip-steps .trip-steps-timeline.warehouse {
      border-left-color: #f1b2ab; }
    .trip-steps .trip-steps-timeline.last {
      border-left-style: dotted;
      border-left-color: #ed7a6e; }
    .trip-steps .trip-steps-timeline.disabled {
      border-left-color: #f1b2ab; }
  .trip-steps.trip-steps-show {
    padding-top: 10px; }
    .trip-steps.trip-steps-show .trip-steps-dot {
      top: 5px;
      border-color: #e74c3c; }
      .trip-steps.trip-steps-show .trip-steps-dot.top {
        background: white; }
        .trip-steps.trip-steps-show .trip-steps-dot.top:before {
          border-bottom-color: #e74c3c; }
        .trip-steps.trip-steps-show .trip-steps-dot.top:after {
          content: ''; }

.whiteframe-container {
  margin-top: 15px; }
  .whiteframe-container:last-child {
    margin-bottom: 15px; }
  .whiteframe-container .whiteframe {
    margin-bottom: 15px; }
    .whiteframe-container .whiteframe:last-child {
      margin-bottom: 0; }
  .whiteframe-container h2.heading {
    font-size: 24px;
    margin-bottom: 5px; }
  .whiteframe-container.whiteframe,
  .whiteframe-container .whiteframe {
    background-color: white;
    width: 100%;
    border-radius: 2.5px;
    position: relative;
    border: 1px solid #d7d8d9; }
    .whiteframe-container.whiteframe.arrow-top::before,
    .whiteframe-container .whiteframe.arrow-top::before {
      display: block;
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      top: -10px;
      left: 50%;
      margin-left: -9px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #CBCBCB; }
    .whiteframe-container.whiteframe.arrow-top::after,
    .whiteframe-container .whiteframe.arrow-top::after {
      display: block;
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      top: -9px;
      left: 50%;
      margin-left: -9px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #FFF; }
    .whiteframe-container.whiteframe.arrow-bottom::before,
    .whiteframe-container .whiteframe.arrow-bottom::before {
      display: block;
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -9px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid #CBCBCB; }
    .whiteframe-container.whiteframe.arrow-bottom::after,
    .whiteframe-container .whiteframe.arrow-bottom::after {
      display: block;
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      bottom: -9px;
      left: 50%;
      margin-left: -9px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid #FFF; }
    .whiteframe-container.whiteframe .header,
    .whiteframe-container .whiteframe .header {
      padding: 15px;
      border-bottom: 1px solid #d7d8d9; }
      .whiteframe-container.whiteframe .header.center,
      .whiteframe-container .whiteframe .header.center {
        text-align: center; }
      .whiteframe-container.whiteframe .header h4,
      .whiteframe-container .whiteframe .header h4 {
        font-size: 18px;
        font-weight: bold; }
      .whiteframe-container.whiteframe .header .small,
      .whiteframe-container .whiteframe .header .small {
        color: #81888a;
        font-size: 12px;
        font-weight: normal; }
      .whiteframe-container.whiteframe .header .right,
      .whiteframe-container .whiteframe .header .right {
        text-align: right; }
      .whiteframe-container.whiteframe .header p,
      .whiteframe-container .whiteframe .header p {
        line-height: 21px; }
    .whiteframe-container.whiteframe .content-details,
    .whiteframe-container .whiteframe .content-details {
      padding: 5px;
      border-top: 1px solid #d7d8d9; }
      .whiteframe-container.whiteframe .content-details .services-20 ul,
      .whiteframe-container .whiteframe .content-details .services-20 ul {
        margin-left: 2.5px;
        margin-top: 5px;
        margin-bottom: 5px; }
        .whiteframe-container.whiteframe .content-details .services-20 ul li,
        .whiteframe-container .whiteframe .content-details .services-20 ul li {
          cursor: pointer;
          margin-right: 2.5px;
          color: #d7d8d9;
          display: inline-block; }
          .whiteframe-container.whiteframe .content-details .services-20 ul li .icon,
          .whiteframe-container .whiteframe .content-details .services-20 ul li .icon {
            font-size: 18px; }
          .whiteframe-container.whiteframe .content-details .services-20 ul li.active,
          .whiteframe-container .whiteframe .content-details .services-20 ul li.active {
            color: #e74c3c; }
      .whiteframe-container.whiteframe .content-details .right-action,
      .whiteframe-container .whiteframe .content-details .right-action {
        text-align: right; }
    .whiteframe-container.whiteframe .foot,
    .whiteframe-container .whiteframe .foot {
      padding: 5px;
      border-top: 1px solid #d7d8d9; }
    .whiteframe-container.whiteframe .heading h2,
    .whiteframe-container .whiteframe .heading h2 {
      font-size: 18px;
      padding-left: 15px;
      padding-top: 15px; }
    .whiteframe-container.whiteframe .content,
    .whiteframe-container .whiteframe .content {
      padding: 10px; }
    .whiteframe-container.whiteframe .content-pointer,
    .whiteframe-container .whiteframe .content-pointer {
      background-color: #ededed;
      cursor: pointer; }
      .whiteframe-container.whiteframe .content-pointer:hover,
      .whiteframe-container .whiteframe .content-pointer:hover {
        background-color: white; }
      .whiteframe-container.whiteframe .content-pointer.active,
      .whiteframe-container .whiteframe .content-pointer.active {
        background-color: white; }
    .whiteframe-container.whiteframe a.content-pointer,
    .whiteframe-container .whiteframe a.content-pointer {
      text-decoration: none;
      display: block;
      color: inherit; }
    .whiteframe-container.whiteframe .content-profile,
    .whiteframe-container .whiteframe .content-profile {
      padding-right: 15px;
      padding-left: 15px;
      line-height: 1.5; }
      .whiteframe-container.whiteframe .content-profile .name,
      .whiteframe-container.whiteframe .content-profile .email,
      .whiteframe-container.whiteframe .content-profile .phone,
      .whiteframe-container.whiteframe .content-profile .address,
      .whiteframe-container.whiteframe .content-profile .iva,
      .whiteframe-container .whiteframe .content-profile .name,
      .whiteframe-container .whiteframe .content-profile .email,
      .whiteframe-container .whiteframe .content-profile .phone,
      .whiteframe-container .whiteframe .content-profile .address,
      .whiteframe-container .whiteframe .content-profile .iva {
        font-size: 16px; }
        .whiteframe-container.whiteframe .content-profile .name .label,
        .whiteframe-container.whiteframe .content-profile .email .label,
        .whiteframe-container.whiteframe .content-profile .phone .label,
        .whiteframe-container.whiteframe .content-profile .address .label,
        .whiteframe-container.whiteframe .content-profile .iva .label,
        .whiteframe-container .whiteframe .content-profile .name .label,
        .whiteframe-container .whiteframe .content-profile .email .label,
        .whiteframe-container .whiteframe .content-profile .phone .label,
        .whiteframe-container .whiteframe .content-profile .address .label,
        .whiteframe-container .whiteframe .content-profile .iva .label {
          font-weight: bold; }
        .whiteframe-container.whiteframe .content-profile .name .small,
        .whiteframe-container.whiteframe .content-profile .email .small,
        .whiteframe-container.whiteframe .content-profile .phone .small,
        .whiteframe-container.whiteframe .content-profile .address .small,
        .whiteframe-container.whiteframe .content-profile .iva .small,
        .whiteframe-container .whiteframe .content-profile .name .small,
        .whiteframe-container .whiteframe .content-profile .email .small,
        .whiteframe-container .whiteframe .content-profile .phone .small,
        .whiteframe-container .whiteframe .content-profile .address .small,
        .whiteframe-container .whiteframe .content-profile .iva .small {
          color: #81888a;
          font-size: 12px;
          font-weight: normal; }
      .whiteframe-container.whiteframe .content-profile .company,
      .whiteframe-container .whiteframe .content-profile .company {
        font-style: italic;
        font-size: 14px; }
    .whiteframe-container.whiteframe .content-checkout table,
    .whiteframe-container .whiteframe .content-checkout table {
      table-layout: fixed;
      width: 100%; }
      .whiteframe-container.whiteframe .content-checkout table tr:nth-child(1),
      .whiteframe-container .whiteframe .content-checkout table tr:nth-child(1) {
        border-top: none; }
      .whiteframe-container.whiteframe .content-checkout table .spanit,
      .whiteframe-container .whiteframe .content-checkout table .spanit {
        width: 100%; }
      .whiteframe-container.whiteframe .content-checkout table .status .state,
      .whiteframe-container .whiteframe .content-checkout table .status .state {
        line-height: 30px; }
      .whiteframe-container.whiteframe .content-checkout table .status .tag,
      .whiteframe-container.whiteframe .content-checkout table .status .tooltips,
      .whiteframe-container .whiteframe .content-checkout table .status .tag,
      .whiteframe-container .whiteframe .content-checkout table .status .tooltips {
        float: right; }
        .whiteframe-container.whiteframe .content-checkout table .status .tag:after,
        .whiteframe-container.whiteframe .content-checkout table .status .tooltips:after,
        .whiteframe-container .whiteframe .content-checkout table .status .tag:after,
        .whiteframe-container .whiteframe .content-checkout table .status .tooltips:after {
          clear: both; }
      .whiteframe-container.whiteframe .content-checkout table .code,
      .whiteframe-container .whiteframe .content-checkout table .code {
        font-size: 12px;
        vertical-align: bottom;
        direction: rtl; }
      .whiteframe-container.whiteframe .content-checkout table .line,
      .whiteframe-container .whiteframe .content-checkout table .line {
        border-top: 1px solid #d7d8d9; }
      .whiteframe-container.whiteframe .content-checkout table .disabled,
      .whiteframe-container .whiteframe .content-checkout table .disabled {
        text-decoration: line-through;
        color: #81888a;
        background-color: whitesmoke; }
      .whiteframe-container.whiteframe .content-checkout table .solid,
      .whiteframe-container .whiteframe .content-checkout table .solid {
        border-top: 2px solid #d7d8d9; }
      .whiteframe-container.whiteframe .content-checkout table tbody tr:first-child td,
      .whiteframe-container .whiteframe .content-checkout table tbody tr:first-child td {
        padding-top: 8px; }
      .whiteframe-container.whiteframe .content-checkout table tbody tr:last-child td,
      .whiteframe-container .whiteframe .content-checkout table tbody tr:last-child td {
        padding-bottom: 8px; }
      .whiteframe-container.whiteframe .content-checkout table td,
      .whiteframe-container .whiteframe .content-checkout table td {
        width: 100%;
        line-height: 1.5;
        vertical-align: top; }
      .whiteframe-container.whiteframe .content-checkout table td:nth-child(1),
      .whiteframe-container .whiteframe .content-checkout table td:nth-child(1) {
        padding-left: 16px; }
      .whiteframe-container.whiteframe .content-checkout table td:nth-child(2),
      .whiteframe-container .whiteframe .content-checkout table td:nth-child(2) {
        padding-right: 16px;
        text-align: right;
        white-space: nowrap; }
      .whiteframe-container.whiteframe .content-checkout table td.colspan,
      .whiteframe-container .whiteframe .content-checkout table td.colspan {
        padding-left: 16px;
        padding-right: 16px; }
    .whiteframe-container.whiteframe .content-confirmation,
    .whiteframe-container .whiteframe .content-confirmation {
      line-height: 1.6; }
      .whiteframe-container.whiteframe .content-confirmation h4,
      .whiteframe-container .whiteframe .content-confirmation h4 {
        font-size: 16px; }
    .whiteframe-container.whiteframe .content-form,
    .whiteframe-container .whiteframe .content-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px; }
    .whiteframe-container.whiteframe .content-rules .info,
    .whiteframe-container .whiteframe .content-rules .info {
      display: inline-block;
      color: #ed7a6e; }
      .whiteframe-container.whiteframe .content-rules .info:hover,
      .whiteframe-container .whiteframe .content-rules .info:hover {
        cursor: pointer;
        color: #e74c3c; }
    .whiteframe-container.whiteframe .content-rules p,
    .whiteframe-container .whiteframe .content-rules p {
      margin-top: 10px; }
    .whiteframe-container.whiteframe .content-rules ul,
    .whiteframe-container .whiteframe .content-rules ul {
      margin-bottom: 10px; }
      .whiteframe-container.whiteframe .content-rules ul li,
      .whiteframe-container .whiteframe .content-rules ul li {
        padding-bottom: 10px;
        border-bottom: #d7d8d9 2px dotted; }
        .whiteframe-container.whiteframe .content-rules ul li .table,
        .whiteframe-container .whiteframe .content-rules ul li .table {
          display: table;
          margin: auto;
          padding-top: 10px; }
          .whiteframe-container.whiteframe .content-rules ul li .table .icon,
          .whiteframe-container .whiteframe .content-rules ul li .table .icon {
            cursor: pointer;
            text-align: center;
            font-size: 34px; }
            .whiteframe-container.whiteframe .content-rules ul li .table .icon:hover,
            .whiteframe-container .whiteframe .content-rules ul li .table .icon:hover {
              color: #e74c3c; }
            .whiteframe-container.whiteframe .content-rules ul li .table .icon.disabled,
            .whiteframe-container .whiteframe .content-rules ul li .table .icon.disabled {
              color: whitesmoke;
              cursor: not-allowed; }
    .whiteframe-container.whiteframe .content-float,
    .whiteframe-container .whiteframe .content-float {
      overflow: auto; }
      .whiteframe-container.whiteframe .content-float .right,
      .whiteframe-container .whiteframe .content-float .right {
        margin-left: 10px;
        float: right; }
      .whiteframe-container.whiteframe .content-float .left,
      .whiteframe-container .whiteframe .content-float .left {
        margin-right: 10px;
        float: left; }
      .whiteframe-container.whiteframe .content-float p,
      .whiteframe-container .whiteframe .content-float p {
        line-height: 26px; }
      .whiteframe-container.whiteframe .content-float:after,
      .whiteframe-container .whiteframe .content-float:after {
        clear: both; }
    .whiteframe-container.whiteframe .content-nocontent,
    .whiteframe-container .whiteframe .content-nocontent {
      padding-top: 50px;
      padding-bottom: 50px;
      min-height: 200px;
      text-align: center;
      font-size: 18px; }
      .whiteframe-container.whiteframe .content-nocontent .icon,
      .whiteframe-container .whiteframe .content-nocontent .icon {
        font-size: 45px; }
      .whiteframe-container.whiteframe .content-nocontent .message,
      .whiteframe-container .whiteframe .content-nocontent .message {
        font-size: 24px;
        line-height: 1.6; }
    .whiteframe-container.whiteframe .content-evidence,
    .whiteframe-container .whiteframe .content-evidence {
      text-align: center; }
      .whiteframe-container.whiteframe .content-evidence h4,
      .whiteframe-container .whiteframe .content-evidence h4 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 24px; }
      .whiteframe-container.whiteframe .content-evidence p,
      .whiteframe-container .whiteframe .content-evidence p {
        padding: 5px 15px 20px 15px; }
      .whiteframe-container.whiteframe .content-evidence .btn,
      .whiteframe-container .whiteframe .content-evidence .btn {
        margin-bottom: 10px; }
    .whiteframe-container.whiteframe .content-map .angular-google-map-container,
    .whiteframe-container .whiteframe .content-map .angular-google-map-container {
      min-height: 240px; }
    .whiteframe-container.whiteframe .content-map-order .angular-google-map-container,
    .whiteframe-container .whiteframe .content-map-order .angular-google-map-container {
      height: 180px; }
    .whiteframe-container.whiteframe .content-services,
    .whiteframe-container .whiteframe .content-services {
      padding: 10px 10px 0 10px; }
      .whiteframe-container.whiteframe .content-services p,
      .whiteframe-container .whiteframe .content-services p {
        margin-top: 10px;
        margin-left: 10px; }
      .whiteframe-container.whiteframe .content-services ul,
      .whiteframe-container .whiteframe .content-services ul {
        display: inline-block;
        padding: 10px 0 0 0; }
        .whiteframe-container.whiteframe .content-services ul.services-2 .icon,
        .whiteframe-container .whiteframe .content-services ul.services-2 .icon {
          font-size: 24px;
          width: 36px;
          height: 36px; }
        .whiteframe-container.whiteframe .content-services ul.services-2 li,
        .whiteframe-container .whiteframe .content-services ul.services-2 li {
          padding: 16px;
          padding-bottom: 4px;
          height: 40px;
          margin-bottom: 0px;
          width: 50%; }
          @media (min-width: 992px) {
            .whiteframe-container.whiteframe .content-services ul.services-2 li,
            .whiteframe-container .whiteframe .content-services ul.services-2 li {
              width: 50%; } }
        .whiteframe-container.whiteframe .content-services ul.services-5 li,
        .whiteframe-container .whiteframe .content-services ul.services-5 li {
          width: 10%; }
          @media (min-width: 992px) {
            .whiteframe-container.whiteframe .content-services ul.services-5 li,
            .whiteframe-container .whiteframe .content-services ul.services-5 li {
              width: 20%; } }
        .whiteframe-container.whiteframe .content-services ul.services-10 li,
        .whiteframe-container .whiteframe .content-services ul.services-10 li {
          width: 10%; }
        .whiteframe-container.whiteframe .content-services ul li,
        .whiteframe-container .whiteframe .content-services ul li {
          float: left;
          color: #d1d1d1;
          cursor: pointer;
          text-align: center;
          margin-bottom: 10px;
          height: 48px;
          width: 48px; }
          .whiteframe-container.whiteframe .content-services ul li:after,
          .whiteframe-container .whiteframe .content-services ul li:after {
            clear: both; }
          .whiteframe-container.whiteframe .content-services ul li .icon,
          .whiteframe-container .whiteframe .content-services ul li .icon {
            border: #d1d1d1 2px solid;
            border-radius: 5px;
            padding: 2.5px;
            font-size: 24px;
            height: 38px;
            width: 38px; }
            @media (min-width: 768px) {
              .whiteframe-container.whiteframe .content-services ul li .icon,
              .whiteframe-container .whiteframe .content-services ul li .icon {
                font-size: 34px;
                height: 48px;
                width: 48px; } }
          .whiteframe-container.whiteframe .content-services ul li.active,
          .whiteframe-container .whiteframe .content-services ul li.active {
            color: #e74c3c; }
            .whiteframe-container.whiteframe .content-services ul li.active .icon,
            .whiteframe-container .whiteframe .content-services ul li.active .icon {
              border-color: #e74c3c; }
    .whiteframe-container.whiteframe .content-services-text,
    .whiteframe-container .whiteframe .content-services-text {
      padding: 10px 10px 0 10px; }
      .whiteframe-container.whiteframe .content-services-text p,
      .whiteframe-container .whiteframe .content-services-text p {
        margin-top: 10px;
        margin-left: 10px; }
      .whiteframe-container.whiteframe .content-services-text ul,
      .whiteframe-container .whiteframe .content-services-text ul {
        display: inline-block;
        padding: 10px 0 0 0; }
        .whiteframe-container.whiteframe .content-services-text ul.services-2 .icon,
        .whiteframe-container .whiteframe .content-services-text ul.services-2 .icon {
          font-size: 24px;
          width: 36px;
          height: 36px; }
        .whiteframe-container.whiteframe .content-services-text ul.services-2 li,
        .whiteframe-container .whiteframe .content-services-text ul.services-2 li {
          padding: 16px;
          padding-bottom: 4px;
          height: 40px;
          margin-bottom: 0px;
          width: 50%; }
          @media (min-width: 992px) {
            .whiteframe-container.whiteframe .content-services-text ul.services-2 li,
            .whiteframe-container .whiteframe .content-services-text ul.services-2 li {
              width: 50%; } }
        .whiteframe-container.whiteframe .content-services-text ul.services-5 li,
        .whiteframe-container .whiteframe .content-services-text ul.services-5 li {
          width: 10%; }
          @media (min-width: 992px) {
            .whiteframe-container.whiteframe .content-services-text ul.services-5 li,
            .whiteframe-container .whiteframe .content-services-text ul.services-5 li {
              width: 20%; } }
        .whiteframe-container.whiteframe .content-services-text ul.services-10 li,
        .whiteframe-container .whiteframe .content-services-text ul.services-10 li {
          width: 10%; }
        .whiteframe-container.whiteframe .content-services-text ul li,
        .whiteframe-container .whiteframe .content-services-text ul li {
          float: left;
          color: #d1d1d1;
          cursor: pointer;
          text-align: center;
          margin-bottom: 10px;
          height: 82px;
          width: 48px; }
          .whiteframe-container.whiteframe .content-services-text ul li p,
          .whiteframe-container .whiteframe .content-services-text ul li p {
            color: black; }
          .whiteframe-container.whiteframe .content-services-text ul li:after,
          .whiteframe-container .whiteframe .content-services-text ul li:after {
            clear: both; }
          .whiteframe-container.whiteframe .content-services-text ul li .icon,
          .whiteframe-container .whiteframe .content-services-text ul li .icon {
            border: #d1d1d1 2px solid;
            border-radius: 5px;
            padding: 2.5px;
            font-size: 24px;
            height: 38px;
            width: 38px; }
            @media (min-width: 768px) {
              .whiteframe-container.whiteframe .content-services-text ul li .icon,
              .whiteframe-container .whiteframe .content-services-text ul li .icon {
                font-size: 34px;
                height: 48px;
                width: 48px; } }
          .whiteframe-container.whiteframe .content-services-text ul li.active,
          .whiteframe-container .whiteframe .content-services-text ul li.active {
            color: #e74c3c; }
            .whiteframe-container.whiteframe .content-services-text ul li.active .icon,
            .whiteframe-container .whiteframe .content-services-text ul li.active .icon {
              border-color: #e74c3c; }
    .whiteframe-container.whiteframe .content-itinerary,
    .whiteframe-container .whiteframe .content-itinerary {
      border-top: #d7d8d9 1px dotted;
      background: rgba(237, 237, 237, 0.5);
      padding-top: 10px; }
    .whiteframe-container.whiteframe .content-collapse.ng-enter,
    .whiteframe-container .whiteframe .content-collapse.ng-enter {
      transition: 0.5s;
      opacity: 0;
      max-height: 0px; }
    .whiteframe-container.whiteframe .content-collapse.ng-enter-active,
    .whiteframe-container .whiteframe .content-collapse.ng-enter-active {
      opacity: 1;
      max-height: 105px; }
    .whiteframe-container.whiteframe .content-collapse.ng-leave,
    .whiteframe-container .whiteframe .content-collapse.ng-leave {
      transition: 0.5s;
      opacity: 1;
      max-height: 105px; }
    .whiteframe-container.whiteframe .content-collapse.ng-leave-active,
    .whiteframe-container .whiteframe .content-collapse.ng-leave-active {
      opacity: 0;
      max-height: 0px; }
    .whiteframe-container.whiteframe .content-more,
    .whiteframe-container .whiteframe .content-more {
      box-shadow: inset 0 5px 7px -5px rgba(0, 0, 0, 0.15);
      background: rgba(153, 153, 153, 0.1);
      border-top: #d7d8d9 1px solid; }
      .whiteframe-container.whiteframe .content-more .right-action,
      .whiteframe-container .whiteframe .content-more .right-action {
        padding: 5px 10px 5px 0;
        text-align: right; }
        .whiteframe-container.whiteframe .content-more .right-action .btn,
        .whiteframe-container .whiteframe .content-more .right-action .btn {
          cursor: pointer;
          white-space: nowrap;
          margin-top: 5px;
          margin-bottom: 5px;
          padding: 5px 6px 5px 5px;
          text-align: center;
          background-color: #e74c3c;
          color: white;
          border-radius: 2.5px;
          border: none;
          font-size: 16px; }
          .whiteframe-container.whiteframe .content-more .right-action .btn .icon,
          .whiteframe-container .whiteframe .content-more .right-action .btn .icon {
            margin-right: 5px; }
          .whiteframe-container.whiteframe .content-more .right-action .btn:hover,
          .whiteframe-container .whiteframe .content-more .right-action .btn:hover {
            cursor: pointer; }
      .whiteframe-container.whiteframe .content-more .left-action,
      .whiteframe-container .whiteframe .content-more .left-action {
        padding: 5px 0px 5px 10px;
        text-align: left; }
        .whiteframe-container.whiteframe .content-more .left-action .btn,
        .whiteframe-container .whiteframe .content-more .left-action .btn {
          cursor: pointer;
          white-space: nowrap;
          margin-top: 5px;
          margin-bottom: 5px;
          padding: 5px 6px 5px 5px;
          text-align: center;
          background-color: #999999;
          color: white;
          border-radius: 2.5px;
          border: none;
          font-size: 16px; }
          .whiteframe-container.whiteframe .content-more .left-action .btn .icon,
          .whiteframe-container .whiteframe .content-more .left-action .btn .icon {
            margin-right: 5px; }
          .whiteframe-container.whiteframe .content-more .left-action .btn:hover,
          .whiteframe-container .whiteframe .content-more .left-action .btn:hover {
            cursor: pointer; }
    .whiteframe-container.whiteframe .item-foot,
    .whiteframe-container .whiteframe .item-foot {
      padding: 5px;
      border-top: 1px solid #d7d8d9; }
      .whiteframe-container.whiteframe .item-foot .right-action,
      .whiteframe-container .whiteframe .item-foot .right-action {
        text-align: right; }
      .whiteframe-container.whiteframe .item-foot .price,
      .whiteframe-container .whiteframe .item-foot .price {
        padding: 5px 10px 5px 0;
        text-align: right; }
        .whiteframe-container.whiteframe .item-foot .price strong,
        .whiteframe-container .whiteframe .item-foot .price strong {
          font-size: 24px;
          white-space: nowrap; }
        .whiteframe-container.whiteframe .item-foot .price p,
        .whiteframe-container .whiteframe .item-foot .price p {
          font-size: 12px;
          white-space: nowrap; }
      .whiteframe-container.whiteframe .item-foot .details,
      .whiteframe-container .whiteframe .item-foot .details {
        padding: 5px 0 5px 10px; }
        .whiteframe-container.whiteframe .item-foot .details b,
        .whiteframe-container .whiteframe .item-foot .details b {
          font-weight: bold; }
        .whiteframe-container.whiteframe .item-foot .details h3,
        .whiteframe-container .whiteframe .item-foot .details h3 {
          font-size: 18px;
          font-weight: bold; }
        .whiteframe-container.whiteframe .item-foot .details .icon-star,
        .whiteframe-container .whiteframe .item-foot .details .icon-star {
          color: #f5ab35; }
        .whiteframe-container.whiteframe .item-foot .details .small,
        .whiteframe-container .whiteframe .item-foot .details .small {
          color: #81888a;
          font-size: 12px;
          font-weight: normal; }
        .whiteframe-container.whiteframe .item-foot .details p,
        .whiteframe-container .whiteframe .item-foot .details p {
          font-size: 14px; }
        .whiteframe-container.whiteframe .item-foot .details .blur,
        .whiteframe-container .whiteframe .item-foot .details .blur {
          color: transparent;
          text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); }
          .whiteframe-container.whiteframe .item-foot .details .blur::selection,
          .whiteframe-container .whiteframe .item-foot .details .blur::selection {
            background: transparent; }
    .whiteframe-container.whiteframe .content-items > li,
    .whiteframe-container .whiteframe .content-items > li {
      border-top: 1px dotted #d7d8d9; }
      .whiteframe-container.whiteframe .content-items > li.disabled,
      .whiteframe-container .whiteframe .content-items > li.disabled {
        text-decoration: line-through;
        color: #81888a;
        background-color: whitesmoke; }
      .whiteframe-container.whiteframe .content-items > li:first-child,
      .whiteframe-container .whiteframe .content-items > li:first-child {
        border-top: none; }
    .whiteframe-container.whiteframe .content-items .row,
    .whiteframe-container .whiteframe .content-items .row {
      height: inherit; }
    .whiteframe-container.whiteframe .content-items .right-action,
    .whiteframe-container .whiteframe .content-items .right-action {
      padding: 5px 10px 5px 0;
      text-align: right; }
    .whiteframe-container.whiteframe .content-items .image img,
    .whiteframe-container .whiteframe .content-items .image img {
      height: 127px; }
    .whiteframe-container.whiteframe .content-items .image-supplier img,
    .whiteframe-container .whiteframe .content-items .image-supplier img {
      height: 240px; }
      @media (min-width: 768px) {
        .whiteframe-container.whiteframe .content-items .image-supplier img,
        .whiteframe-container .whiteframe .content-items .image-supplier img {
          height: 140px; } }
    .whiteframe-container.whiteframe .content-items .image-orders img,
    .whiteframe-container .whiteframe .content-items .image-orders img {
      height: 127px; }
    .whiteframe-container.whiteframe .content-items .orders,
    .whiteframe-container .whiteframe .content-items .orders {
      padding: 10px 15px 10px 10px;
      line-height: 1.5; }
      .whiteframe-container.whiteframe .content-items .orders .btn,
      .whiteframe-container .whiteframe .content-items .orders .btn {
        cursor: pointer;
        margin-top: 37px; }
    .whiteframe-container.whiteframe .content-items .details,
    .whiteframe-container .whiteframe .content-items .details {
      padding: 0 0 0 10px; }
      .whiteframe-container.whiteframe .content-items .details b,
      .whiteframe-container .whiteframe .content-items .details b {
        font-weight: bold; }
      .whiteframe-container.whiteframe .content-items .details h3,
      .whiteframe-container .whiteframe .content-items .details h3 {
        padding-top: 5px;
        font-size: 18px;
        font-weight: bold; }
      .whiteframe-container.whiteframe .content-items .details .icon-star,
      .whiteframe-container .whiteframe .content-items .details .icon-star {
        color: #f5ab35; }
      .whiteframe-container.whiteframe .content-items .details .small,
      .whiteframe-container .whiteframe .content-items .details .small {
        color: #81888a;
        font-size: 12px;
        font-weight: normal; }
      .whiteframe-container.whiteframe .content-items .details p,
      .whiteframe-container .whiteframe .content-items .details p {
        margin-bottom: 5px;
        font-size: 14px; }
    .whiteframe-container.whiteframe .content-items .services-20,
    .whiteframe-container .whiteframe .content-items .services-20 {
      padding: 5px 0 0 10px; }
      .whiteframe-container.whiteframe .content-items .services-20 ul li,
      .whiteframe-container .whiteframe .content-items .services-20 ul li {
        cursor: pointer;
        margin-right: 2.5px;
        color: #d7d8d9;
        display: inline-block; }
        .whiteframe-container.whiteframe .content-items .services-20 ul li .icon,
        .whiteframe-container .whiteframe .content-items .services-20 ul li .icon {
          font-size: 18px; }
        .whiteframe-container.whiteframe .content-items .services-20 ul li.active,
        .whiteframe-container .whiteframe .content-items .services-20 ul li.active {
          color: #e74c3c; }
    .whiteframe-container.whiteframe .content-items .services-10,
    .whiteframe-container .whiteframe .content-items .services-10 {
      padding: 0 0 5px 10px; }
      .whiteframe-container.whiteframe .content-items .services-10 ul li,
      .whiteframe-container .whiteframe .content-items .services-10 ul li {
        width: 9%;
        cursor: pointer;
        margin-right: 2.5px;
        color: #d7d8d9;
        display: inline-block; }
        .whiteframe-container.whiteframe .content-items .services-10 ul li .icon,
        .whiteframe-container .whiteframe .content-items .services-10 ul li .icon {
          font-size: 18px; }
        .whiteframe-container.whiteframe .content-items .services-10 ul li.active,
        .whiteframe-container .whiteframe .content-items .services-10 ul li.active {
          color: #e74c3c; }
    .whiteframe-container.whiteframe .content-items .price,
    .whiteframe-container .whiteframe .content-items .price {
      padding: 5px 10px 5px 0;
      text-align: right; }
      .whiteframe-container.whiteframe .content-items .price strong,
      .whiteframe-container .whiteframe .content-items .price strong {
        font-size: 24px;
        white-space: nowrap; }
      .whiteframe-container.whiteframe .content-items .price p,
      .whiteframe-container .whiteframe .content-items .price p {
        font-size: 12px;
        white-space: nowrap; }
    .whiteframe-container.whiteframe .loader-container.loader-icon, .whiteframe-container.whiteframe .loader-container.loader-icon:before, .whiteframe-container.whiteframe .loader-container.loader-icon:after,
    .whiteframe-container .whiteframe .loader-container.loader-icon,
    .whiteframe-container .whiteframe .loader-container.loader-icon:before,
    .whiteframe-container .whiteframe .loader-container.loader-icon:after {
      background: #d7d8d9; }
    .whiteframe-container.whiteframe .loader-container.loader-icon,
    .whiteframe-container .whiteframe .loader-container.loader-icon {
      color: #d7d8d9; }

.modal h3.top-title {
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-top: 5px; }

.modal p.message {
  text-align: center;
  font-size: 16px;
  padding: 25px 20px; }

.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0; }

.clearfix:after {
  clear: both; }

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.date-picker-date-time {
  position: absolute; }

.date-range .date-picker-date-time {
  position: inherit; }

[date-picker-wrapper] {
  position: absolute;
  min-width: 240px;
  z-index: 10;
  display: block;
  font-size: 14px; }

[date-time-append] [date-picker-wrapper] [date-picker] {
  margin-top: -30px; }

[date-time-append] [date-picker] {
  position: relative;
  margin-right: -1000px;
  margin-bottom: -1000px; }

[date-range] [date-picker] .after.before {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #499dcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f6ab4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f6ab4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f6ab4', GradientType=0);
  border-color: #2f6ab4 #2f6ab4 #1f4677;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f6ab4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); }

[date-range] [date-picker] .after.before:hover,
[date-range] [date-picker] .after.before:active,
[date-range] [date-picker] .after.before.active,
[date-range] [date-picker] .after.before.disabled,
[date-range] [date-picker] .after.before[disabled],
[date-range] [date-picker] .after.before.muted {
  color: #ffffff;
  background-color: #2f6ab4;
  *background-color: #2a5ea0; }

[date-range] [date-picker] .after.before:active,
[date-range] [date-picker] .after.before.active {
  background-color: #24528c \9; }

[date-picker].hidden {
  display: none; }

[date-picker] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #fff;
  /* GENERAL */
  padding: 0px;
  /* SPECIFIC */ }

[date-picker] table {
  margin: 0; }

[date-picker] td,
[date-picker] th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none; }

[date-picker] td {
  padding: 0px 0px; }

[date-picker] .switch {
  width: 145px; }

[date-picker] td span,
[date-picker] th span {
  display: block;
  width: 23%;
  height: 26px;
  line-height: 25px;
  float: left;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

[date-picker] td span:hover,
[date-picker] th span:hover {
  background: #eeeeee; }

[date-picker] td span.disabled,
[date-picker] th span.disabled,
[date-picker] td span.disabled:hover,
[date-picker] th span.disabled:hover,
[date-picker] td span.muted,
[date-picker] th span.muted,
[date-picker] td span.muted:hover,
[date-picker] th span.muted:hover {
  background: none;
  color: #999999;
  cursor: default; }

[date-picker] th span {
  padding: 0px 0px;
  width: 100%; }

[date-picker] .active,
[date-picker] .now {
  color: #ffffff;
  background-color: #006dcc;
  background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
  background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
  background-image: -o-linear-gradient(top, #0088cc, #0044cc);
  background-image: linear-gradient(to bottom, #0088cc, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #0044cc;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

[date-picker] .active:hover,
[date-picker] .now:hover,
[date-picker] .active:active,
[date-picker] .now:active,
[date-picker] .active.active,
[date-picker] .now.active,
[date-picker] .active.disabled,
[date-picker] .now.disabled,
[date-picker] .active[disabled],
[date-picker] .now[disabled],
[date-picker] .active.muted,
[date-picker] .now.muted {
  color: #ffffff;
  background-color: #0044cc;
  *background-color: #003bb3; }

[date-picker] .active:active,
[date-picker] .now:active,
[date-picker] .active.active,
[date-picker] .now.active {
  background-color: #003399 \9; }

[date-picker] .now {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ee735b;
  background-image: -moz-linear-gradient(top, #ee5f5b, #ee905b);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#ee905b));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #ee905b);
  background-image: -o-linear-gradient(top, #ee5f5b, #ee905b);
  background-image: linear-gradient(to bottom, #ee5f5b, #ee905b);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffee905b', GradientType=0);
  border-color: #ee905b #ee905b #e56218;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #ee905b;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); }

[date-picker] .now:hover,
[date-picker] .now:active,
[date-picker] .now.active,
[date-picker] .now.disabled,
[date-picker] .now[disabled],
[date-picker] .now.muted {
  color: #ffffff;
  background-color: #ee905b;
  *background-color: #ec8044; }

[date-picker] .now:active,
[date-picker] .now.active {
  background-color: #e9712d \9; }

[date-picker] .disabled,
[date-picker] .muted {
  background: none;
  color: #999999 !important;
  cursor: default; }

[date-picker] .year td span, [date-picker] .month td span, [date-picker] .minutes td span {
  height: 54px;
  line-height: 54px; }

[date-picker] .date td {
  padding: 0; }

[date-picker] .date td span {
  width: 100%;
  height: 26px;
  line-height: 26px; }

[date-picker] th span:hover,
[date-picker] .date td span:hover {
  background: #eeeeee;
  cursor: pointer; }

[date-picker] th.weekday span,
[date-picker] th.weekday span:hover {
  background: none;
  cursor: default; }

[date-picker] th.switch {
  text-transform: capitalize; }

.angular-google-map-container {
  min-height: 125px; }

.colorpicker .form-control {
  height: 28px; }

.colorpicker .color-picker-wrapper.color-picker-swatch-only .input-group .input-group-addon.color-picker-swatch {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  height: auto;
  width: 28px; }

.cookielaw {
  color: #999999;
  padding: 10px;
  background-color: #dfdfdf;
  border-bottom: 1px solid #d7d8d9; }
  .cookielaw p {
    padding-right: 15px; }
  .cookielaw a, .cookielaw a:hover, .cookielaw a:active, .cookielaw a:focus {
    color: #999999; }
  .cookielaw .close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    border: 0;
    cursor: pointer;
    background: transparent;
    font-size: 21px;
    font-weight: bold;
    color: #999999; }
    .cookielaw .close:hover {
      color: black; }

.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0; }

.clearfix:after {
  clear: both; }

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.date-picker-date-time {
  position: absolute; }

.date-range .date-picker-date-time {
  position: inherit; }

[date-picker-wrapper] {
  position: absolute;
  min-width: 220px;
  z-index: 10;
  display: block;
  font-size: 14px; }

[date-time-append] [date-picker-wrapper] [date-picker] {
  margin-top: -30px; }

[date-time-append] [date-picker] {
  position: relative;
  margin-right: -1000px;
  margin-bottom: -1000px; }

[date-range] [date-picker] .after.before {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #499dcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f6ab4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f6ab4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f6ab4', GradientType=0);
  border-color: #2f6ab4 #2f6ab4 #1f4677;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f6ab4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); }

[date-range] [date-picker] .after.before:hover,
[date-range] [date-picker] .after.before:active,
[date-range] [date-picker] .after.before.active,
[date-range] [date-picker] .after.before.disabled,
[date-range] [date-picker] .after.before[disabled],
[date-range] [date-picker] .after.before.muted {
  color: #ffffff;
  background-color: #2f6ab4;
  *background-color: #2a5ea0; }

[date-range] [date-picker] .after.before:active,
[date-range] [date-picker] .after.before.active {
  background-color: #24528c \9; }

[date-picker].hidden {
  display: none; }

[date-picker] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #fff;
  /* GENERAL */
  padding: 0px;
  /* SPECIFIC */ }

[date-picker] table {
  margin: 0; }

[date-picker] td,
[date-picker] th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none; }

[date-picker] td {
  padding: 0px 0px; }

[date-picker] .switch {
  width: 145px; }

[date-picker] td span,
[date-picker] th span {
  display: block;
  width: 23%;
  height: 26px;
  line-height: 25px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

[date-picker] td span:hover,
[date-picker] th span:hover {
  background: #eeeeee; }

[date-picker] td span.disabled,
[date-picker] th span.disabled,
[date-picker] td span.disabled:hover,
[date-picker] th span.disabled:hover,
[date-picker] td span.muted,
[date-picker] th span.muted,
[date-picker] td span.muted:hover,
[date-picker] th span.muted:hover {
  background: none;
  color: #999999;
  cursor: default; }

[date-picker] th span {
  padding: 0px 0px;
  width: 100%; }

[date-picker] .active,
[date-picker] .now {
  color: #ffffff;
  background-color: #006dcc;
  background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
  background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
  background-image: -o-linear-gradient(top, #0088cc, #0044cc);
  background-image: linear-gradient(to bottom, #0088cc, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #0044cc;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

[date-picker] .active:hover,
[date-picker] .now:hover,
[date-picker] .active:active,
[date-picker] .now:active,
[date-picker] .active.active,
[date-picker] .now.active,
[date-picker] .active.disabled,
[date-picker] .now.disabled,
[date-picker] .active[disabled],
[date-picker] .now[disabled],
[date-picker] .active.muted,
[date-picker] .now.muted {
  color: #ffffff;
  background-color: #0044cc;
  *background-color: #003bb3; }

[date-picker] .active:active,
[date-picker] .now:active,
[date-picker] .active.active,
[date-picker] .now.active {
  background-color: #003399 \9; }

[date-picker] .now {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ee735b;
  background-image: -moz-linear-gradient(top, #ee5f5b, #ee905b);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#ee905b));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #ee905b);
  background-image: -o-linear-gradient(top, #ee5f5b, #ee905b);
  background-image: linear-gradient(to bottom, #ee5f5b, #ee905b);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffee905b', GradientType=0);
  border-color: #ee905b #ee905b #e56218;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #ee905b;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); }

[date-picker] .now:hover,
[date-picker] .now:active,
[date-picker] .now.active,
[date-picker] .now.disabled,
[date-picker] .now[disabled],
[date-picker] .now.muted {
  color: #ffffff;
  background-color: #ee905b;
  *background-color: #ec8044; }

[date-picker] .now:active,
[date-picker] .now.active {
  background-color: #e9712d \9; }

[date-picker] .disabled,
[date-picker] .muted {
  background: none;
  color: #999999 !important;
  cursor: default; }

[date-picker] .year td span, [date-picker] .month td span, [date-picker] .minutes td span {
  height: 54px;
  line-height: 54px; }

[date-picker] .date td {
  padding: 0; }

[date-picker] .date td span {
  width: 100%;
  height: 26px;
  line-height: 26px; }

[date-picker] th span:hover,
[date-picker] .date td span:hover {
  background: #eeeeee;
  cursor: pointer; }

[date-picker] th.weekday span,
[date-picker] th.weekday span:hover {
  background: none;
  cursor: default; }

[date-picker] th.switch {
  text-transform: capitalize; }

.br-dropdown {
  position: relative;
  min-width: 400px; }
  .br-dropdown .br-no-margin {
    margin: 0px; }
  .br-dropdown .values-list {
    overflow-y: auto;
    max-height: 200px; }
  .br-dropdown .form-row .type {
    padding-top: 4px;
    padding-bottom: 4px; }
  .br-dropdown .form-row .row {
    display: flex;
    align-items: center;
    min-height: 40px; }

.br-dropdown.container {
  margin: 0px;
  display: none;
  padding: 0px; }

.br-dropdown-close, .br-dropdown-placeholder {
  display: none;
  opacity: 0;
  transition: width 1s ease-in;
  transition: height 1s ease-in; }

.br-dropdown-open {
  opacity: 1;
  z-index: 1000;
  display: block !important; }

.modal.filter-save h3.top-title {
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-top: 5px; }

.modal.filter-save p.message {
  text-align: center;
  font-size: 16px;
  padding: 25px 20px; }

.modal.filter-save .row {
  display: flex;
  align-items: center;
  min-height: 40px; }

.angular-google-map-container {
  min-height: 125px; }

.angular-google-map-container {
  min-height: 125px; }

.notification-message {
  background-color: #36994c; }

.notification-transfer {
  background-color: #c4ffc4;
  background-image: url(/assets/images/icons/money.png) !important; }

.notification-card {
  background-color: #8eff60;
  background-image: url(/assets/images/icons/pay.png) !important; }

.notification-failed {
  background-color: #ff6f6b;
  background-image: url(/assets/images/icons/no-entry.png) !important; }

.notification-cancelled {
  background-color: #ffc972;
  background-image: url(/assets/images/icons/close.png) !important; }

.placepicker input.ui-autocomplete-loading {
  color: #e74c3c; }

.placepicker input.gm-err-autocomplete {
  background-position: calc(100% - 10px) center;
  padding-left: 10px !important;
  background-repeat: no-repeat !important;
  background-size: 15px 15px !important; }

.placepicker .input-btn {
  background: #e74c3c; }

.placepicker-slim input.ui-autocomplete-loading {
  color: #e74c3c; }

.placepicker-slim input.gm-err-autocomplete {
  background-position: calc(100% - 10px) center;
  padding-left: 10px !important;
  background-repeat: no-repeat !important;
  background-size: 15px 15px !important; }

.placepicker input.ui-autocomplete-loading {
  color: #e74c3c; }

.placepicker input.gm-err-autocomplete {
  background-position: calc(100% - 10px) center;
  padding-left: 10px !important;
  background-repeat: no-repeat !important;
  background-size: 15px 15px !important; }

.content-line {
  padding: 2px 8px 2px 8px; }

.outline {
  border: black 1px solid;
  padding: 4px; }

.content-line {
  padding: 2px 8px 2px 8px; }

.outline {
  border: black 1px solid;
  padding: 4px; }

.quote-details-new pre {
  white-space: pre-wrap; }

.content-line {
  padding: 2px 8px 2px 8px; }

.outline {
  border: black 1px solid;
  padding: 4px; }

.tag-narrow {
  padding: 0px 8px 0px 8px; }

.subtitle {
  font-weight: bold; }

.border-bottom {
  border-bottom: 1px solid lightgray; }

.content-line {
  padding: 2px 8px 2px 8px; }

.outline {
  border: black 1px solid;
  padding: 4px; }

.simple-dropdown {
  position: absolute;
  background-color: #FFFFFF;
  border: 1px solid #888888;
  z-index: 1; }
  .simple-dropdown .simple-dropdown-option {
    padding: 5px;
    cursor: pointer; }
    .simple-dropdown .simple-dropdown-option:hover {
      background-color: #efefef; }

.slider {
  height: 100%;
  width: 100%; }
  .slider .active-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .slider .active-container img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .slider .active-container:hover .arrows-container {
      opacity: 1; }
    .slider .active-container .arrows-container {
      opacity: 0.2;
      width: 100%;
      left: 0;
      top: 50%;
      margin-top: -15px;
      transition: opacity .5s;
      position: absolute; }
      .slider .active-container .arrows-container .click {
        cursor: pointer; }
      .slider .active-container .arrows-container .prev:after, .slider .active-container .arrows-container .prev:before, .slider .active-container .arrows-container .next:after, .slider .active-container .arrows-container .next:before {
        content: '';
        position: absolute;
        width: 50%;
        height: 50%;
        background-color: #FFF; }
      .slider .active-container .arrows-container .prev, .slider .active-container .arrows-container .next {
        position: absolute;
        display: block;
        width: 20px;
        height: 30px;
        cursor: pointer; }
      .slider .active-container .arrows-container .prev {
        left: 20px; }
        .slider .active-container .arrows-container .prev:before {
          left: 30%;
          top: 0;
          transform: skew(145deg, 0deg);
          box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5); }
        .slider .active-container .arrows-container .prev:after {
          left: 30%;
          top: 50%;
          transform: skew(-145deg, 0deg);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); }
      .slider .active-container .arrows-container .next {
        right: 20px; }
        .slider .active-container .arrows-container .next:before {
          right: 30%;
          top: 0;
          transform: skew(35deg, 0deg);
          box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5); }
        .slider .active-container .arrows-container .next:after {
          right: 30%;
          top: 50%;
          transform: skew(-35deg, 0deg);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); }
    .slider .active-container .head {
      position: absolute;
      bottom: 5px;
      left: 5px;
      font-size: 18px;
      color: #ededed;
      text-shadow: 0px 0px 5px black; }
  .slider .thumbnails-container {
    border-top: 1px solid #d7d8d9;
    position: relative;
    width: 100%; }
    .slider .thumbnails-container .thumbnail {
      position: relative;
      height: 80px;
      width: 16.665%;
      overflow: hidden;
      float: left;
      border-right: 1px solid #d7d8d9; }
      .slider .thumbnails-container .thumbnail img {
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-top: 0px; }
      .slider .thumbnails-container .thumbnail.active {
        border-bottom: 2px solid #e74c3c; }
      .slider .thumbnails-container .thumbnail:after {
        clear: both; }
      .slider .thumbnails-container .thumbnail:last-of-type {
        border-right: none; }

.angular-google-map-container {
  min-height: 125px; }

.br-table table {
  width: 100%; }

.br-table th, td {
  padding: 8px;
  border: 1px solid #ddd;
  border-collapse: collapse;
  text-align: left; }

.br-table th {
  background: #eee;
  font-size: 110%; }

.br-table tbody {
  overflow-y: auto;
  max-height: 1vh; }

.uploader {
  height: 100%;
  width: 100%; }
  .uploader .thumbnails-container .thumbnail {
    margin: 0 0 1px 0;
    position: relative;
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: left;
    border-right: 1px solid #d7d8d9; }
    .uploader .thumbnails-container .thumbnail img, .uploader .thumbnails-container .thumbnail .img {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .uploader .thumbnails-container .thumbnail:hover .arrows-container {
      opacity: 1; }
    .uploader .thumbnails-container .thumbnail:after {
      clear: both; }
    .uploader .thumbnails-container .thumbnail:last-of-type {
      border-right: none;
      margin-right: 1px; }
    .uploader .thumbnails-container .thumbnail .arrows-container {
      opacity: 0.2;
      width: 100%;
      left: 0;
      top: 50%;
      margin-top: -15px;
      transition: opacity .5s;
      position: absolute; }
      .uploader .thumbnails-container .thumbnail .arrows-container .click {
        cursor: pointer; }
      .uploader .thumbnails-container .thumbnail .arrows-container .prev:after, .uploader .thumbnails-container .thumbnail .arrows-container .prev:before, .uploader .thumbnails-container .thumbnail .arrows-container .next:after, .uploader .thumbnails-container .thumbnail .arrows-container .next:before {
        content: '';
        position: absolute;
        width: 50%;
        height: 50%;
        background-color: #FFF; }
      .uploader .thumbnails-container .thumbnail .arrows-container .prev, .uploader .thumbnails-container .thumbnail .arrows-container .next {
        position: absolute;
        display: block;
        width: 20px;
        height: 30px;
        cursor: pointer; }
      .uploader .thumbnails-container .thumbnail .arrows-container .prev {
        left: 10px; }
        .uploader .thumbnails-container .thumbnail .arrows-container .prev.disabled {
          display: none; }
        .uploader .thumbnails-container .thumbnail .arrows-container .prev:before {
          left: 30%;
          top: 0;
          transform: skew(145deg, 0deg);
          box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5); }
        .uploader .thumbnails-container .thumbnail .arrows-container .prev:after {
          left: 30%;
          top: 50%;
          transform: skew(-145deg, 0deg);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); }
      .uploader .thumbnails-container .thumbnail .arrows-container .next {
        right: 10px; }
        .uploader .thumbnails-container .thumbnail .arrows-container .next.disabled {
          display: none; }
        .uploader .thumbnails-container .thumbnail .arrows-container .next:before {
          right: 30%;
          top: 0;
          transform: skew(35deg, 0deg);
          box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5); }
        .uploader .thumbnails-container .thumbnail .arrows-container .next:after {
          right: 30%;
          top: 50%;
          transform: skew(-35deg, 0deg);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); }
    .uploader .thumbnails-container .thumbnail .del {
      position: relative;
      font-size: 24px;
      position: absolute;
      top: 0;
      right: 0;
      color: #d7d8d9; }
      .uploader .thumbnails-container .thumbnail .del:hover {
        cursor: pointer;
        color: #e74c3c; }
    .uploader .thumbnails-container .thumbnail .pick {
      position: relative;
      height: 80px;
      width: 80px;
      color: #d7d8d9;
      text-align: center;
      line-height: 80px;
      margin: auto;
      font-size: 24px; }
  .uploader .thumbnails-container .add {
    position: relative;
    background: transparent;
    border: #d7d8d9 3px dotted;
    color: #d7d8d9;
    border-radius: 5px;
    width: 80px;
    height: 80px;
    font-size: 34px; }
    .uploader .thumbnails-container .add:hover {
      border-color: #e74c3c;
      color: #e74c3c;
      cursor: pointer; }
    .uploader .thumbnails-container .add.dragover {
      cursor: pointer;
      border-color: #2ecc71;
      color: #2ecc71; }

.square {
  height: 80px;
  width: 80px; }

.uploader {
  height: 100%;
  width: 100%; }
  .uploader .thumbnails-container {
    position: relative; }
    .uploader .thumbnails-container .thumbnail {
      margin: 0 0 1px 0;
      position: relative;
      height: 80px;
      width: 80px;
      overflow: hidden;
      float: left;
      border-right: 1px solid #d7d8d9; }
      .uploader .thumbnails-container .thumbnail img, .uploader .thumbnails-container .thumbnail .img {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .uploader .thumbnails-container .thumbnail:hover .arrows-container {
        opacity: 1; }
      .uploader .thumbnails-container .thumbnail:after {
        clear: both; }
      .uploader .thumbnails-container .thumbnail:last-of-type {
        border-right: none;
        margin-right: 1px; }
      .uploader .thumbnails-container .thumbnail .arrows-container {
        opacity: 0.2;
        width: 100%;
        left: 0;
        top: 50%;
        margin-top: -15px;
        transition: opacity .5s;
        position: absolute; }
        .uploader .thumbnails-container .thumbnail .arrows-container .click {
          cursor: pointer; }
        .uploader .thumbnails-container .thumbnail .arrows-container .prev:after, .uploader .thumbnails-container .thumbnail .arrows-container .prev:before, .uploader .thumbnails-container .thumbnail .arrows-container .next:after, .uploader .thumbnails-container .thumbnail .arrows-container .next:before {
          content: '';
          position: absolute;
          width: 50%;
          height: 50%;
          background-color: #FFF; }
        .uploader .thumbnails-container .thumbnail .arrows-container .prev, .uploader .thumbnails-container .thumbnail .arrows-container .next {
          position: absolute;
          display: block;
          width: 20px;
          height: 30px;
          cursor: pointer; }
        .uploader .thumbnails-container .thumbnail .arrows-container .prev {
          left: 10px; }
          .uploader .thumbnails-container .thumbnail .arrows-container .prev.disabled {
            display: none; }
          .uploader .thumbnails-container .thumbnail .arrows-container .prev:before {
            left: 30%;
            top: 0;
            transform: skew(145deg, 0deg);
            box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5); }
          .uploader .thumbnails-container .thumbnail .arrows-container .prev:after {
            left: 30%;
            top: 50%;
            transform: skew(-145deg, 0deg);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); }
        .uploader .thumbnails-container .thumbnail .arrows-container .next {
          right: 10px; }
          .uploader .thumbnails-container .thumbnail .arrows-container .next.disabled {
            display: none; }
          .uploader .thumbnails-container .thumbnail .arrows-container .next:before {
            right: 30%;
            top: 0;
            transform: skew(35deg, 0deg);
            box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.5); }
          .uploader .thumbnails-container .thumbnail .arrows-container .next:after {
            right: 30%;
            top: 50%;
            transform: skew(-35deg, 0deg);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); }
      .uploader .thumbnails-container .thumbnail .del {
        position: relative;
        font-size: 24px;
        position: absolute;
        top: 0;
        right: 0;
        color: #d7d8d9; }
        .uploader .thumbnails-container .thumbnail .del:hover {
          cursor: pointer;
          color: #e74c3c; }
      .uploader .thumbnails-container .thumbnail .pick {
        position: relative;
        height: 80px;
        width: 80px;
        color: #d7d8d9;
        text-align: center;
        line-height: 80px;
        font-size: 24px; }
    .uploader .thumbnails-container .add {
      position: relative;
      background: transparent;
      border: #d7d8d9 3px dotted;
      color: #d7d8d9;
      border-radius: 5px;
      width: 80px;
      height: 80px;
      font-size: 34px; }
      .uploader .thumbnails-container .add:hover {
        border-color: #e74c3c;
        color: #e74c3c;
        cursor: pointer; }
      .uploader .thumbnails-container .add.dragover {
        cursor: pointer;
        border-color: #2ecc71;
        color: #2ecc71; }

.auth h3 {
  text-align: center;
  font-size: 18px;
  color: #e74c3c; }

.auth h2 {
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold; }

.auth p.bottom {
  margin-top: 10px;
  text-align: center; }
  @media (min-width: 768px) {
    .auth p.bottom.left {
      text-align: left; }
    .auth p.bottom.right {
      text-align: right; } }

.auth .btn {
  margin-top: 15px; }

.auth .terms {
  font-size: 12px;
  margin: 10px 0 5px 0; }

.auth .disabled .btn {
  cursor: not-allowed; }
  .auth .disabled .btn, .auth .disabled .btn:hover, .auth .disabled .btn:active, .auth .disabled .btn:focus {
    background-color: #f1b2ab;
    border-color: #f1b2ab; }

.auth a {
  color: #e74c3c !important;
  cursor: pointer; }
  .auth a:hover, .auth a:active, .auth a:focus {
    color: #e74c3c !important; }

.auth .collapse-step.ng-enter {
  transition: 0.5s;
  opacity: 0;
  max-height: 0px; }

.auth .collapse-step.ng-enter-active {
  opacity: 1;
  max-height: 200px; }

.auth .collapse-step.ng-leave {
  transition: 0.5s;
  opacity: 1;
  max-height: 200px; }

.auth .collapse-step.ng-leave-active {
  opacity: 0;
  max-height: 0px; }

.footer {
  min-height: 300px;
  padding: 50px 0 20px 0;
  color: white;
  text-align: center;
  background-color: #2b2d2e;
  background-image: url("/assets/images/bg/pattern.png"); }
  .footer a {
    color: white;
    text-decoration: none; }
    .footer a:hover, .footer a:active, .footer a:focus {
      outline: 0;
      color: #cbcccd; }
  .footer h5 {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px; }
    @media (min-width: 992px) {
      .footer h5 {
        text-align: left; } }
  .footer img {
    margin: 0 auto; }
  .footer .contacts {
    color: #555a5f;
    margin-top: 10px; }
  .footer .list {
    line-height: 1.45; }
    .footer .list li {
      margin-top: 15px;
      margin-bottom: 15px; }
    @media (min-width: 992px) {
      .footer .list {
        text-align: left; }
        .footer .list li {
          margin-top: 5px;
          margin-bottom: 5px; } }
  .footer hr {
    margin-top: 70px;
    border-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 30px; }
  .footer .copyright {
    color: #81888a; }
  .footer .dropdown select {
    background-color: #555a5f;
    color: white;
    border-radius: 2.5px;
    margin-top: 5px;
    border: 0;
    padding: 5px 0 5px 5px;
    font-weight: 700; }
    .footer .dropdown select option {
      padding: 5px 2.5px 5px 2.5px; }
      .footer .dropdown select option:disabled {
        color: #999999; }

.light-planner .placepicker, .light-planner .datetimepicker {
  margin: 0;
  display: flex; }

.light-planner .place-area {
  padding: 0; }

.light-planner .date-area {
  padding: 0;
  flex: 1 1 55%;
  min-width: 55%; }

.light-planner .time-area {
  padding-left: 10px;
  padding-right: 0;
  flex: 1 1 45%;
  min-width: 45%; }

.light-planner .placepicker-input, .light-planner .datepicker-input, .light-planner .timepicker-input {
  padding: 3px 15px 3px 15px;
  border: 1px solid #888888;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  color: #888888;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #fcfcfc;
  text-align: left; }

.light-planner .light-planner-step {
  margin: 10px 0;
  display: block;
  position: relative;
  box-sizing: border-box; }
  .light-planner .light-planner-step.removable {
    padding: 15px 5px 5px;
    border: 1px solid #BBBBBB;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
  .light-planner .light-planner-step .light-planner-remove-step {
    position: absolute;
    right: 2px;
    top: 2px;
    font-size: 16px;
    color: #444444; }
    .light-planner .light-planner-step .light-planner-remove-step:hover {
      color: #e74c3c; }
    .light-planner .light-planner-step .light-planner-remove-step:active {
      color: #444444; }
  .light-planner .light-planner-step .light-planner-add-step-block {
    display: flex;
    align-items: center;
    margin: 5px 0; }
    .light-planner .light-planner-step .light-planner-add-step-block .light-planner-add-step {
      font-size: 14px;
      padding: 4px 5px;
      border: 1px solid #BBBBBB;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #444444;
      text-align: center; }
      .light-planner .light-planner-step .light-planner-add-step-block .light-planner-add-step:hover {
        background-color: #EFEFEF;
        border-color: #e74c3c;
        color: #e74c3c; }
      .light-planner .light-planner-step .light-planner-add-step-block .light-planner-add-step:active {
        color: #444444;
        background-color: #ffffff;
        border-color: #BBBBBB; }
    .light-planner .light-planner-step .light-planner-add-step-block hr {
      margin: 0;
      flex: 1 1 auto;
      border-color: #BBBBBB; }

.light-planner .top .label {
  font-size: 12px; }

.modal h3.top-title {
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-top: 5px; }

.modal form {
  padding: 0 10px; }

body.sidebar-open {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden; }

.navbar {
  width: 100%;
  background: #24292e;
  color: white;
  border-bottom: #1e2327 1px solid; }
  .navbar nav {
    height: 60px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between; }
    .navbar nav a {
      text-decoration: none; }
      .navbar nav a:not(.btn), .navbar nav a:not(.btn):hover, .navbar nav a:not(.btn):active, .navbar nav a:not(.btn):focus {
        color: inherit; }
    .navbar nav .comp {
      height: 60px;
      position: relative;
      display: block; }
      .navbar nav .comp.pull-right {
        border-left: 1px solid;
        border-left-color: #1e2327; }
      .navbar nav .comp.pull-left {
        border-right: 1px solid;
        border-right-color: #1e2327; }
      .navbar nav .comp .notifications-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .navbar nav .comp .notifications-block .notifications-box {
          line-height: 60px;
          height: 100%;
          cursor: pointer;
          padding: 0 15px;
          font-size: 26px; }
          .navbar nav .comp .notifications-block .notifications-box:hover {
            cursor: pointer;
            background: #21262a; }
          .navbar nav .comp .notifications-block .notifications-box .notifications {
            position: absolute;
            left: -10px;
            bottom: -10px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 50%;
            background-color: #ff4081;
            color: #ffffff;
            font-weight: 600;
            font-size: 12px;
            z-index: 2;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
        .navbar nav .comp .notifications-block:hover .notification-dropdown {
          display: block; }
        .navbar nav .comp .notifications-block .notification-dropdown {
          display: none;
          position: absolute;
          z-index: 3001;
          top: 60px;
          left: auto;
          right: -1px;
          min-width: 282px;
          max-width: 460px;
          line-height: normal;
          border-radius: 0 0 2.5px 2.5px;
          border: 1px solid;
          background: #24292e;
          border-color: #1e2327; }
          .navbar nav .comp .notifications-block .notification-dropdown .notification-item {
            line-height: normal;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            padding: 10px; }
            .navbar nav .comp .notifications-block .notification-dropdown .notification-item .notification-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 5px 0; }
              .navbar nav .comp .notifications-block .notification-dropdown .notification-item .notification-header .notification-date {
                font-size: 10px; }
            .navbar nav .comp .notifications-block .notification-dropdown .notification-item .notification-text {
              font-size: 12px; }
            .navbar nav .comp .notifications-block .notification-dropdown .notification-item .notification-actions {
              line-height: normal;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 10px;
              padding: 5px 0;
              color: #e74c3c; }
              .navbar nav .comp .notifications-block .notification-dropdown .notification-item .notification-actions a {
                line-height: normal;
                padding: 0;
                margin: 0; }
                .navbar nav .comp .notifications-block .notification-dropdown .notification-item .notification-actions a:hover {
                  font-weight: 600; }
          .navbar nav .comp .notifications-block .notification-dropdown .notifications-actions {
            line-height: normal;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 15px 10px;
            font-size: 10px;
            color: #e74c3c; }
            .navbar nav .comp .notifications-block .notification-dropdown .notifications-actions a {
              line-height: normal;
              padding: 0;
              margin: 0; }
              .navbar nav .comp .notifications-block .notification-dropdown .notifications-actions a:hover {
                font-weight: 600; }
      .navbar nav .comp a, .navbar nav .comp p {
        display: block; }
        .navbar nav .comp a:not(.btn), .navbar nav .comp p:not(.btn) {
          line-height: 40px;
          height: 100%;
          cursor: pointer;
          padding: 0 10px; }
        .navbar nav .comp a.btn-md, .navbar nav .comp p.btn-md {
          margin: 12px; }
      .navbar nav .comp .dropdown-block {
        border-left: 1px solid;
        border-left-color: #1e2327;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 10px; }
        .navbar nav .comp .dropdown-block:hover .dropdown {
          display: block; }
        .navbar nav .comp .dropdown-block:hover {
          cursor: pointer;
          background: #21262a; }
        .navbar nav .comp .dropdown-block .dropdown {
          display: none;
          position: absolute;
          z-index: 3001;
          top: 60px;
          left: auto;
          right: -1px;
          min-width: 282px;
          max-width: 460px;
          line-height: normal;
          border-radius: 0 0 2.5px 2.5px;
          border: 1px solid;
          background: #24292e;
          border-color: #1e2327; }
          .navbar nav .comp .dropdown-block .dropdown .item {
            display: block;
            position: relative;
            margin: 0;
            border-top: 1px solid;
            border-color: #1e2327; }
            .navbar nav .comp .dropdown-block .dropdown .item:first-child {
              border-top: none; }
            .navbar nav .comp .dropdown-block .dropdown .item:hover {
              cursor: pointer;
              background: #21262a; }
    .navbar nav .brand {
      position: relative;
      height: 60px;
      padding: 5px 10px;
      color: white !important; }
      .navbar nav .brand .mark {
        margin: 0 5px;
        background-image: url("/assets/images/brand/busrapido-icon.png");
        background-size: cover;
        background-position: center center;
        width: 50px;
        height: 50px;
        float: left; }
      .navbar nav .brand .busrapido {
        float: left;
        margin: 0 5px; }
        .navbar nav .brand .busrapido .logo {
          margin-top: 3px;
          margin-bottom: 1px;
          font-size: 32px;
          line-height: 32px; }
          .navbar nav .brand .busrapido .logo strong {
            font-weight: bold; }
        .navbar nav .brand .busrapido .payoff {
          margin-left: -21px;
          margin-top: 3px;
          line-height: 11px;
          font-size: 11px;
          text-align: right; }
    .navbar nav .side {
      display: inline; }
      .navbar nav .side .toggle {
        display: inline;
        float: right;
        line-height: 59px;
        padding-right: 15px;
        color: #555a5f;
        font-size: 18px; }
        @media (min-width: 768px) {
          .navbar nav .side .toggle {
            display: none; } }
        .navbar nav .side .toggle li {
          float: left;
          display: inline; }
      .navbar nav .side .bar {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2001; }
        @media (min-width: 768px) {
          .navbar nav .side .bar {
            display: none !important; } }
        .navbar nav .side .bar .mask {
          opacity: .6;
          position: absolute;
          cursor: pointer;
          height: 100%;
          width: 100%;
          background-color: #000;
          transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
        .navbar nav .side .bar .content {
          right: 0;
          position: absolute;
          width: 285px;
          height: 100%;
          border: 0;
          background-color: #2b2d2e;
          background-image: url("/assets/images/bg/pattern.png"); }
          .navbar nav .side .bar .content .header {
            height: 48px;
            border-bottom: #1e2327 1px solid; }
            .navbar nav .side .bar .content .header p, .navbar nav .side .bar .content .header a {
              font-size: 16px;
              line-height: 28px;
              padding: 10px 10px 10px 5px;
              color: white;
              font-weight: bold;
              display: block;
              float: left; }
            .navbar nav .side .bar .content .header img {
              height: 38px;
              width: 38px;
              margin: 5px;
              display: block;
              float: left; }
          .navbar nav .side .bar .content .wrapper {
            height: calc(100% - 48px);
            overflow-x: hidden;
            overflow-y: auto;
            padding: 20px; }
            .navbar nav .side .bar .content .wrapper > ul {
              margin-bottom: 34px; }
              .navbar nav .side .bar .content .wrapper > ul:last-child {
                margin-bottom: 0; }
              .navbar nav .side .bar .content .wrapper > ul > li {
                color: white;
                font-size: 20px;
                line-height: 46px; }
                .navbar nav .side .bar .content .wrapper > ul > li:hover, .navbar nav .side .bar .content .wrapper > ul > li:active, .navbar nav .side .bar .content .wrapper > ul > li:focus {
                  color: #e74c3c; }
        .navbar nav .side .bar.visible {
          display: block; }

br-planner {
  display: block; }

.planner {
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  color: #555a5f; }
  .planner .rel {
    position: relative; }
  .planner .to-start {
    position: absolute;
    font-size: 12px;
    white-space: normal;
    padding: 0 5px;
    height: 37px;
    overflow: hidden;
    bottom: 0px; }
  .planner form {
    width: 100%;
    height: 100%; }
  .planner tooltip tip {
    min-width: 250px; }
  .planner .collapse-step.ng-enter {
    transition: 0.5s;
    opacity: 0;
    max-height: 0px; }
  .planner .collapse-step.ng-enter-active {
    opacity: 1;
    max-height: 200px; }
  .planner .collapse-step.ng-leave {
    transition: 0.5s;
    opacity: 1;
    max-height: 200px; }
  .planner .collapse-step.ng-leave-active {
    opacity: 0;
    max-height: 0px; }
  .planner .add, .planner .del {
    line-height: 26px;
    margin-top: 5px;
    cursor: pointer; }
  .planner .trip-steps-dot.last {
    cursor: pointer; }
  .planner .trip-steps-dot.disabled {
    cursor: not-allowed; }
  .planner .disabled {
    color: #81888a;
    cursor: not-allowed; }
    .planner .disabled .btn {
      cursor: not-allowed; }
      .planner .disabled .btn, .planner .disabled .btn:hover, .planner .disabled .btn:active, .planner .disabled .btn:focus {
        cursor: not-allowed;
        background-color: #f1b2ab;
        border-color: #f1b2ab; }
    .planner .disabled .add {
      cursor: not-allowed; }
  .planner ul {
    background-color: inherit; }
  .planner .peoples {
    background-color: inherit;
    position: relative;
    padding: 0px 10px 10px 41px; }
    .planner .peoples button {
      margin-top: 29px; }
    .planner .peoples .side-icon {
      position: absolute;
      background-color: #e74c3c;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      left: 7px;
      top: -1px; }
      .planner .peoples .side-icon .icon {
        position: absolute;
        left: 3px;
        line-height: 18px;
        color: white; }
    .planner .peoples label .top {
      padding: 0 0 5px 0; }

.br-planner-topic {
  display: block;
  /**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
  /**
* The dndDraggingSource class will be applied to
* the source element of a drag operation. It makes
* sense to hide it to give the user the feeling
* that he's actually moving it.
*/
  /**
* An element with .dndPlaceholder class will be
* added to the dnd-list while the user is dragging
* over it.
*/
  padding: 10px;
  padding-bottom: 48px;
  margin: 0 auto;
  width: 100%;
  color: #555a5f; }
  .br-planner-topic ul[dnd-list] {
    min-height: 42px;
    padding-left: 0px; }
  .br-planner-topic ul[dnd-list] .dndDraggingSource {
    display: none; }
  .br-planner-topic ul[dnd-list] .dndPlaceholder {
    background-color: #ddd;
    display: block;
    min-height: 42px; }
  .br-planner-topic .trip-steps {
    padding-bottom: 20px; }
  .br-planner-topic .rel {
    position: relative; }
  .br-planner-topic .to-start {
    position: absolute;
    font-size: 12px;
    white-space: normal;
    padding: 0 5px;
    height: 37px;
    overflow: hidden;
    bottom: 0px; }
  .br-planner-topic form {
    width: 100%;
    height: 100%; }
  .br-planner-topic tooltip tip {
    min-width: 250px; }
  .br-planner-topic .collapse-step.ng-enter {
    transition: 0.5s;
    opacity: 0;
    max-height: 0px; }
  .br-planner-topic .collapse-step.ng-enter-active {
    opacity: 1;
    max-height: 200px; }
  .br-planner-topic .collapse-step.ng-leave {
    transition: 0.5s;
    opacity: 1;
    max-height: 200px; }
  .br-planner-topic .collapse-step.ng-leave-active {
    opacity: 0;
    max-height: 0px; }
  .br-planner-topic .add,
  .br-planner-topic .del {
    line-height: 26px;
    margin-top: 5px;
    cursor: pointer; }
  .br-planner-topic .trip-steps-dot.last {
    cursor: pointer; }
  .br-planner-topic .trip-steps-dot.disabled {
    cursor: not-allowed; }
  .br-planner-topic .disabled {
    color: #81888a;
    cursor: not-allowed; }
    .br-planner-topic .disabled .btn {
      cursor: not-allowed; }
      .br-planner-topic .disabled .btn, .br-planner-topic .disabled .btn:hover, .br-planner-topic .disabled .btn:active, .br-planner-topic .disabled .btn:focus {
        cursor: not-allowed;
        background-color: #f1b2ab;
        border-color: #f1b2ab; }
    .br-planner-topic .disabled .add {
      cursor: not-allowed; }
  .br-planner-topic ul {
    background-color: inherit; }
  .br-planner-topic .peoples {
    background-color: inherit;
    position: relative;
    padding: 0px 10px 10px 41px; }
    .br-planner-topic .peoples button {
      margin-top: 29px; }
    .br-planner-topic .peoples .side-icon {
      position: absolute;
      background-color: #e74c3c;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      left: 7px;
      top: -1px; }
      .br-planner-topic .peoples .side-icon .icon {
        position: absolute;
        left: 3px;
        line-height: 18px;
        color: white; }
    .br-planner-topic .peoples label .top {
      padding: 0 0 5px 0; }
  .br-planner-topic .white {
    color: white; }
  .br-planner-topic .low {
    z-index: 0; }
  .br-planner-topic .nav {
    height: 60px;
    width: 100%;
    position: relative;
    display: block; }
    .br-planner-topic .nav .pull-left {
      float: left; }
    .br-planner-topic .nav .comp:hover:not(.no-hover),
    .br-planner-topic .nav .dropdown .item:hover:not(.no-hover) {
      cursor: pointer; }
    .br-planner-topic .nav .comp {
      height: 60px;
      position: relative;
      display: block; }
      .br-planner-topic .nav .comp a,
      .br-planner-topic .nav .comp p {
        display: block; }
        .br-planner-topic .nav .comp a:not(.btn),
        .br-planner-topic .nav .comp p:not(.btn) {
          line-height: 40px;
          height: 100%;
          cursor: pointer;
          padding: 0 15px; }
        .br-planner-topic .nav .comp a.btn-md,
        .br-planner-topic .nav .comp p.btn-md {
          margin: 12px; }
      .br-planner-topic .nav .comp .dropdown {
        position: absolute;
        z-index: 3001;
        top: -60px;
        left: 30px;
        right: auto;
        min-width: 282px;
        max-width: 460px;
        max-height: 200px;
        overflow-y: auto;
        line-height: normal;
        border-radius: 0 0 2.5px 2.5px;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
        background: white; }
        .br-planner-topic .nav .comp .dropdown .item {
          display: block;
          color: black;
          position: relative;
          margin: 0; }
          .br-planner-topic .nav .comp .dropdown .item:first-child {
            border-top: none; }
          .br-planner-topic .nav .comp .dropdown .item :hover {
            background: rgba(0, 0, 0, 0.1); }
  .br-planner-topic .dot {
    border-radius: 50%;
    color: white;
    margin: 4px;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 20px; }
    .br-planner-topic .dot .icon {
      text-align: center;
      width: 100%; }
  .br-planner-topic .dot.enabled {
    background: #e74c3c; }
  .br-planner-topic .dot.disabled {
    background: gray;
    color: white; }

.wrapper.bus {
  padding-left: 40px; }
  .wrapper.bus .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .wrapper.bus .row {
    display: flex;
    align-items: center; }

.wrapper.city {
  padding-left: 40px; }
  .wrapper.city .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .wrapper.city .row {
    display: flex;
    align-items: center; }

.wrapper.disposal {
  padding-left: 40px; }
  .wrapper.disposal .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .wrapper.disposal .row {
    display: flex;
    align-items: center; }

.wrapper.end {
  border-bottom: 2px solid #e74c3c; }
  .wrapper.end .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .wrapper.end .row {
    display: flex;
    align-items: center;
    min-height: 40px; }

.wrapper.pickup .type {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold; }

.wrapper.pickup .row {
  display: flex;
  align-items: center; }

.wrapper.pickup .align-left {
  margin-left: 10px; }

.wrapper.pickup .row.place {
  padding-left: 40px; }

.wrapper.return {
  padding-left: 40px; }
  .wrapper.return .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .wrapper.return .row {
    display: flex;
    align-items: center; }

.wrapper.shuttle {
  padding-left: 40px; }
  .wrapper.shuttle .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .wrapper.shuttle .row {
    display: flex;
    align-items: center; }

.transfer.wrapper {
  padding-left: 40px; }
  .transfer.wrapper .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .transfer.wrapper .row {
    display: flex;
    align-items: center; }

.transit.wrapper {
  padding-left: 40px; }
  .transit.wrapper .type {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold; }
  .transit.wrapper .row {
    display: flex;
    align-items: center; }

br-planner {
  display: block; }

.planner {
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  color: #555a5f; }
  .planner .rel {
    position: relative; }
  .planner .to-start {
    position: absolute;
    font-size: 12px;
    white-space: normal;
    padding: 0 5px;
    height: 37px;
    overflow: hidden;
    bottom: 0px; }
  .planner form {
    width: 100%;
    height: 100%; }
  .planner tooltip tip {
    min-width: 250px; }
  .planner .collapse-step.ng-enter {
    transition: 0.5s;
    opacity: 0;
    max-height: 0px; }
  .planner .collapse-step.ng-enter-active {
    opacity: 1;
    max-height: 200px; }
  .planner .collapse-step.ng-leave {
    transition: 0.5s;
    opacity: 1;
    max-height: 200px; }
  .planner .collapse-step.ng-leave-active {
    opacity: 0;
    max-height: 0px; }
  .planner .add, .planner .del {
    line-height: 26px;
    margin-top: 5px;
    cursor: pointer; }
  .planner .trip-steps-dot.last {
    cursor: pointer; }
  .planner .trip-steps-dot.disabled {
    cursor: not-allowed; }
  .planner .disabled {
    color: #81888a;
    cursor: not-allowed; }
    .planner .disabled .btn {
      cursor: not-allowed; }
      .planner .disabled .btn, .planner .disabled .btn:hover, .planner .disabled .btn:active, .planner .disabled .btn:focus {
        cursor: not-allowed;
        background-color: #f1b2ab;
        border-color: #f1b2ab; }
    .planner .disabled .add {
      cursor: not-allowed; }
  .planner ul {
    background-color: inherit; }
  .planner .peoples {
    background-color: inherit;
    position: relative;
    padding: 0px 10px 10px 41px; }
    .planner .peoples button {
      margin-top: 29px; }
    .planner .peoples .side-icon {
      position: absolute;
      background-color: #e74c3c;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      left: 7px;
      top: -1px; }
      .planner .peoples .side-icon .icon {
        position: absolute;
        left: 3px;
        line-height: 18px;
        color: white; }
    .planner .peoples label .top {
      padding: 0 0 5px 0; }

.header-accent {
  border-top: 2px solid #e74c3c !important;
  border-bottom: 0px solid #e74c3c !important; }

br-planner {
  display: block; }

.planner {
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  color: #555a5f; }
  .planner .rel {
    position: relative; }
  .planner .to-start {
    position: absolute;
    font-size: 12px;
    white-space: normal;
    padding: 0 5px;
    height: 37px;
    overflow: hidden;
    bottom: 0px; }
  .planner form {
    width: 100%;
    height: 100%; }
  .planner tooltip tip {
    min-width: 250px; }
  .planner .collapse-step.ng-enter {
    transition: 0.5s;
    opacity: 0;
    max-height: 0px; }
  .planner .collapse-step.ng-enter-active {
    opacity: 1;
    max-height: 200px; }
  .planner .collapse-step.ng-leave {
    transition: 0.5s;
    opacity: 1;
    max-height: 200px; }
  .planner .collapse-step.ng-leave-active {
    opacity: 0;
    max-height: 0px; }
  .planner .add, .planner .del {
    line-height: 26px;
    margin-top: 5px;
    cursor: pointer; }
  .planner .trip-steps-dot.last {
    cursor: pointer; }
  .planner .trip-steps-dot.disabled {
    cursor: not-allowed; }
  .planner .disabled {
    color: #81888a;
    cursor: not-allowed; }
    .planner .disabled .btn {
      cursor: not-allowed; }
      .planner .disabled .btn, .planner .disabled .btn:hover, .planner .disabled .btn:active, .planner .disabled .btn:focus {
        cursor: not-allowed;
        background-color: #f1b2ab;
        border-color: #f1b2ab; }
    .planner .disabled .add {
      cursor: not-allowed; }
  .planner ul {
    background-color: inherit; }
  .planner .peoples {
    background-color: inherit;
    position: relative;
    padding: 0px 10px 10px 41px; }
    .planner .peoples button {
      margin-top: 29px; }
    .planner .peoples .side-icon {
      position: absolute;
      background-color: #e74c3c;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      left: 7px;
      top: -1px; }
      .planner .peoples .side-icon .icon {
        position: absolute;
        left: 3px;
        line-height: 18px;
        color: white; }
    .planner .peoples label .top {
      padding: 0 0 5px 0; }

.main-trips {
  min-height: calc(100vh - (307px + 61px)); }

.heart-red {
  color: #FF4081; }

.main-checkout .evidence-content {
  padding-top: 15px;
  padding-bottom: 65px;
  background-color: white;
  border-bottom: #d7d8d9 1px solid;
  border-top: #d7d8d9 1px solid; }
  .main-checkout .evidence-content .trip-steps {
    margin-top: 5px;
    margin-bottom: 5px; }
  .main-checkout .evidence-content.top {
    border-top: none; }

.main-checkout .overlappping-content {
  margin-top: -68px; }

.main-checkout .company-description h1 {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold; }

.main-checkout .company-description .small {
  font-size: 18px;
  font-weight: normal; }

.main-checkout .company-description.disabled {
  text-decoration: line-through;
  color: #81888a; }

.main-checkout .trip-details h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: #d7d8d9 1px solid; }

.main-checkout .trip-details .angular-google-map-container {
  margin-top: 20px;
  border: #d7d8d9 1px solid !important;
  height: 350px; }

.main-checkout .prevnext button {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .main-checkout .prevnext button {
      margin-bottom: 0px; } }

.main-checkout .vehicle-details {
  padding-top: 15px;
  padding-bottom: 15px; }
  .main-checkout .vehicle-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .separator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .items {
    line-height: 2.5; }
    .main-checkout .vehicle-details .items b {
      font-weight: bold; }
  .main-checkout .vehicle-details .services {
    color: #81888a;
    display: inline;
    text-decoration: line-through; }
    .main-checkout .vehicle-details .services .icon {
      display: none; }
    .main-checkout .vehicle-details .services span {
      color: #81888a;
      line-height: 2.5; }
    .main-checkout .vehicle-details .services tip {
      width: 250px; }
    .main-checkout .vehicle-details .services.active {
      color: #555a5f;
      font-weight: bold;
      text-decoration: none; }
      .main-checkout .vehicle-details .services.active .icon {
        font-size: 24px;
        display: inline;
        margin-right: 5px; }
        .main-checkout .vehicle-details .services.active .icon.tooltips {
          font-size: 14px; }

.main-order .active-container {
  height: 350px; }
  .main-order .active-container img {
    max-height: 350px; }

.main-payment {
  min-height: calc(100vh - 450px); }
  .main-payment .content-gallery,
  .main-payment .content-gallery img {
    height: 200px; }
  .main-payment .evidence-content {
    padding-top: 15px;
    padding-bottom: 65px;
    background-color: white;
    border-bottom: #d7d8d9 1px solid;
    border-top: #d7d8d9 1px solid; }
    .main-payment .evidence-content.top {
      border-top: none; }
  .main-payment .overlappping-content {
    margin-top: -68px;
    margin-bottom: 20px; }
  .main-payment .section-description h1 {
    margin-top: 5px;
    font-size: 24px; }
  .main-payment .terms {
    font-size: 12px;
    margin: 5px 0 5px 0; }

.container-full {
  width: 100%;
  padding: 15px; }

.container-xl {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.table-container {
  overflow-x: auto; }

.ng-table-group-header {
  display: none; }

.quotes .dropdown {
  position: absolute;
  z-index: 3001;
  top: 0px;
  left: 0px;
  min-width: 282px;
  max-width: 460px;
  line-height: normal;
  border-radius: 0 0 2.5px 2.5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  background: white; }
  .quotes .dropdown .item {
    display: block;
    color: black;
    position: relative;
    margin: 0; }
    .quotes .dropdown .item:first-child {
      border-top: none; }
    .quotes .dropdown .item :hover {
      background: rgba(0, 0, 0, 0.1); }

.dropdown-container {
  position: relative; }
  .dropdown-container .dropdown-wrapper {
    overflow: hidden; }

.icon-star {
  color: #f5ab35; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.email {
  /* add generic styling for the editor */
  /* add the styling for the awesomness of the resizer */ }
  .email .ta-root.focussed > .ta-scroll-window.form-control {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .email .ta-editor.ta-html, .email .ta-scroll-window.form-control {
    min-height: 300px;
    height: auto;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
    width: 100%; }
  .email .ta-scroll-window.form-control {
    position: relative;
    padding: 0; }
  .email .ta-scroll-window > .ta-bind {
    height: auto;
    min-height: 300px;
    padding: 6px 12px; }
  .email .ta-editor:focus {
    user-select: text; }
  .email .ta-resizer-handle-overlay {
    z-index: 100;
    position: absolute;
    display: none; }
  .email .ta-resizer-handle-overlay > .ta-resizer-handle-info {
    position: absolute;
    bottom: 16px;
    right: 16px;
    border: 1px solid black;
    background-color: #FFF;
    padding: 0 4px;
    opacity: 0.7; }
  .email .ta-resizer-handle-overlay > .ta-resizer-handle-background {
    position: absolute;
    bottom: 5px;
    right: 5px;
    left: 5px;
    top: 5px;
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.2); }
  .email .ta-resizer-handle-overlay > .ta-resizer-handle-corner {
    width: 10px;
    height: 10px;
    position: absolute; }
  .email .ta-resizer-handle-overlay > .ta-resizer-handle-corner-tl {
    top: 0;
    left: 0;
    border-left: 1px solid black;
    border-top: 1px solid black; }
  .email .ta-resizer-handle-overlay > .ta-resizer-handle-corner-tr {
    top: 0;
    right: 0;
    border-right: 1px solid black;
    border-top: 1px solid black; }
  .email .ta-resizer-handle-overlay > .ta-resizer-handle-corner-bl {
    bottom: 0;
    left: 0;
    border-left: 1px solid black;
    border-bottom: 1px solid black; }
  .email .ta-resizer-handle-overlay > .ta-resizer-handle-corner-br {
    bottom: 0;
    right: 0;
    border: 1px solid black;
    cursor: se-resize;
    background-color: white; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }
  .main-results .offset {
    margin-top: 30px; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-trips {
  min-height: calc(100vh - (307px + 61px)); }

.main-checkout .evidence-content {
  padding-top: 15px;
  padding-bottom: 65px;
  background-color: white;
  border-bottom: #d7d8d9 1px solid;
  border-top: #d7d8d9 1px solid; }
  .main-checkout .evidence-content .trip-steps {
    margin-top: 5px;
    margin-bottom: 5px; }
  .main-checkout .evidence-content.top {
    border-top: none; }

.main-checkout .overlappping-content {
  margin-top: -68px; }

.main-checkout .company-description h1 {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold; }

.main-checkout .company-description .small {
  font-size: 18px;
  font-weight: normal; }

.main-checkout .company-description.disabled {
  text-decoration: line-through;
  color: #81888a; }

.main-checkout .trip-details h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: #d7d8d9 1px solid; }

.main-checkout .trip-details .angular-google-map-container {
  margin-top: 20px;
  border: #d7d8d9 1px solid !important;
  height: 350px; }

.main-checkout .prevnext button {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .main-checkout .prevnext button {
      margin-bottom: 0px; } }

.main-checkout .vehicle-details {
  padding-top: 15px;
  padding-bottom: 15px; }
  .main-checkout .vehicle-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .separator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .items {
    line-height: 2.5; }
    .main-checkout .vehicle-details .items b {
      font-weight: bold; }
  .main-checkout .vehicle-details .services {
    color: #81888a;
    display: inline;
    text-decoration: line-through; }
    .main-checkout .vehicle-details .services .icon {
      display: none; }
    .main-checkout .vehicle-details .services span {
      color: #81888a;
      line-height: 2.5; }
    .main-checkout .vehicle-details .services tip {
      width: 250px; }
    .main-checkout .vehicle-details .services.active {
      color: #555a5f;
      font-weight: bold;
      text-decoration: none; }
      .main-checkout .vehicle-details .services.active .icon {
        font-size: 24px;
        display: inline;
        margin-right: 5px; }
        .main-checkout .vehicle-details .services.active .icon.tooltips {
          font-size: 14px; }

.main-order .active-container {
  height: 350px; }
  .main-order .active-container img {
    max-height: 350px; }

.main-payment {
  min-height: calc(100vh - 450px); }
  .main-payment .content-gallery,
  .main-payment .content-gallery img {
    height: 200px; }
  .main-payment .evidence-content {
    padding-top: 15px;
    padding-bottom: 65px;
    background-color: white;
    border-bottom: #d7d8d9 1px solid;
    border-top: #d7d8d9 1px solid; }
    .main-payment .evidence-content.top {
      border-top: none; }
  .main-payment .overlappping-content {
    margin-top: -68px;
    margin-bottom: 20px; }
  .main-payment .section-description h1 {
    margin-top: 5px;
    font-size: 24px; }
  .main-payment .terms {
    font-size: 12px;
    margin: 5px 0 5px 0; }

.container-full {
  width: 100%;
  padding: 15px; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }
  .main-results .offset {
    margin-top: 30px; }
  .main-results .cc-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }
    .main-results .cc-box .top {
      margin-right: 10px; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.modal h3.top-title {
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-top: 5px; }

.modal p.message {
  text-align: center;
  font-size: 16px;
  padding: 25px 20px; }

.modal h2 {
  font-size: 18px; }

.modal h3.top-title {
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-top: 5px; }

.modal p.message {
  text-align: center;
  font-size: 16px;
  padding: 25px 20px; }

.modal h2 {
  font-size: 18px; }

.main-book .book .content-map .angular-google-map-container {
  min-height: calc(100vh - 270px) !important;
  min-width: 100%; }

.main-book .book .plan {
  background-color: #ededed; }
  .main-book .book .plan .planner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }

.breadcumb-container.breadcumb-order {
  height: 0; }

.planner {
  padding-bottom: 64px; }

.main-book .book .content-map .angular-google-map-container {
  min-height: calc(100vh - 270px) !important;
  min-width: 100%; }

.main-book .book .plan {
  background-color: #ededed; }
  .main-book .book .plan .planner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }

.dot {
  border-radius: 50%;
  color: white;
  margin: 4px;
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 20px; }
  .dot .icon {
    text-align: center;
    width: 100%; }

.dot.enabled {
  background: #e74c3c; }

.dot.disabled {
  background: gray;
  color: white; }

.dot:hover {
  background: #ff3a24; }

.margin {
  line-height: 128px; }

.breadcumb-container.breadcumb-order {
  height: 0; }

.planner {
  padding-bottom: 64px; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-results {
  padding-bottom: 20px; }
  .main-results .msg {
    text-align: center;
    font-size: 16px;
    line-height: 1.5; }
  .main-results .center-icon {
    text-align: center; }
    .main-results .center-icon .icon {
      font-size: 18px; }
  .main-results .limit-container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center; }
    .main-results .limit-container .icon {
      cursor: pointer;
      font-size: 56px;
      color: #f1b2ab; }
      .main-results .limit-container .icon:hover, .main-results .limit-container .icon:active, .main-results .limit-container .icon:focus {
        color: #e74c3c; }

.breadcumb-container.breadcumb-order .breadcumb-step {
  width: 14.28571428571428%; }

.main-collaborators .collaborators-tabs {
  position: relative;
  display: flex;
  min-height: 40px;
  align-items: stretch;
  justify-content: flex-start;
  padding: 20px 0 0 0; }
  .main-collaborators .collaborators-tabs .collaborators-tab {
    position: relative;
    min-width: 150px;
    color: #888888;
    background-color: #f4f4f4;
    border: 1px solid #888888;
    text-align: center;
    padding: 10px;
    z-index: 0; }
    .main-collaborators .collaborators-tabs .collaborators-tab.active {
      z-index: 2;
      color: #222222;
      background-color: #FFFFFF;
      border-color: #e74c3c;
      border-bottom: none;
      box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.08), 3px -3px 3px rgba(0, 0, 0, 0.13); }

.main-collaborators .collaborators-section {
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  min-height: 300px;
  border: 1px solid #e74c3c;
  margin-top: -1px;
  padding: 20px;
  margin-bottom: 40px;
  box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.08), 3px -3px 3px rgba(0, 0, 0, 0.13); }
  .main-collaborators .collaborators-section .collaborators-section-actions {
    display: flex;
    justify-content: flex-end; }
    .main-collaborators .collaborators-section .collaborators-section-actions .collaborators-section-action {
      border: 1px solid #e74c3c;
      border-radius: 5px;
      color: #e74c3c;
      font-weight: 600;
      padding: 8px 12px; }
  .main-collaborators .collaborators-section .collaborator-insert-container {
    position: relative;
    max-width: 350px;
    background-color: #fcfcfc;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: 0;
    margin: 10px 0; }
    .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-delete {
      position: absolute;
      right: 5px;
      top: 3px;
      font-weight: 800; }
    .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 20px; }
      .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert .collaborator-field {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert .collaborator-field .collaborator-input-field {
          width: 170px;
          padding: 3px 5px;
          border: 1px solid #888888;
          -webkit-border-radius: 3px;
          border-radius: 3px;
          font-size: 12px;
          color: #888888;
          -o-text-overflow: clip;
          text-overflow: clip;
          background: #fcfcfc;
          text-align: left;
          margin: 5px 0; }
          .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert .collaborator-field .collaborator-input-field.short {
            width: 150px;
            margin-right: 5px; }
        .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert .collaborator-field .collaborator-role-list {
          width: 170px;
          cursor: pointer;
          padding: 3px 15px 3px 15px;
          outline: 0;
          font-size: 12px;
          border: 1px solid #888888;
          border-radius: 3px;
          background: #FFFFFF;
          color: #888888;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          margin: 5px 0; }
          .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert .collaborator-field .collaborator-role-list::-ms-expand {
            display: none; }
          .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert .collaborator-field .collaborator-role-list:disabled {
            opacity: 0.5;
            pointer-events: none; }
        .main-collaborators .collaborators-section .collaborator-insert-container .collaborator-insert .collaborator-field .collaborator-role-list-arrow {
          margin-left: -20px;
          color: #888888;
          pointer-events: none; }
  .main-collaborators .collaborators-section .collaborators-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 20px 0;
    flex-wrap: wrap; }
    .main-collaborators .collaborators-section .collaborators-container .collaborator-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      background-color: #fcfcfc;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      border: 0; }
      .main-collaborators .collaborators-section .collaborators-container .collaborator-box.collaborator-admin {
        border-top: 4px solid #ff3a24; }
      .main-collaborators .collaborators-section .collaborators-container .collaborator-box.collaborator-collaborator {
        border-top: 4px solid #f5ab35; }
      .main-collaborators .collaborators-section .collaborators-container .collaborator-box.collaborator-reader {
        border-top: 4px solid #2ecc71; }
      .main-collaborators .collaborators-section .collaborators-container .collaborator-box .collaborator-delete {
        position: absolute;
        right: 5px;
        top: 3px;
        font-weight: 800; }
      .main-collaborators .collaborators-section .collaborators-container .collaborator-box .collaborator-name {
        line-height: 25px;
        vertical-align: middle;
        font-weight: 500; }
  .main-collaborators .collaborators-section .warn {
    background-color: #f5e3c7;
    border: 1px solid #f0c495;
    border-radius: 2.5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px; }

.main-collaborators .collaborators-section-role-insert {
  position: relative;
  max-width: 350px;
  background-color: #fcfcfc;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 0;
  margin: 10px 0; }
  .main-collaborators .collaborators-section-role-insert .role-delete {
    position: absolute;
    right: 5px;
    top: 3px;
    font-weight: 800; }
  .main-collaborators .collaborators-section-role-insert .role-field {
    display: flex; }

.main-collaborators .collaborators-section-roles-container .collaborators-section-role-box {
  display: flex;
  background-color: #fcfcfc;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 0;
  margin: 10px 0; }
  .main-collaborators .collaborators-section-roles-container .collaborators-section-role-box .role-name {
    flex: 0 0 300px; }
  .main-collaborators .collaborators-section-roles-container .collaborators-section-role-box .role-permissions {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); }
    .main-collaborators .collaborators-section-roles-container .collaborators-section-role-box .role-permissions .role-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      line-height: 20px; }

.main-signin .auth {
  max-width: 650px;
  margin: 20px auto; }
  .main-signin .auth h3.top-title {
    font-size: 18px;
    color: #e74c3c;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding-top: 10px;
    padding-top: 5px; }

.main-profile {
  margin-bottom: 20px;
  min-height: calc(100vh - (307px + 61px + 20px)); }

.auth h3 {
  text-align: center;
  font-size: 18px;
  color: #e74c3c; }

.auth h2 {
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold; }

.auth p.bottom {
  margin-top: 10px;
  text-align: center; }
  @media (min-width: 768px) {
    .auth p.bottom.left {
      text-align: left; }
    .auth p.bottom.right {
      text-align: right; } }

.auth .btn {
  margin-top: 15px; }

.auth .terms {
  font-size: 12px;
  margin: 10px 0 5px 0; }

.auth .disabled .btn {
  cursor: not-allowed; }
  .auth .disabled .btn, .auth .disabled .btn:hover, .auth .disabled .btn:active, .auth .disabled .btn:focus {
    background-color: #f1b2ab;
    border-color: #f1b2ab; }

.auth a {
  color: #e74c3c !important;
  cursor: pointer; }
  .auth a:hover, .auth a:active, .auth a:focus {
    color: #e74c3c !important; }

.auth .collapse-step.ng-enter {
  transition: 0.5s;
  opacity: 0;
  max-height: 0px; }

.auth .collapse-step.ng-enter-active {
  opacity: 1;
  max-height: 200px; }

.auth .collapse-step.ng-leave {
  transition: 0.5s;
  opacity: 1;
  max-height: 200px; }

.auth .collapse-step.ng-leave-active {
  opacity: 0;
  max-height: 0px; }

.modal h3.top-title {
  font-size: 18px;
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-top: 5px; }

.modal p.message {
  text-align: center;
  font-size: 16px;
  padding: 25px 20px; }

.modal h2 {
  font-size: 18px; }

input[type="tel"]::-webkit-outer-spin-button,
input[type="tel"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.pricing .ui-grid-row:not(.ui-grid-row-selected) .ui-grid-cell.ui-grid-row-header-cell {
  background-color: #fff; }

.pricing .ui-grid-row:not(.ui-grid-row-selected):nth-child(even) .ui-grid-cell {
  background-color: #fff; }

.pricing .ui-grid, .pricing .ui-grid-header {
  border: 0; }

.pricing .ui-grid-header-cell {
  border-top: 1px solid;
  border-bottom: 1px solid;
  background-color: #ececec;
  border-color: #888 !important; }

.pricing .ui-grid-header-cell:first-child {
  border-left: 1px solid; }

.pricing .ui-grid-header-cell:last-child {
  border-right: 1px solid; }

.pricing .ui-grid-cell:first-child {
  border-left: 1px solid; }

.pricing .ui-grid-top-panel {
  background-color: #fff; }

.pricing .ui-grid-cell {
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: #888 !important; }
  .pricing .ui-grid-cell form {
    line-height: 30px; }

.pricing .input-group-text {
  background-color: #f5f5f5; }

input[type="tel"] {
  -moz-appearance: textfield; }

.main-trips {
  min-height: calc(100vh - (307px + 61px)); }

.auto-margin {
  margin-right: auto;
  margin-left: auto; }

.no-padding {
  padding: 0px !important; }

.ui-grid-cell-contents form {
  line-height: 30px; }

.no-margin {
  margin-right: 0px !important;
  margin-left: 0px !important; }

.vehicle-table .header-icon {
  font-size: 200%;
  color: #e74c3c; }

.vehicle-table .btn-height {
  height: 100%; }

.vehicle-table .no-width {
  visibility: hidden;
  width: 0px; }

._exradicated-tooltip {
  padding: 0px !important; }

.main-checkout .evidence-content {
  padding-top: 15px;
  padding-bottom: 65px;
  background-color: white;
  border-bottom: #d7d8d9 1px solid;
  border-top: #d7d8d9 1px solid; }
  .main-checkout .evidence-content .trip-steps {
    margin-top: 5px;
    margin-bottom: 5px; }
  .main-checkout .evidence-content.top {
    border-top: none; }

.main-checkout .overlappping-content {
  margin-top: -68px; }

.main-checkout .company-description h1 {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold; }

.main-checkout .company-description .small {
  font-size: 18px;
  font-weight: normal; }

.main-checkout .company-description.disabled {
  text-decoration: line-through;
  color: #81888a; }

.main-checkout .trip-details h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: #d7d8d9 1px solid; }

.main-checkout .trip-details .angular-google-map-container {
  margin-top: 20px;
  border: #d7d8d9 1px solid !important;
  height: 350px; }

.main-checkout .prevnext button {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .main-checkout .prevnext button {
      margin-bottom: 0px; } }

.main-checkout .vehicle-details {
  padding-top: 15px;
  padding-bottom: 15px; }
  .main-checkout .vehicle-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .separator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .items {
    line-height: 2.5; }
    .main-checkout .vehicle-details .items b {
      font-weight: bold; }
  .main-checkout .vehicle-details .services {
    color: #81888a;
    display: inline;
    text-decoration: line-through; }
    .main-checkout .vehicle-details .services .icon {
      display: none; }
    .main-checkout .vehicle-details .services span {
      color: #81888a;
      line-height: 2.5; }
    .main-checkout .vehicle-details .services tip {
      width: 250px; }
    .main-checkout .vehicle-details .services.active {
      color: #555a5f;
      font-weight: bold;
      text-decoration: none; }
      .main-checkout .vehicle-details .services.active .icon {
        font-size: 24px;
        display: inline;
        margin-right: 5px; }
        .main-checkout .vehicle-details .services.active .icon.tooltips {
          font-size: 14px; }

.main-order .active-container {
  height: 350px; }
  .main-order .active-container img {
    max-height: 350px; }

.main-payment {
  min-height: calc(100vh - 450px); }
  .main-payment .content-gallery,
  .main-payment .content-gallery img {
    height: 200px; }
  .main-payment .evidence-content {
    padding-top: 15px;
    padding-bottom: 65px;
    background-color: white;
    border-bottom: #d7d8d9 1px solid;
    border-top: #d7d8d9 1px solid; }
    .main-payment .evidence-content.top {
      border-top: none; }
  .main-payment .overlappping-content {
    margin-top: -68px;
    margin-bottom: 20px; }
  .main-payment .section-description h1 {
    margin-top: 5px;
    font-size: 24px; }
  .main-payment .terms {
    font-size: 12px;
    margin: 5px 0 5px 0; }

.no-overflow-x {
  overflow-x: hidden; }

table {
  width: 100%;
  background-color: #fff;
  word-wrap: break-word; }

.container-xl {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.table-container {
  overflow-x: auto;
  min-height: 800px; }
  .table-container .multiSelect .show {
    position: fixed; }
  .table-container .multiSelect button {
    font-size: 0px;
    background-image: none;
    min-height: 0px !important;
    background: none;
    border: none;
    vertical-align: top; }
  .table-container .multiSelect .caret {
    border: none;
    margin: 0px !important;
    width: 14px;
    height: 14px;
    background-image: url("../assets/images/icons/filter.svg");
    background-size: contain; }

/*
.ui-grid-cell-contents {
  white-space: normal !important;
}
*/
.no-margin {
  margin: 0px;
  padding: 0px; }

.wrapper.company .narrow {
  margin-left: 7px;
  margin-right: 7px; }

.wrapper.company .neg-narrow {
  margin-left: 0px;
  margin-right: 0px; }

.wrapper.company .no-margin {
  margin: 0px; }

.wrapper.company .no-padding {
  padding: 0px; }

.wrapper.company .no-margin-top {
  margin-top: 0px; }

.wrapper.additional .no-margin {
  margin: 0px; }

.wrapper.additional .no-margin-top {
  margin-top: 0px; }

.wrapper.additional .invisible {
  display: none; }

.wrapper.notification .no-margin-top {
  margin-top: 0px !important; }

.wrapper.notification .no-margin {
  margin: 0px !important; }

.wrapper.requesttypes .no-margin {
  margin: 0px; }

.wrapper.requesttypes .no-padding {
  padding: 0px; }

.wrapper.requesttypes .align-right {
  text-align: right; }

.wrapper.vehicle .no-margin {
  margin: 0px; }

.wrapper.vehicle .no-padding {
  padding: 0px; }

.wrapper.vehicle .align-right {
  text-align: right; }

.wrapper.vehicle .big-font {
  font-size: 120%; }

.main-vehicles {
  min-height: calc(100vh - (307px + 61px)); }
  .main-vehicles .btn.disabled {
    cursor: not-allowed; }
    .main-vehicles .btn.disabled, .main-vehicles .btn.disabled:hover, .main-vehicles .btn.disabled:active, .main-vehicles .btn.disabled:focus {
      background-color: #f1b2ab;
      border-color: #f1b2ab; }

.main-trips {
  min-height: calc(100vh - (307px + 61px)); }

.main-checkout .evidence-content {
  padding-top: 15px;
  padding-bottom: 65px;
  background-color: white;
  border-bottom: #d7d8d9 1px solid;
  border-top: #d7d8d9 1px solid; }
  .main-checkout .evidence-content .trip-steps {
    margin-top: 5px;
    margin-bottom: 5px; }
  .main-checkout .evidence-content.top {
    border-top: none; }

.main-checkout .overlappping-content {
  margin-top: -68px; }

.main-checkout .company-description h1 {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold; }

.main-checkout .company-description .small {
  font-size: 18px;
  font-weight: normal; }

.main-checkout .company-description.disabled {
  text-decoration: line-through;
  color: #81888a; }

.main-checkout .trip-details h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: #d7d8d9 1px solid; }

.main-checkout .trip-details .angular-google-map-container {
  margin-top: 20px;
  border: #d7d8d9 1px solid !important;
  height: 350px; }

.main-checkout .prevnext button {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .main-checkout .prevnext button {
      margin-bottom: 0px; } }

.main-checkout .vehicle-details {
  padding-top: 15px;
  padding-bottom: 15px; }
  .main-checkout .vehicle-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .separator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .items {
    line-height: 2.5; }
    .main-checkout .vehicle-details .items b {
      font-weight: bold; }
  .main-checkout .vehicle-details .services {
    color: #81888a;
    display: inline;
    text-decoration: line-through; }
    .main-checkout .vehicle-details .services .icon {
      display: none; }
    .main-checkout .vehicle-details .services span {
      color: #81888a;
      line-height: 2.5; }
    .main-checkout .vehicle-details .services tip {
      width: 250px; }
    .main-checkout .vehicle-details .services.active {
      color: #555a5f;
      font-weight: bold;
      text-decoration: none; }
      .main-checkout .vehicle-details .services.active .icon {
        font-size: 24px;
        display: inline;
        margin-right: 5px; }
        .main-checkout .vehicle-details .services.active .icon.tooltips {
          font-size: 14px; }

.main-order .active-container {
  height: 350px; }
  .main-order .active-container img {
    max-height: 350px; }

.main-payment {
  min-height: calc(100vh - 450px); }
  .main-payment .content-gallery,
  .main-payment .content-gallery img {
    height: 200px; }
  .main-payment .evidence-content {
    padding-top: 15px;
    padding-bottom: 65px;
    background-color: white;
    border-bottom: #d7d8d9 1px solid;
    border-top: #d7d8d9 1px solid; }
    .main-payment .evidence-content.top {
      border-top: none; }
  .main-payment .overlappping-content {
    margin-top: -68px;
    margin-bottom: 20px; }
  .main-payment .section-description h1 {
    margin-top: 5px;
    font-size: 24px; }
  .main-payment .terms {
    font-size: 12px;
    margin: 5px 0 5px 0; }

.container-full {
  width: 100%;
  padding: 15px; }

table {
  width: 100%;
  background-color: #fff;
  word-wrap: break-word; }

.container-xl {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.table-container {
  overflow-x: auto; }

.ng-table-group-header {
  display: none; }

table-container .multiSelect .show {
  position: fixed;
  margin-left: -280px;
  width: 280px; }

table-container .multiSelect > button {
  font-size: 0px;
  background-image: none;
  min-height: 0px !important;
  background: none;
  border: none;
  vertical-align: top; }

table-container .multiSelect .caret {
  border: none;
  margin: 0px !important;
  width: 14px;
  height: 14px;
  background-image: url("../assets/images/icons/filter.svg");
  background-size: contain; }

table-container .multiSelect .date-picker-date-time {
  position: fixed; }

.locations-box .whiteframe {
  max-width: 100%; }

.locations-box .locations-actions {
  display: flex;
  font-size: 22px; }
  .locations-box .locations-actions .locations-action-back {
    background-color: #ffffff;
    border: 1px solid #EFEFEF;
    padding: 5px;
    margin: 5px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .locations-box .locations-actions .locations-action-back:hover {
      background-color: #f8f8f8; }

.locations-box .locations-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: row; }

.location-box .location-header {
  background-color: #ffffff;
  border: 1px solid #EFEFEF;
  padding: 10px;
  margin: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px; }
  .location-box .location-header.clickable:hover {
    background-color: #f8f8f8; }
  .location-box .location-header .location-drill .location-icon {
    width: 15px;
    height: auto;
    margin-right: 10px; }
  .location-box .location-header .location-open {
    background-color: #ffffff;
    padding: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #EFEFEF; }
    .location-box .location-header .location-open.clickable:hover {
      background-color: #f8f8f8; }

.location-box .location-body {
  background-color: #ffffff;
  border: 1px solid #EFEFEF;
  padding: 10px;
  margin: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 12px; }
  .location-box .location-body .location-poi {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .location-box .location-body .location-poi .location-poi-input {
      flex: 1 1 auto;
      width: 100%;
      padding: 3px 35px 3px 15px;
      border: 1px solid #EFEFEF;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      font-size: 12px;
      color: #888888;
      -o-text-overflow: clip;
      text-overflow: clip;
      background: #fcfcfc;
      text-align: left;
      margin: 5px 0; }
    .location-box .location-body .location-poi .location-action-remove {
      flex: 0 0 15px;
      background-color: #ffffff;
      padding: 3px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      border: 1px solid #EFEFEF; }
      .location-box .location-body .location-poi .location-action-remove.clickable:hover {
        background-color: #f8f8f8; }
  .location-box .location-body .location-action-add {
    background-color: #ffffff;
    padding: 3px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #EFEFEF; }
    .location-box .location-body .location-action-add.clickable:hover {
      background-color: #f8f8f8; }

.main-map .content-full-page {
  max-height: calc(100vh - 225px) !important;
  overflow-y: scroll;
  overflow-x: hidden; }

.main-map .content-map .angular-google-map-container {
  min-height: calc(100vh - 225px) !important;
  min-width: 100%; }

.results-bus-prices {
  margin-bottom: 20px;
  min-height: calc(100vh - (307px + 61px + 20px)); }
  .results-bus-prices .header .header-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .results-bus-prices .header .header-filter .city-currency {
      padding: 2px 4px;
      margin-left: 10px;
      border: 1px solid #999999; }
    .results-bus-prices .header .header-filter .city-selection {
      position: relative; }
      .results-bus-prices .header .header-filter .city-selection .city-suggestions {
        position: absolute;
        z-index: 2;
        top: 20px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: white;
        border: 1px solid #999999;
        max-height: 200px;
        overflow-y: auto; }
        .results-bus-prices .header .header-filter .city-selection .city-suggestions .city-suggestion {
          padding: 3px; }
          .results-bus-prices .header .header-filter .city-selection .city-suggestions .city-suggestion:hover {
            background-color: #ededed;
            cursor: pointer; }
  .results-bus-prices .content {
    background-color: #ececec;
    max-width: 900px; }
    .results-bus-prices .content .bus-size-block {
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid #dddddd; }
      .results-bus-prices .content .bus-size-block .bus-size-block-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #444444; }
        .results-bus-prices .content .bus-size-block .bus-size-block-row.bus-size-section {
          background-color: #efefef;
          text-transform: uppercase;
          font-weight: 800;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          margin: 10px 0; }
        .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-key {
          flex: 0 0 250px;
          max-width: 250px;
          padding: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-description div {
          white-space: normal; }
        .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-description textarea {
          width: 100%;
          height: 50px;
          padding: 3px;
          box-sizing: border-box;
          border: 2px solid #ccc;
          border-radius: 4px;
          background-color: #f8f8f8;
          resize: none; }
        .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-bus-size {
          flex-grow: 1;
          flex-basis: 0;
          padding: 5px; }
          .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-bus-size.language {
            text-align: center;
            text-transform: uppercase;
            font-weight: 600; }
          .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-bus-size .bus-size-input {
            width: 35%;
            font-size: 12px;
            padding: 0; }
        .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-sort {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
        .results-bus-prices .content .bus-size-block .bus-size-block-row .bus-size-block-delete {
          font-size: 16px;
          color: #e74c3c; }
      .results-bus-prices .content .bus-size-block .trip-category-actions {
        margin-top: 20px; }
  .results-bus-prices .btn-secondary-small {
    margin: 0 0 0 10px;
    border: 1px solid #e74c3c;
    color: #e74c3c;
    font-size: 12px;
    font-weight: 600;
    padding: 1px 6px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
    .results-bus-prices .btn-secondary-small.disabled {
      border: 1px solid #444444;
      color: #444444;
      background-color: #ededed; }
    .results-bus-prices .btn-secondary-small:hover {
      background-color: #ededed; }

.main-profile {
  margin-bottom: 20px;
  min-height: calc(100vh - (307px + 61px + 20px)); }

.hub {
  --input-padding-x: .50rem;
  --input-padding-y: .50rem; }
  .hub.content-map .angular-google-map-container {
    min-height: 600px !important;
    min-width: 100%; }
  .hub .form-label-group {
    position: relative;
    margin-bottom: 1rem; }
  .hub .form-label-group > input,
  .hub .form-label-group > label,
  .hub .form-label-group > button {
    padding: var(--input-padding-y) var(--input-padding-x); }
  .hub .no-margin {
    margin: 0px; }
  .hub .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out; }
  .hub .form-label-group input::-webkit-input-placeholder {
    color: transparent; }
  .hub .form-label-group input:-ms-input-placeholder {
    color: transparent; }
  .hub .form-label-group input::-ms-input-placeholder {
    color: transparent; }
  .hub .form-label-group input::-moz-placeholder {
    color: transparent; }
  .hub .form-label-group input::placeholder {
    color: transparent; }
  .hub .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3); }
  .hub .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777; }
  .hub h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }

.chin {
  margin-bottom: 1em; }

.main-profile {
  margin-bottom: 20px;
  min-height: calc(100vh - (307px + 61px + 20px)); }
  .main-profile h2 {
    font-size: 18px;
    line-height: 1.5; }
  .main-profile p {
    line-height: 1.5; }
    .main-profile p b {
      font-weight: bold; }

.results-quotation {
  margin-bottom: 20px;
  min-height: calc(100vh - (307px + 61px + 20px)); }
  .results-quotation .content {
    background-color: #ececec;
    max-width: 900px; }
  .results-quotation .new-quotations {
    font-size: 12px;
    color: #e74c3c; }
    .results-quotation .new-quotations a {
      color: #e74c3c;
      text-decoration: underline; }
  .results-quotation .header-filters {
    display: flex;
    justify-content: space-between;
    position: relative; }
    .results-quotation .header-filters .header-filter {
      display: flex; }
    .results-quotation .header-filters .icon {
      font-size: 18px; }
    .results-quotation .header-filters #full-text-search {
      padding-right: 20px; }
      .results-quotation .header-filters #full-text-search.filtered {
        border: 1px solid #e74c3c; }
    .results-quotation .header-filters .inside-input {
      position: absolute;
      right: 0;
      top: 1px; }
  .results-quotation .header-details {
    display: flex;
    justify-content: space-between; }
    .results-quotation .header-details label {
      margin-bottom: 0; }
    .results-quotation .header-details .header-number-results {
      display: flex;
      align-items: center; }
      .results-quotation .header-details .header-number-results .results-amount {
        min-width: 50px; }
    .results-quotation .header-details .header-pagination {
      display: grid;
      grid-template-columns: repeat(5, 25px); }
      .results-quotation .header-details .header-pagination .page {
        grid-row: 1;
        margin: 0 5px; }
        .results-quotation .header-details .header-pagination .page.current-page {
          color: #e74c3c;
          font-weight: bold; }
        .results-quotation .header-details .header-pagination .page.hidden {
          visibility: hidden; }
  .results-quotation .block-footer {
    padding: 20px; }
    .results-quotation .block-footer .footer-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .results-quotation .block-footer .footer-details label {
        margin-bottom: 0; }
      .results-quotation .block-footer .footer-details .footer-number-results {
        display: flex;
        align-items: center; }
        .results-quotation .block-footer .footer-details .footer-number-results .results-amount {
          min-width: 50px; }
      .results-quotation .block-footer .footer-details .footer-pagination {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(5, 25px); }
        .results-quotation .block-footer .footer-details .footer-pagination .page {
          grid-row: 1;
          margin: 0 5px; }
          .results-quotation .block-footer .footer-details .footer-pagination .page.current-page {
            color: #e74c3c;
            font-weight: bold; }
          .results-quotation .block-footer .footer-details .footer-pagination .page.hidden {
            visibility: hidden; }

*:focus {
  outline: none; }

.result-container {
  margin-bottom: 15px;
  margin-left: 10px;
  position: relative; }
  .result-container .result-preview {
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
    .result-container .result-preview .result-status .ribbon-container {
      display: block;
      position: absolute;
      padding: 6px 6px;
      z-index: 1;
      left: -10px;
      top: 10px;
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2); }
      .result-container .result-preview .result-status .ribbon-container:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        top: 100%;
        border-width: 4px 5px;
        border-style: solid; }
      .result-container .result-preview .result-status .ribbon-container .ribbon-text-value {
        display: block;
        color: #fff;
        font-weight: bolder;
        font-size: 14px;
        line-height: 16px;
        vertical-align: center; }
    .result-container .result-preview .result-status.status-new .ribbon-container {
      background-color: #d45050; }
      .result-container .result-preview .result-status.status-new .ribbon-container:after {
        border-color: #993737 #993737 transparent transparent; }
    .result-container .result-preview .result-status.status-pending .ribbon-container {
      background-color: #d4d450; }
      .result-container .result-preview .result-status.status-pending .ribbon-container:after {
        border-color: #998937 #998937 transparent transparent; }
    .result-container .result-preview .result-status.status-sent .ribbon-container {
      background-color: #4fd46c; }
      .result-container .result-preview .result-status.status-sent .ribbon-container:after {
        border-color: #36994c #36994c transparent transparent; }
    .result-container .result-preview .result-status.status-transfer .ribbon-container {
      background-color: #5066d4; }
      .result-container .result-preview .result-status.status-transfer .ribbon-container:after {
        border-color: #374799 #374799 transparent transparent; }
    .result-container .result-preview .result-status.status-failed .ribbon-container {
      background-color: #d450d4; }
      .result-container .result-preview .result-status.status-failed .ribbon-container:after {
        border-color: #993799 #993799 transparent transparent; }
    .result-container .result-preview .result-status.status-completed .ribbon-container {
      background-color: #50d4d4; }
      .result-container .result-preview .result-status.status-completed .ribbon-container:after {
        border-color: #379999 #379999 transparent transparent; }
      .result-container .result-preview .result-status.status-completed .ribbon-container .ribbon-text-value {
        color: #444444; }
    .result-container .result-preview .result-status.status-cancelled .ribbon-container {
      background-color: #d49250; }
      .result-container .result-preview .result-status.status-cancelled .ribbon-container:after {
        border-color: #996837 #996837 transparent transparent; }
    .result-container .result-preview .result-status.status-refunded .ribbon-container {
      background-color: #a8d450; }
      .result-container .result-preview .result-status.status-refunded .ribbon-container:after {
        border-color: #789937 #789937 transparent transparent; }
    .result-container .result-preview .result-status.status-standby .ribbon-container {
      background-color: #cccccc; }
      .result-container .result-preview .result-status.status-standby .ribbon-container:after {
        border-color: #7a7a7a #7a7a7a transparent transparent; }
    .result-container .result-preview .result-status.status-lost .ribbon-container {
      background-color: #cc7593; }
      .result-container .result-preview .result-status.status-lost .ribbon-container:after {
        border-color: #7a404c #7a404c transparent transparent; }
    .result-container .result-preview .result-status.status-nodispo .ribbon-container {
      background-color: #000; }
      .result-container .result-preview .result-status.status-nodispo .ribbon-container:after {
        border-color: #1e1e1e #1e1e1e transparent transparent; }
    .result-container .result-preview .result-status.status-no-dispo .ribbon-container {
      background-color: #000; }
      .result-container .result-preview .result-status.status-no-dispo .ribbon-container:after {
        border-color: #1e1e1e #1e1e1e transparent transparent; }
    .result-container .result-preview .result-status.status-no .ribbon-container {
      background-color: #000; }
      .result-container .result-preview .result-status.status-no .ribbon-container:after {
        border-color: #1e1e1e #1e1e1e transparent transparent; }
    .result-container .result-preview .result-status.status-B2B .ribbon-container {
      background-color: #eba117; }
      .result-container .result-preview .result-status.status-B2B .ribbon-container:after {
        border-color: #eb7a17 #eb7a17  transparent transparent; }
    .result-container .result-preview .result-status.status-b2b .ribbon-container {
      background-color: #eba117; }
      .result-container .result-preview .result-status.status-b2b .ribbon-container:after {
        border-color: #eb7a17 #eb7a17  transparent transparent; }
    .result-container .result-preview .result-status.status-B2b .ribbon-container {
      background-color: #eba117; }
      .result-container .result-preview .result-status.status-B2b .ribbon-container:after {
        border-color: #eb7a17 #eb7a17  transparent transparent; }
    .result-container .result-preview .result-status.status-nexi .ribbon-container {
      background-color: #2D32AA;
      color: white !important; }
      .result-container .result-preview .result-status.status-nexi .ribbon-container:after {
        border-color: #2D32AA #2D32AA  transparent transparent; }
    .result-container .result-preview .result-info {
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      padding-left: 80px;
      align-items: center; }
      .result-container .result-preview .result-info .result-info-block {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 10px; }
        .result-container .result-preview .result-info .result-info-block .icon-copy {
          padding: 0 5px; }
        .result-container .result-preview .result-info .result-info-block.result-date {
          font-size: 12px; }
        .result-container .result-preview .result-info .result-info-block .flag {
          height: 25px; }
        .result-container .result-preview .result-info .result-info-block .verified {
          font-size: 10px;
          color: #4fd46c;
          margin-right: 5px; }
        .result-container .result-preview .result-info .result-info-block.result-currency .icon {
          margin-left: 5px;
          font-size: 10px; }
    .result-container .result-preview .info-preview {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px; }
      .result-container .result-preview .info-preview .info-blocks {
        display: flex;
        justify-content: center;
        margin-right: 30px; }
        .result-container .result-preview .info-preview .info-blocks .info-block {
          border: 1px solid #888888;
          padding: 4px 8px;
          margin: 0 3px;
          border-radius: 5px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          color: #888888; }
          .result-container .result-preview .info-preview .info-blocks .info-block .info-block-label {
            font-size: 12px;
            display: flex; }
            .result-container .result-preview .info-preview .info-blocks .info-block .info-block-label .icon, .result-container .result-preview .info-preview .info-blocks .info-block .info-block-label .icon-2 {
              margin-right: 2px; }
          .result-container .result-preview .info-preview .info-blocks .info-block .info-block-text {
            font-size: 12px;
            display: flex;
            white-space: nowrap;
            flex-wrap: nowrap; }
          .result-container .result-preview .info-preview .info-blocks .info-block.result-total {
            font-weight: bold; }
            .result-container .result-preview .info-preview .info-blocks .info-block.result-total .result-input {
              max-width: 50px;
              padding: 3px;
              margin-right: 35px;
              border: 0 solid;
              -webkit-border-radius: 3px;
              border-radius: 3px;
              font-size: 12px;
              font-weight: bold;
              color: #888888;
              -o-text-overflow: clip;
              text-overflow: clip;
              background: #fcfcfc;
              text-align: left; }
              .result-container .result-preview .info-preview .info-blocks .info-block.result-total .result-input.right {
                text-align: right; }
              .result-container .result-preview .info-preview .info-blocks .info-block.result-total .result-input::-webkit-inner-spin-button, .result-container .result-preview .info-preview .info-blocks .info-block.result-total .result-input::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0; }
            .result-container .result-preview .info-preview .info-blocks .info-block.result-total .result-input-text {
              font-size: 12px;
              color: #888888;
              margin-left: -40px;
              white-space: nowrap; }
          .result-container .result-preview .info-preview .info-blocks .info-block.info-block-highlight {
            background-color: #95d49d;
            color: #444444; }
            .result-container .result-preview .info-preview .info-blocks .info-block.info-block-highlight .result-input {
              background-color: #95d49d;
              color: #444444; }
            .result-container .result-preview .info-preview .info-blocks .info-block.info-block-highlight .result-input-text {
              color: #444444; }
    .result-container .result-preview .show-details {
      display: flex;
      margin-top: 10px;
      padding: 5px 0 5px 0;
      background-color: #fefefe;
      justify-content: center; }
  .result-container .details-box {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #dddddd;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 10px; }
    .result-container .details-box .info-boxes {
      display: flex; }
      .result-container .details-box .info-boxes .info-box {
        justify-content: flex-start;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 10px; }
        .result-container .details-box .info-boxes .info-box.info-box-left {
          flex: 0 0 310px; }
        .result-container .details-box .info-boxes .info-box.info-box-right {
          max-width: calc(100% - 310px); }
        .result-container .details-box .info-boxes .info-box .content-map {
          max-width: 300px;
          border: 1px solid #888888; }
        .result-container .details-box .info-boxes .info-box .result {
          margin-top: 10px;
          padding: 10px;
          text-align: left; }
          .result-container .details-box .info-boxes .info-box .result .result-details {
            display: flex;
            justify-content: space-between;
            position: relative; }
            .result-container .details-box .info-boxes .info-box .result .result-details.result-title {
              font-size: 16px;
              margin-bottom: 10px;
              padding-bottom: 5px;
              border-bottom: 1px solid #888888; }
            .result-container .details-box .info-boxes .info-box .result .result-details.result-total {
              border-top: 1px solid #444444;
              padding-top: 5px;
              margin-top: 5px; }
            .result-container .details-box .info-boxes .info-box .result .result-details .result-modify-trip {
              position: absolute;
              right: 0;
              border: 1px solid #666;
              padding: 3px 4px;
              border-radius: 5px; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-modify-trip:hover {
                background-color: #EFEFEF; }
            .result-container .details-box .info-boxes .info-box .result .result-details .result-details-text {
              text-align: right;
              display: flex;
              font-size: 12px; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-text.result-details-text-highlight {
                font-weight: 600; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-text .result-details-trip {
                text-align: left;
                color: #444444; }
                .result-container .details-box .info-boxes .info-box .result .result-details .result-details-text .result-details-trip .trip-trf {
                  margin-top: 10px; }
                .result-container .details-box .info-boxes .info-box .result .result-details .result-details-text .result-details-trip .result-details-trip-actions {
                  display: flex;
                  margin-top: 15px;
                  justify-content: space-between;
                  align-items: center; }
                  .result-container .details-box .info-boxes .info-box .result .result-details .result-details-text .result-details-trip .result-details-trip-actions .result-details-action-copy {
                    border: 1px solid #666666;
                    padding: 6px 4px;
                    border-radius: 5px;
                    color: #666666; }
                    .result-container .details-box .info-boxes .info-box .result .result-details .result-details-text .result-details-trip .result-details-trip-actions .result-details-action-copy:hover {
                      background-color: #efefef; }
            .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label {
              flex-wrap: nowrap;
              white-space: nowrap;
              font-size: 12px; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label .activity-status-sent {
                color: #36994c;
                font-weight: 600; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label .activity-status-open {
                color: #998937;
                font-weight: 600; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label .activity-status-blocked {
                color: #993737;
                font-weight: 600; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label .activity-status-click {
                color: #36994c;
                font-weight: 600; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label .activity-status-bounce {
                color: #993737;
                font-weight: 600; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label .activity-status-spam {
                color: #993737;
                font-weight: 600; }
              .result-container .details-box .info-boxes .info-box .result .result-details .result-details-label .activity-status-unsub {
                color: #993737;
                font-weight: 600; }
        .result-container .details-box .info-boxes .info-box .additional-costs {
          padding: 10px; }
          .result-container .details-box .info-boxes .info-box .additional-costs .result-select {
            margin: 0 10px 10px 0; }
        .result-container .details-box .info-boxes .info-box .result-actions {
          flex: 1 1 100%;
          min-width: 300px;
          padding: 10px;
          text-align: left; }
          .result-container .details-box .info-boxes .info-box .result-actions .info-operator {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
            width: 100%; }
            .result-container .details-box .info-boxes .info-box .result-actions .info-operator .info-operator-label {
              width: 100px;
              padding: 5px 0;
              margin-bottom: 0; }
            .result-container .details-box .info-boxes .info-box .result-actions .info-operator .info-operator-list {
              width: 120px;
              cursor: pointer;
              padding: 3px 15px 3px 15px;
              outline: 0;
              font-size: 12px;
              border: 1px solid #888888;
              border-radius: 3px;
              background: #FFFFFF;
              color: #888888;
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-operator .info-operator-list::-ms-expand {
                display: none; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-operator .info-operator-list:hover, .result-container .details-box .info-boxes .info-box .result-actions .info-operator .info-operator-list:focus {
                color: #444444;
                background: #dddddd; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-operator .info-operator-list:disabled {
                opacity: 0.5;
                pointer-events: none; }
            .result-container .details-box .info-boxes .info-box .result-actions .info-operator .info-operator-list-arrow {
              margin-left: -20px;
              color: #888888;
              pointer-events: none; }
          .result-container .details-box .info-boxes .info-box .result-actions .real-price {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 15px; }
            .result-container .details-box .info-boxes .info-box .result-actions .real-price .result-input-label {
              width: 100px;
              margin: 0; }
            .result-container .details-box .info-boxes .info-box .result-actions .real-price .result-input {
              width: 120px;
              padding: 3px 35px 3px 15px;
              border: 1px solid #888888;
              -webkit-border-radius: 3px;
              border-radius: 3px;
              font-size: 12px;
              color: #888888;
              -o-text-overflow: clip;
              text-overflow: clip;
              background: #fcfcfc;
              text-align: left; }
              .result-container .details-box .info-boxes .info-box .result-actions .real-price .result-input.right {
                text-align: right; }
              .result-container .details-box .info-boxes .info-box .result-actions .real-price .result-input::-webkit-inner-spin-button, .result-container .details-box .info-boxes .info-box .result-actions .real-price .result-input::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0; }
            .result-container .details-box .info-boxes .info-box .result-actions .real-price .result-input-text {
              font-size: 12px;
              color: #888888;
              margin-left: -40px; }
            .result-container .details-box .info-boxes .info-box .result-actions .real-price .input-message {
              margin-left: 15px;
              font-size: 12px;
              color: #888888;
              font-style: italic; }
          .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            justify-content: space-between; }
            .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price {
              flex: 0 1 30%;
              display: flex;
              flex-direction: column;
              min-width: 80px;
              padding: 10px;
              border-radius: 5px;
              border: 1px solid #888888; }
              .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price.selected-result {
                border-color: #e74c3c; }
              .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .result-input {
                box-sizing: border-box;
                min-width: 100%;
                max-width: 100%;
                flex: 1 1 auto;
                padding: 1px 35px 1px 15px;
                border: 1px solid #888888;
                -webkit-border-radius: 3px;
                border-radius: 3px;
                font-size: 12px;
                color: #888888;
                -o-text-overflow: clip;
                text-overflow: clip;
                background: #fcfcfc;
                text-align: left;
                margin-bottom: 10px; }
                .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .result-input.right {
                  text-align: right; }
                .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .result-input::-webkit-inner-spin-button, .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .result-input::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0; }
              .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .result-input-text {
                font-size: 12px;
                color: #888888;
                margin-left: -40px; }
              .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .box-result-vehicle-data {
                display: flex;
                flex-direction: row;
                justify-content: flex-start; }
                .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .box-result-vehicle-data .box-result-info-year, .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .box-result-vehicle-data .box-result-info-emission {
                  background-color: #E5E5E5;
                  border-radius: 3px;
                  margin: 0 3px 10px 3px;
                  padding: 3px 6px;
                  font-size: 10px;
                  white-space: nowrap;
                  color: #222; }
              .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .box-result-info-services {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap; }
                .result-container .details-box .info-boxes .info-box .result-actions .three-results-prices .box-result-price .box-result-info-services .box-result-info-service {
                  margin-right: 5px; }
          .result-container .details-box .info-boxes .info-box .result-actions .info-actions {
            display: flex;
            margin-bottom: 15px; }
            .result-container .details-box .info-boxes .info-box .result-actions .info-actions .info-action {
              background-color: #FFFFFF;
              color: #e74c3c;
              border: 1px solid #e74c3c;
              font-size: 12px;
              padding: 3px 5px;
              border-radius: 5px;
              margin-right: 5px;
              position: relative; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-actions .info-action:hover {
                background-color: #efefef; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-actions .info-action .dropdown-cost {
                position: absolute; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-actions .info-action .dropdown-add-service {
                position: absolute; }
          .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize {
            border: 1px solid #888888;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            padding: 10px;
            margin: 10px 0; }
            .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-header {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #888888;
              padding: 5px; }
            .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body {
              padding: 10px 5px; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .cost-template {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                margin-bottom: 10px; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .cost-template label {
                  margin: 3px 5px; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .cost-template input {
                  width: auto; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .cost-title-class {
                font-weight: 600;
                margin-top: 15px; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .cost-class {
                display: flex;
                justify-content: space-between; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .show {
                width: 13px;
                height: auto; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 15px; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-name {
                  width: 100px;
                  margin: 0;
                  font-size: 12px; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input {
                  width: 100px;
                  padding: 3px 35px 3px 15px;
                  border: 1px solid #888888;
                  -webkit-border-radius: 3px;
                  border-radius: 3px;
                  font-size: 12px;
                  color: #888888;
                  -o-text-overflow: clip;
                  text-overflow: clip;
                  background: #fcfcfc;
                  text-align: left; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input.right {
                    text-align: right; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input.short {
                    width: 80px;
                    padding: 3px 5px 3px 5px; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input::-webkit-inner-spin-button, .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input-currency {
                  margin-left: 10px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input-currency .result-input-text {
                    font-size: 12px;
                    color: #888888;
                    margin-left: -40px; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .result-input-currency .input-message {
                    margin-left: 15px;
                    font-size: 12px;
                    color: #888888;
                    font-style: italic; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-close {
                  flex: 1 1 auto;
                  text-align: right; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode {
                  margin-left: 10px; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode .additional-cost-detail-payment {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%; }
                    .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode .additional-cost-detail-payment .additional-cost-detail-payment-list {
                      width: 70px;
                      cursor: pointer;
                      padding: 3px 15px 3px 15px;
                      outline: 0;
                      font-size: 12px;
                      border: 1px solid #888888;
                      border-radius: 3px;
                      background: #FFFFFF;
                      color: #888888;
                      appearance: none;
                      -webkit-appearance: none;
                      -moz-appearance: none; }
                      .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode .additional-cost-detail-payment .additional-cost-detail-payment-list::-ms-expand {
                        display: none; }
                      .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode .additional-cost-detail-payment .additional-cost-detail-payment-list:hover, .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode .additional-cost-detail-payment .additional-cost-detail-payment-list:focus {
                        color: #444444;
                        background: #dddddd; }
                      .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode .additional-cost-detail-payment .additional-cost-detail-payment-list:disabled {
                        opacity: 0.5;
                        pointer-events: none; }
                    .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-cost-details .additional-cost-detail-pay-mode .additional-cost-detail-payment .additional-cost-detail-payment-list-arrow {
                      margin-left: -20px;
                      color: #888888;
                      pointer-events: none; }
              .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 15px; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-name {
                  width: 100px;
                  margin: 0;
                  font-size: 12px; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .result-input {
                  width: 100px;
                  padding: 3px 35px 3px 15px;
                  border: 1px solid #888888;
                  -webkit-border-radius: 3px;
                  border-radius: 3px;
                  font-size: 12px;
                  color: #888888;
                  -o-text-overflow: clip;
                  text-overflow: clip;
                  background: #fcfcfc;
                  text-align: left; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .result-input.right {
                    text-align: right; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .result-input::-webkit-inner-spin-button, .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .result-input::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .result-input-currency {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-left: 10px; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .result-input-currency .result-input-text {
                    font-size: 12px;
                    color: #888888;
                    margin-left: -40px; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .result-input-currency .input-message {
                    margin-left: 15px;
                    font-size: 12px;
                    color: #888888;
                    font-style: italic; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-close {
                  flex: 1 1 auto;
                  text-align: right; }
                .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode {
                  margin-left: 10px; }
                  .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode .additional-service-detail-payment {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%; }
                    .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode .additional-service-detail-payment .additional-service-detail-payment-list {
                      width: 70px;
                      cursor: pointer;
                      padding: 3px 15px 3px 15px;
                      outline: 0;
                      font-size: 12px;
                      border: 1px solid #888888;
                      border-radius: 3px;
                      background: #FFFFFF;
                      color: #888888;
                      appearance: none;
                      -webkit-appearance: none;
                      -moz-appearance: none; }
                      .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode .additional-service-detail-payment .additional-service-detail-payment-list::-ms-expand {
                        display: none; }
                      .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode .additional-service-detail-payment .additional-service-detail-payment-list:hover, .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode .additional-service-detail-payment .additional-service-detail-payment-list:focus {
                        color: #444444;
                        background: #dddddd; }
                      .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode .additional-service-detail-payment .additional-service-detail-payment-list:disabled {
                        opacity: 0.5;
                        pointer-events: none; }
                    .result-container .details-box .info-boxes .info-box .result-actions .info-box-customize .info-box-customize-body .additional-service-details .additional-service-detail-pay-mode .additional-service-detail-payment .additional-service-detail-payment-list-arrow {
                      margin-left: -20px;
                      color: #888888;
                      pointer-events: none; }
          .result-container .details-box .info-boxes .info-box .result-actions .user-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px; }
            .result-container .details-box .info-boxes .info-box .result-actions .user-row .user-email {
              display: flex;
              justify-content: flex-start;
              align-items: center; }
              .result-container .details-box .info-boxes .info-box .result-actions .user-row .user-email .user-email-label {
                width: 100px;
                margin: 0; }
              .result-container .details-box .info-boxes .info-box .result-actions .user-row .user-email .user-email-input {
                width: 200px;
                padding: 3px 15px 3px 15px;
                border: 1px solid #888888;
                -webkit-border-radius: 3px;
                border-radius: 3px;
                font-size: 12px;
                color: #888888;
                -o-text-overflow: clip;
                text-overflow: clip;
                background: #fcfcfc;
                text-align: left; }
                .result-container .details-box .info-boxes .info-box .result-actions .user-row .user-email .user-email-input.right {
                  text-align: right; }
                .result-container .details-box .info-boxes .info-box .result-actions .user-row .user-email .user-email-input::-webkit-inner-spin-button, .result-container .details-box .info-boxes .info-box .result-actions .user-row .user-email .user-email-input::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0; }
          .result-container .details-box .info-boxes .info-box .result-actions .user-notes {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 15px; }
            .result-container .details-box .info-boxes .info-box .result-actions .user-notes .user-notes-label {
              width: 100px;
              margin: 0; }
            .result-container .details-box .info-boxes .info-box .result-actions .user-notes .user-notes-text {
              flex: 1 1 auto;
              padding: 3px 15px 3px 15px;
              border: 1px solid #888888;
              -webkit-border-radius: 3px;
              border-radius: 3px;
              font-size: 12px;
              color: #888888;
              -o-text-overflow: clip;
              text-overflow: clip;
              background: #fcfcfc;
              text-align: left;
              height: 50px; }
              .result-container .details-box .info-boxes .info-box .result-actions .user-notes .user-notes-text.right {
                text-align: right; }
              .result-container .details-box .info-boxes .info-box .result-actions .user-notes .user-notes-text::-webkit-inner-spin-button, .result-container .details-box .info-boxes .info-box .result-actions .user-notes .user-notes-text::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0; }
          .result-container .details-box .info-boxes .info-box .result-actions .info-box-actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 100px; }
    .result-container .details-box .info-actions-box {
      display: flex;
      padding: 10px;
      align-items: center;
      justify-content: space-between; }
      .result-container .details-box .info-actions-box .info-action-box-dropdown {
        position: relative; }
        .result-container .details-box .info-actions-box .info-action-box-dropdown .simple-dropdown {
          border: 1px solid #e74c3c;
          padding: 4px 8px;
          border-radius: 5px;
          color: #e74c3c;
          font-size: 12px;
          width: 100%; }
          .result-container .details-box .info-actions-box .info-action-box-dropdown .simple-dropdown .simple-dropdown-option:not(:last-child) {
            border-bottom: 1px solid #e74c3c; }
      .result-container .details-box .info-actions-box .info-action-box {
        border: 1px solid #888888;
        padding: 4px 8px;
        border-radius: 5px;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .result-container .details-box .info-actions-box .info-action-box.result-delete {
          color: #e74c3c;
          border-color: #e74c3c; }
          .result-container .details-box .info-actions-box .info-action-box.result-delete .info-block-label {
            font-size: 12px;
            font-weight: 600;
            color: #e74c3c; }
        .result-container .details-box .info-actions-box .info-action-box.result-send-template {
          color: #e74c3c;
          border-color: #e74c3c; }
          .result-container .details-box .info-actions-box .info-action-box.result-send-template .info-block-label {
            font-size: 12px;
            font-weight: 600;
            color: #e74c3c; }
        .result-container .details-box .info-actions-box .info-action-box.result-send {
          color: #ffffff;
          background-color: #e74c3c;
          border-color: #e74c3c; }
  .result-container .action-send {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #ffffff;
    background-color: #e74c3c;
    border-radius: 3px;
    white-space: nowrap;
    font-size: 14px;
    padding: 4px 12px;
    font-weight: bold; }
    .result-container .action-send:hover {
      background-color: #ed7a6e; }
    .result-container .action-send:active {
      background-color: #f1b2ab; }
    .result-container .action-send.disabled {
      background-color: #c1c1c1;
      box-shadow: none; }
      .result-container .action-send.disabled:hover {
        background-color: #c1c1c1; }
      .result-container .action-send.disabled:active {
        background-color: #c1c1c1; }
  .result-container .action-send-secondary {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #e74c3c;
    background-color: #ffffff;
    border: 1px solid #e74c3c;
    border-radius: 3px;
    white-space: normal;
    font-size: 14px;
    padding: 4px 12px;
    margin: 0 15px; }
    .result-container .action-send-secondary:hover {
      background-color: #efefef; }
    .result-container .action-send-secondary.disabled {
      border: 1px solid #c1c1c1;
      box-shadow: none;
      background-color: #efefef; }
      .result-container .action-send-secondary.disabled:hover {
        border: 1px solid #c1c1c1;
        background-color: #efefef; }
      .result-container .action-send-secondary.disabled:active {
        border: 1px solid #c1c1c1;
        background-color: #efefef; }

.main-trips {
  min-height: calc(100vh - (307px + 61px)); }

.no-margin {
  margin: 0px;
  padding: 0px; }

.heart-red {
  color: #FF4081; }

.circle-green {
  color: #24dd1e; }

.main-checkout .evidence-content {
  padding-top: 15px;
  padding-bottom: 65px;
  background-color: white;
  border-bottom: #d7d8d9 1px solid;
  border-top: #d7d8d9 1px solid; }
  .main-checkout .evidence-content .trip-steps {
    margin-top: 5px;
    margin-bottom: 5px; }
  .main-checkout .evidence-content.top {
    border-top: none; }

.main-checkout .overlappping-content {
  margin-top: -68px; }

.main-checkout .company-description h1 {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold; }

.main-checkout .company-description .small {
  font-size: 18px;
  font-weight: normal; }

.main-checkout .company-description.disabled {
  text-decoration: line-through;
  color: #81888a; }

.main-checkout .trip-details h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: #d7d8d9 1px solid; }

.main-checkout .trip-details .angular-google-map-container {
  margin-top: 20px;
  border: #d7d8d9 1px solid !important;
  height: 350px; }

.main-checkout .prevnext button {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .main-checkout .prevnext button {
      margin-bottom: 0px; } }

.main-checkout .vehicle-details {
  padding-top: 15px;
  padding-bottom: 15px; }
  .main-checkout .vehicle-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .separator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .items {
    line-height: 2.5; }
    .main-checkout .vehicle-details .items b {
      font-weight: bold; }
  .main-checkout .vehicle-details .services {
    color: #81888a;
    display: inline;
    text-decoration: line-through; }
    .main-checkout .vehicle-details .services .icon {
      display: none; }
    .main-checkout .vehicle-details .services span {
      color: #81888a;
      line-height: 2.5; }
    .main-checkout .vehicle-details .services tip {
      width: 250px; }
    .main-checkout .vehicle-details .services.active {
      color: #555a5f;
      font-weight: bold;
      text-decoration: none; }
      .main-checkout .vehicle-details .services.active .icon {
        font-size: 24px;
        display: inline;
        margin-right: 5px; }
        .main-checkout .vehicle-details .services.active .icon.tooltips {
          font-size: 14px; }

.main-order .active-container {
  height: 350px; }
  .main-order .active-container img {
    max-height: 350px; }

.main-payment {
  min-height: calc(100vh - 450px); }
  .main-payment .content-gallery,
  .main-payment .content-gallery img {
    height: 200px; }
  .main-payment .evidence-content {
    padding-top: 15px;
    padding-bottom: 65px;
    background-color: white;
    border-bottom: #d7d8d9 1px solid;
    border-top: #d7d8d9 1px solid; }
    .main-payment .evidence-content.top {
      border-top: none; }
  .main-payment .overlappping-content {
    margin-top: -68px;
    margin-bottom: 20px; }
  .main-payment .section-description h1 {
    margin-top: 5px;
    font-size: 24px; }
  .main-payment .terms {
    font-size: 12px;
    margin: 5px 0 5px 0; }

.container-full {
  width: 100%;
  padding: 15px; }

.container-xl {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.table-container {
  overflow-x: auto; }

.ng-table-group-header {
  display: none; }

.table-container .multiSelect .show {
  position: fixed; }

.table-container .multiSelect > button {
  font-size: 0px;
  background-image: none;
  min-height: 0px !important;
  background: none;
  border: none;
  vertical-align: top; }

.table-container .multiSelect .caret {
  border: none;
  margin: 0px !important;
  width: 14px;
  height: 14px;
  background-image: url("../assets/images/icons/filter.svg");
  background-size: contain; }

/*
.grid {
    height: 98vh;

}
*/
.quotes .dropdown {
  position: absolute;
  z-index: 3001;
  top: 0px;
  left: 0px;
  min-width: 282px;
  max-width: 460px;
  line-height: normal;
  border-radius: 0 0 2.5px 2.5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  background: white; }
  .quotes .dropdown .item {
    display: block;
    color: black;
    position: relative;
    margin: 0; }
    .quotes .dropdown .item:first-child {
      border-top: none; }
    .quotes .dropdown .item :hover {
      background: rgba(0, 0, 0, 0.1); }

.dropdown-container {
  position: relative; }
  .dropdown-container .dropdown-wrapper {
    overflow: hidden; }

.icon-star {
  color: #f5ab35; }

.main-reviews .reviews .rating {
  padding-right: 4px;
  margin-top: 5px; }
  .main-reviews .reviews .rating .icon {
    color: #e74c3c; }
    .main-reviews .reviews .rating .icon.disabled {
      color: #d7d8d9; }

.main-reviews .reviews .comment {
  padding-top: 5px; }
  .main-reviews .reviews .comment .icon {
    font-size: 18px; }

.main-profile {
  margin-bottom: 20px;
  min-height: calc(100vh - (307px + 61px + 20px)); }

.standard-vehicle {
  --input-padding-x: .50rem;
  --input-padding-y: .50rem; }
  .standard-vehicle.content-map .angular-google-map-container {
    min-height: 600px !important;
    min-width: 100%; }
  .standard-vehicle .form-label-group {
    position: relative;
    margin-bottom: 1rem; }
  .standard-vehicle .form-label-group > input,
  .standard-vehicle .form-label-group > label,
  .standard-vehicle .form-label-group > button {
    padding: var(--input-padding-y) var(--input-padding-x); }
  .standard-vehicle .no-margin {
    margin: 0px; }
  .standard-vehicle .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out; }
  .standard-vehicle .form-label-group input::-webkit-input-placeholder {
    color: transparent; }
  .standard-vehicle .form-label-group input:-ms-input-placeholder {
    color: transparent; }
  .standard-vehicle .form-label-group input::-ms-input-placeholder {
    color: transparent; }
  .standard-vehicle .form-label-group input::-moz-placeholder {
    color: transparent; }
  .standard-vehicle .form-label-group input::placeholder {
    color: transparent; }
  .standard-vehicle .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3); }
  .standard-vehicle .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777; }
  .standard-vehicle h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .standard-vehicle ul.services-10 {
    list-style: none; }
  .standard-vehicle .optional-services-title {
    color: #888888; }
  .standard-vehicle .optional-services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-row-gap: 15px; }
    .standard-vehicle .optional-services-list .optional-service {
      font-size: 40px;
      color: #bbbbbb;
      display: flex;
      align-items: center;
      justify-items: center;
      flex-direction: column;
      margin-right: 10px;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .standard-vehicle .optional-services-list .optional-service:focus {
        outline: none !important; }
      .standard-vehicle .optional-services-list .optional-service.active {
        color: #e74c3c; }
        .standard-vehicle .optional-services-list .optional-service.active .icon-2 {
          border: 1px solid #e74c3c;
          color: #e74c3c; }
      .standard-vehicle .optional-services-list .optional-service .icon-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 4px;
        color: #888888;
        border: 1px solid #888888;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        margin-bottom: 5px; }
      .standard-vehicle .optional-services-list .optional-service .optional-service-text {
        font-size: 12px;
        font-style: italic;
        text-align: center; }

.chin {
  margin-bottom: 1em; }

.main-trips {
  min-height: calc(100vh - (307px + 61px)); }

.no-margin {
  margin: 0px;
  padding: 0px; }

.main-checkout .evidence-content {
  padding-top: 15px;
  padding-bottom: 65px;
  background-color: white;
  border-bottom: #d7d8d9 1px solid;
  border-top: #d7d8d9 1px solid; }
  .main-checkout .evidence-content .trip-steps {
    margin-top: 5px;
    margin-bottom: 5px; }
  .main-checkout .evidence-content.top {
    border-top: none; }

.main-checkout .overlappping-content {
  margin-top: -68px; }

.main-checkout .company-description h1 {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold; }

.main-checkout .company-description .small {
  font-size: 18px;
  font-weight: normal; }

.main-checkout .company-description.disabled {
  text-decoration: line-through;
  color: #81888a; }

.main-checkout .trip-details h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: #d7d8d9 1px solid; }

.main-checkout .trip-details .angular-google-map-container {
  margin-top: 20px;
  border: #d7d8d9 1px solid !important;
  height: 350px; }

.main-checkout .prevnext button {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .main-checkout .prevnext button {
      margin-bottom: 0px; } }

.main-checkout .vehicle-details {
  padding-top: 15px;
  padding-bottom: 15px; }
  .main-checkout .vehicle-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .separator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .items {
    line-height: 2.5; }
    .main-checkout .vehicle-details .items b {
      font-weight: bold; }
  .main-checkout .vehicle-details .services {
    color: #81888a;
    display: inline;
    text-decoration: line-through; }
    .main-checkout .vehicle-details .services .icon {
      display: none; }
    .main-checkout .vehicle-details .services span {
      color: #81888a;
      line-height: 2.5; }
    .main-checkout .vehicle-details .services tip {
      width: 250px; }
    .main-checkout .vehicle-details .services.active {
      color: #555a5f;
      font-weight: bold;
      text-decoration: none; }
      .main-checkout .vehicle-details .services.active .icon {
        font-size: 24px;
        display: inline;
        margin-right: 5px; }
        .main-checkout .vehicle-details .services.active .icon.tooltips {
          font-size: 14px; }

.main-order .active-container {
  height: 350px; }
  .main-order .active-container img {
    max-height: 350px; }

.main-payment {
  min-height: calc(100vh - 450px); }
  .main-payment .content-gallery,
  .main-payment .content-gallery img {
    height: 200px; }
  .main-payment .evidence-content {
    padding-top: 15px;
    padding-bottom: 65px;
    background-color: white;
    border-bottom: #d7d8d9 1px solid;
    border-top: #d7d8d9 1px solid; }
    .main-payment .evidence-content.top {
      border-top: none; }
  .main-payment .overlappping-content {
    margin-top: -68px;
    margin-bottom: 20px; }
  .main-payment .section-description h1 {
    margin-top: 5px;
    font-size: 24px; }
  .main-payment .terms {
    font-size: 12px;
    margin: 5px 0 5px 0; }

.container-full {
  width: 100%;
  padding: 15px; }

.container-xl {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.table-container {
  overflow-x: auto; }

.ng-table-group-header {
  display: none; }

.table-container .multiSelect .show {
  position: fixed; }

.table-container .multiSelect > button {
  font-size: 0px;
  background-image: none;
  min-height: 0px !important;
  background: none;
  border: none;
  vertical-align: top; }

.table-container .multiSelect .caret {
  border: none;
  margin: 0px !important;
  width: 14px;
  height: 14px;
  background-image: url("../assets/images/icons/filter.svg");
  background-size: contain; }

/*
.grid {
    height: 98vh;

}
*/
.quotes .dropdown {
  position: absolute;
  z-index: 3001;
  top: 0px;
  left: 0px;
  min-width: 282px;
  max-width: 460px;
  line-height: normal;
  border-radius: 0 0 2.5px 2.5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  background: white; }
  .quotes .dropdown .item {
    display: block;
    color: black;
    position: relative;
    margin: 0; }
    .quotes .dropdown .item:first-child {
      border-top: none; }
    .quotes .dropdown .item :hover {
      background: rgba(0, 0, 0, 0.1); }

.dropdown-container {
  position: relative; }
  .dropdown-container .dropdown-wrapper {
    overflow: hidden; }

.icon-star {
  color: #f5ab35; }

.results-translation {
  margin-bottom: 20px;
  min-height: calc(100vh - (307px + 61px + 20px)); }
  .results-translation .container {
    width: 100% !important; }
  .results-translation .content {
    background-color: #ececec;
    max-width: 100% !important; }
    .results-translation .content .translation-block {
      margin-bottom: 15px;
      margin-left: 10px;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid #dddddd; }
      .results-translation .content .translation-block .translation-block-row {
        display: flex; }
        .results-translation .content .translation-block .translation-block-row.translation-section {
          background-color: #efefef;
          text-transform: uppercase;
          font-weight: 800;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          margin: 10px 0; }
        .results-translation .content .translation-block .translation-block-row .translation-block-key {
          flex: 0 0 450px;
          max-width: 450px;
          padding: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .results-translation .content .translation-block .translation-block-row .translation-block-translation {
          flex-grow: 1;
          flex-basis: 0;
          padding: 5px; }
          .results-translation .content .translation-block .translation-block-row .translation-block-translation.language {
            text-align: center;
            text-transform: uppercase;
            font-weight: 600; }

.main-users .header-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row; }
  .main-users .header-filters .header-filter {
    display: flex;
    position: relative; }
  .main-users .header-filters .icon {
    font-size: 18px; }
  .main-users .header-filters #full-text-search {
    padding-right: 20px; }
    .main-users .header-filters #full-text-search.filtered {
      border: 1px solid #e74c3c; }
  .main-users .header-filters .inside-input {
    position: absolute;
    right: 0;
    top: 1px; }

.main-trips {
  min-height: calc(100vh - (307px + 61px)); }

.main-checkout .evidence-content {
  padding-top: 15px;
  padding-bottom: 65px;
  background-color: white;
  border-bottom: #d7d8d9 1px solid;
  border-top: #d7d8d9 1px solid; }
  .main-checkout .evidence-content .trip-steps {
    margin-top: 5px;
    margin-bottom: 5px; }
  .main-checkout .evidence-content.top {
    border-top: none; }

.main-checkout .overlappping-content {
  margin-top: -68px; }

.main-checkout .company-description h1 {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold; }

.main-checkout .company-description .small {
  font-size: 18px;
  font-weight: normal; }

.main-checkout .company-description.disabled {
  text-decoration: line-through;
  color: #81888a; }

.main-checkout .trip-details h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: #d7d8d9 1px solid; }

.main-checkout .trip-details .angular-google-map-container {
  margin-top: 20px;
  border: #d7d8d9 1px solid !important;
  height: 350px; }

.main-checkout .prevnext button {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .main-checkout .prevnext button {
      margin-bottom: 0px; } }

.main-checkout .vehicle-details {
  padding-top: 15px;
  padding-bottom: 15px; }
  .main-checkout .vehicle-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 5px;
    border-bottom: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .separator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: #d7d8d9 1px solid; }
  .main-checkout .vehicle-details .items {
    line-height: 2.5; }
    .main-checkout .vehicle-details .items b {
      font-weight: bold; }
  .main-checkout .vehicle-details .services {
    color: #81888a;
    display: inline;
    text-decoration: line-through; }
    .main-checkout .vehicle-details .services .icon {
      display: none; }
    .main-checkout .vehicle-details .services span {
      color: #81888a;
      line-height: 2.5; }
    .main-checkout .vehicle-details .services tip {
      width: 250px; }
    .main-checkout .vehicle-details .services.active {
      color: #555a5f;
      font-weight: bold;
      text-decoration: none; }
      .main-checkout .vehicle-details .services.active .icon {
        font-size: 24px;
        display: inline;
        margin-right: 5px; }
        .main-checkout .vehicle-details .services.active .icon.tooltips {
          font-size: 14px; }

.main-order .active-container {
  height: 350px; }
  .main-order .active-container img {
    max-height: 350px; }

.main-payment {
  min-height: calc(100vh - 450px); }
  .main-payment .content-gallery,
  .main-payment .content-gallery img {
    height: 200px; }
  .main-payment .evidence-content {
    padding-top: 15px;
    padding-bottom: 65px;
    background-color: white;
    border-bottom: #d7d8d9 1px solid;
    border-top: #d7d8d9 1px solid; }
    .main-payment .evidence-content.top {
      border-top: none; }
  .main-payment .overlappping-content {
    margin-top: -68px;
    margin-bottom: 20px; }
  .main-payment .section-description h1 {
    margin-top: 5px;
    font-size: 24px; }
  .main-payment .terms {
    font-size: 12px;
    margin: 5px 0 5px 0; }

.container-full {
  width: 100%;
  padding: 15px; }

.container-xl {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.table-container {
  overflow-x: auto; }
  .table-container .multiSelect .show {
    position: fixed; }
  .table-container .multiSelect > button {
    font-size: 0px;
    background-image: none;
    min-height: 0px !important;
    background: none;
    border: none;
    vertical-align: top; }
  .table-container .multiSelect .caret {
    border: none;
    margin: 0px !important;
    width: 14px;
    height: 14px;
    background-image: url("../assets/images/icons/filter.svg");
    background-size: contain; }

.ng-table-group-header {
  display: none; }

.main-e404 {
  padding-top: 20px; }
