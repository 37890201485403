// -----------------------------------------------------------------------------
// This file contains the divider class
// -----------------------------------------------------------------------------
hr {
  &.divider {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba($color-border, 0), rgba($color-border, 1), rgba($color-border, 0));
  }
}
