// -----------------------------------------------------------------------------
// This file contains all styles related to the jumbo component.
// -----------------------------------------------------------------------------
.jumbo-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center top;
  background-size: cover;
  &.jumbo-opacity {
    &:before {
      content: '';
      background-color: rgba($color-grey-dark, 0.3);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.jumbo-fill {
    height: 100%;
    width: 100%;
  }
  &.jumbo-video {
    video {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;               // Put on background

      min-width: 100%;           // Expand video
      min-height: 100%;
      width: auto;               // Keep aspect ratio
      height: auto;

      transform: translate(-50%,-50%);  // Cover effect: compensate the offset
      overflow: hidden;
      top: 50%;                  // Vertical center offset
      left: 50%;                 // Horizontal center offset
    }
  }
  &.jumbo-vertical {
    transform-style: preserve-3d;
    .jumbo-content {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.jumbo-primary {
    .jumbo-content {
      color: $color-grey-white;
      h1 {
        font-weight: bold;
        font-size: $font-size-headline;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        @include respond-to(sm) { font-size: $font-size-h1; }
        @include respond-to(md) { font-size: $font-size-h2; }
        @include respond-to(lg) { font-size: $font-size-h3; }
        span {
          color: $color-brand-primary;
        }
      }
      h2 {
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        margin-top: 20px;
        font-weight: bold;
        font-size: $font-size-regular;
        @include respond-to(sm) { font-size: $font-size-medium; }
        @include respond-to(md) { font-size: $font-size-large; }
      }
      h3 {
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        margin-top: 20px;
        font-weight: bold;
        font-size: $font-size-small;
        @include respond-to(sm) { font-size: $font-size-regular; }
        @include respond-to(md) { font-size: $font-size-medium; }
      }
    }
  }

  .jumbo-content {
    position: relative;
    text-align: center;
    h1 { font-size: $font-size-h2; }
    h3 { font-size: $font-size-medium; }
  }
}
