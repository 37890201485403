// -----------------------------------------------------------------------------
// This file contains all styles related to the slider component.
// -----------------------------------------------------------------------------
.rzslider {
  margin-left: 1px;
  padding-left: 5px;
  padding-right: 5px;
  .rz-pointer {
    background-color: $color-brand-primary !important;
    &.rz-active {
      &::after {
        background-color: $color-brand-light !important;
      }
    }
  }
  .rz-bar {
    background-color: $color-border !important;
  }
  .rz-selection {
    background-color: $color-brand-primary !important;
  }
}
