// -----------------------------------------------------------------------------
// This file contains all styles related to the trip component.
// -----------------------------------------------------------------------------
.trip-steps {
  position: relative;
  .trip-steps-dot {
    top: 8px;
  }
  .trip-steps-revert {
    &.disabled {
      display: none;
    }
    position: absolute;
    top: 55%;
    left: 7.5px;
    .icon {
      color: $color-brand-mid;
      background-color: $color-grey-white;
      font-size: $font-size-large;
      border-radius: 50%;
      &:hover {
        color: $color-brand-primary;
        cursor: pointer;
      }
    }
  }
  .trip-steps-timeline {
    top: 18px;
  }
  li {
    position: relative;
  }

  .trip-steps-content {
    text-align: left;
    padding: 0 10px 10px 0;
    margin-left: 41px;
    .icon {
      margin: 0 5px;
    }
    .city {
      font-size: $font-size-regular;
    }
    .address {
      font-size: $font-size-small;
    }
    .date {
      text-align: right;
      font-size: $font-size-small;
    }
    .layover {
      text-align: right;
      font-size: $font-size-small;
    }
  }

  .trip-steps-dot {
    left: 6.5px;
    position: absolute;
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $color-grey-white;
    border: 6px solid $color-brand-primary;
    &.warehouse {
      border-color: $color-brand-light !important;
      &.top:before {
        border-bottom-color: $color-brand-light !important;
      }
      &.bottom:before {
        border-top-color: $color-brand-light !important;
      }
    }
    &.top {
      border-color: $color-brand-mid;
      background: $color-brand-mid;
      &:before {
        border: solid transparent;
        border-bottom-color: $color-brand-mid;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 8px;
        left: -4px;
        top: -17px;
      }
      &:after {
        content: '+';
        color: $color-grey-white;
        position: absolute;
        top: -5.5px;
        left: -1px;
        font-weight: bold;
        font-size: $font-size-large;
        text-align: center;
      }
    }
    &.plus {
      width: 40px;
      height: 40px;
      border-color: $color-brand-mid;
      background: $color-brand-mid;
      &:after {
        content: '+';
        color: $color-grey-white;
        position: absolute;
        top: 6px;
        left: 8px;
        font-weight: bold;
        font-size: $font-size-large;
        text-align: center;
      }
    }
    &.bottom {
      &:before {
        // border: solid transparent;
        border-top-color: $color-brand-primary;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 8px;
        left: -4px;
        top: 9px;
      }
    }
    &.disabled {
      border-color: $color-brand-light;
      &.top {
        background: $color-brand-light;
        &:before {
          border-bottom-color: $color-brand-light;
        }
      }
      &.bottom {
        &:before {
          border-top-color: $color-brand-light;
        }
      }
    }
  }
  .trip-steps-timeline {
    left: 14.5px;
    position: absolute;
    height: 100%;
    width: 4px;
    border-left: 4px solid $color-brand-primary;
    &.lay {
      border-left-color: $color_border;
      border-left-style: dotted;
    }
    &.warehouse {
      border-left-color: $color-brand-light;
    }
    &.last {
      border-left-style: dotted;
      border-left-color: $color-brand-mid;
    }
    &.disabled {
      border-left-color: $color-brand-light;
    }
  }
  &.trip-steps-show {
    padding-top: 10px;
    .trip-steps-dot {
      top: 5px;
      border-color: $color-brand-primary;
      &.top {
        background: $color-grey-white;
        &:before {
          border-bottom-color: $color-brand-primary;
        }
        &:after {
          content: '';
        }
      }
    }
  }
}
