// -----------------------------------------------------------------------------
// This file contains all styles related to the tooltip component.
// -----------------------------------------------------------------------------
tip-cont {
  cursor: pointer;
}
tooltip {
  tip {
    width: 240px;
    color: $color-grey-white;
    background-color: $color-grey-black;
    font-size: $font-size-regular;
    font-weight: normal;
    padding: 7.5px 10px;
    opacity: 0.8 !important;
    transition: none !important;
    animation: none !important;
  }
  &._top {
    tip-arrow {
      border-top-color: $color-grey-black !important;
    }
  }
  &._right {
    tip-arrow {
      border-right-color: $color-grey-black !important;
    }
  }
  &._bottom {
    tip-arrow {
      border-bottom-color: $color-grey-black !important;
    }
  }
  &._left {
    tip-arrow {
      border-left-color: $color-grey-black !important;
    }
  }
  &._multiline {
    display: inline-block;
  }
}

.tooltip {
  cursor: pointer;
  position: relative;
  display: inline;
  .tip {
    position: absolute;
    width: 140px;
    color: $color-grey-white;
    background-color: $color-grey-black;
    height: 30px;
    line-height: 30px;
    text-align: center;
    visibility: hidden;
    border-radius: 6px;
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -2px;
      width: 0; height: 0;
      border-top: 8px solid $color-grey-black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
  &:hover {
    .tip {
      font-size: $font-size-regular;
      font-weight: normal;
      visibility: visible;
      opacity: 0.8;
      bottom: 30px;
      left: 50%;
      margin-left: -76px;
      z-index: 999;
    }
  }
}
