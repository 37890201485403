// -----------------------------------------------------------------------------
// This file contains all styles related to the breadcumb component.
// -----------------------------------------------------------------------------
$timeline-h-size-step:                  30px;
$timeline-h-size-line:                  8px;
$timeline-h-size-border:                2px;
$timeline-h-size-label:                 110px;
$timeline-h-size-boxShadow:             5px;
$timeline-h-title-distance:             30px;
$timeline-h-title-size-font:            12px;
$timeline-h-label-distance:             10 + ($timeline-h-size-step/2) + $timeline-h-size-boxShadow;


$timeline-h-summary-size-step:          12px;
$timeline-h-summary-size-border:        2px;
$timeline-h-summary-size-line:          2px;
$timeline-h-summary-size-label:         110px;
$timeline-h-summary-size-boxShadow:     3px;
$timeline-h-summary-title-distance:     10px;
$timeline-h-summary-title-size-font:    8px;
$timeline-h-summary-label-distance:     10 + ($timeline-h-summary-size-step/2) + $timeline-h-summary-size-boxShadow;

.breadcumb-container {
  &.breadcumb-order {
    height: 100%;
    background-color: $color-brand-mid;
    padding: 35px 0 60px 0;
    ol {
      padding: 0;
      border-top: $timeline-h-size-line solid $color-grey-light;
      list-style: none;
    }
    .breadcumb-step {
      float: left;
      width: 25%;

      position: relative;
      &:first-of-type,
      &:last-of-type {
        &:before {
          content: "";
          width: 50%;
          height: $timeline-h-size-line+2;

          background: $color-brand-mid;

          position: absolute;
          top: -($timeline-h-size-line+1);
          left: 0;
        }
      }
      &:last-of-type:before {
        left: 50%;
      }

      .breadcumb-step-title {
        color: $color-grey-white;
        display: block;
        padding: $timeline-h-title-distance 0 0;

        font-size: $font-size-medium;
        text-align: center;
        a {
          color: $color-grey-white;
        }
      }

      .breadcumb-step-marker {
        width: $timeline-h-size-step;
        height: $timeline-h-size-step;
        margin-left: -$timeline-h-size-step/2;

        background: $color-grey-white;
        border: $timeline-h-size-border solid $color-grey-white;
        border-radius: 50%;
        box-shadow: 0 0 0 $timeline-h-size-boxShadow $color-grey-white;

        text-align: center;
        line-height: $timeline-h-size-step -10;
        color: $color-brand-primary;
        font-size: $font-size-medium;
        font-style: normal;
        line-height: $timeline-h-size-step*0.85;
        font-weight: bold;

        position: absolute;
        top: -($timeline-h-size-step/2)-($timeline-h-size-line/2);
        left: 50%;
        
        a {
          color: $color-brand-primary;
        }

        &.done {
          cursor: pointer;
          background: $color-brand-primary;
          color: $color-grey-white;
          a {
            color: $color-grey-white;
          }
        }
        &.active {
          background: $color-brand-mid;
          color: $color-grey-white;
          a {
            color: $color-grey-white;
          }
        }
      }
    }
  }
}
