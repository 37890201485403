// -----------------------------------------------------------------------------
// This file contains all external fonts used
// -----------------------------------------------------------------------------
@font-face {
    font-family: 'homemade-apple';
    src: font('homemadeapple-webfont.eot');
    src: font('homemadeapple-webfont.eot?#iefix') format('embedded-opentype'),
         font('homemadeapple-webfont.woff2') format('woff2'),
         font('homemadeapple-webfont.woff') format('woff'),
         font('homemadeapple-webfont.svg#homemade-apple') format('svg');
    font-weight: normal;
    font-style: normal;
}
