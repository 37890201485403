// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.tag {
  font-size: $font-size-regular;
  display: inline;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;

  & {
    color: $color-text;
    background-color: $color-button;
    border: $color-button-border 1px solid;
    border-radius: 2.5px;
  }
  &a, a {
    @include on-event(true) {
      color: $color-text;
    }
  }
  &:hover {
    text-decoration: none;
  }
  &.tag-grey {
    color: $color-grey-white;
    background-color: $color-button-hover;
    border-color: $color-button-hover-border;
  }
  &.tag-green {
    color: $color-grey-white;
    background-color: $color-green-primary;
    border-color: $color-green-mid;
  }
  &.tag-red {
    color: $color-grey-white;
    background-color: $color-red-primary;
    border-color: $color-red-mid;
  }
  &.tag-yellow {
    color: $color-grey-white;
    background-color: $color-yellow-primary;
    border-color: $color-yellow-mid;
  }
  &::-moz-focus-inner {
    border: 0;
  }
  &.tag-mrg {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &.tag-mrg-top {
    margin-top: 10px;
  }
  &.tag-mrg-bottom {
    margin-top: 10px;
  }
  &.tag-sm {
    padding: 4px 12px;
  }
  &.tag-md {
    padding: 7px 21px;
  }
  &.tag-lg {
    font-size: $font-size-medium;
    font-weight: bold;
    padding: 10px 15px;
  }
  &.tag-block {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
  }
}
