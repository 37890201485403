// -----------------------------------------------------------------------------
// This file contains all icons from our font.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'br-icon';
  src: font('br-icon.eot');
  src: font('br-icon.eot#iefix') format('embedded-opentype'),
  font('br-icon.woff2') format('woff2'),
  font('br-icon.woff') format('woff'),
  font('br-icon.ttf') format('truetype'),
  font('br-icon.svg#br-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'br-icons-2';
  src: font('br-icons-2.eot');
  src: font('br-icons-2.eot#iefix') format('embedded-opentype'),
  font('br-icons-2.woff') format('woff'),
  font('br-icons-2.ttf') format('truetype'),
  font('br-icons-2.svg#br-icons-2') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'br-icon';
    src: font('br-iconsvg#br-icon') format('svg');
  }
}
.icon {

  &:before {
    font-family: "br-icon";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes */
    font-variant: normal;
    text-transform: none;

    /* fix buttons height */
    /* line-height: 1em; */

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }


  @font-face {
    font-family: 'br-icon';
    src: url('../fonts/br-icon.eot?51431517');
    src: url('../fonts/br-icon.eot?51431517#iefix') format('embedded-opentype'),
         url('../fonts/br-icon.woff?51431517') format('woff'),
         url('../fonts/br-icon.ttf?51431517') format('truetype'),
         url('../fonts/br-icon.svg?51431517#br-icon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'br-icon';
    src: url('../fonts/br-icon.svg?51431517#br-icon') format('svg');
  }
}
*/

  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "br-icon";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
  &.icon-heart:before { content: '\e800'; } /* '' */
  &.icon-basket:before { content: '\e801'; } /* '' */
  &.icon-coffee-machine:before { content: '\e802'; } /* '' */
  &.icon-connection:before { content: '\e803'; } /* '' */
  &.icon-dvd:before { content: '\e804'; } /* '' */
  &.icon-fridge:before { content: '\e805'; } /* '' */
  &.icon-idea:before { content: '\e806'; } /* '' */
  &.icon-karaoke:before { content: '\e807'; } /* '' */
  &.icon-leather:before { content: '\e808'; } /* '' */
  &.icon-clock:before { content: '\e809'; } /* '' */
  &.icon-map:before { content: '\e80a'; } /* '' */
  &.icon-location:before { content: '\e80b'; } /* '' */
  &.icon-share:before { content: '\e80c'; } /* '' */
  &.icon-star:before { content: '\e80d'; } /* '' */
  &.icon-luggage:before { content: '\e80e'; } /* '' */
  &.icon-monitor:before { content: '\e80f'; } /* '' */
  &.icon-music-player:before { content: '\e810'; } /* '' */
  &.icon-oven:before { content: '\e811'; } /* '' */
  &.icon-plug:before { content: '\e812'; } /* '' */
  &.icon-radio:before { content: '\e813'; } /* '' */
  &.icon-arrow-up:before { content: '\e814'; } /* '' */
  &.icon-arrow-right:before { content: '\e815'; } /* '' */
  &.icon-arrow-down:before { content: '\e816'; } /* '' */
  &.icon-arrow-left:before { content: '\e817'; } /* '' */
  &.icon-open-up:before { content: '\e818'; } /* '' */
  &.icon-open-right:before { content: '\e819'; } /* '' */
  &.icon-open-down:before { content: '\e81a'; } /* '' */
  &.icon-open-left:before { content: '\e81b'; } /* '' */
  &.icon-star-half:before { content: '\e81c'; } /* '' */
  &.icon-menu:before { content: '\e81d'; } /* '' */
  &.icon-thick:before { content: '\e81e'; } /* '' */
  &.icon-television:before { content: '\e81f'; } /* '' */
  &.icon-toilet:before { content: '\e820'; } /* '' */
  &.icon-usb:before { content: '\e821'; } /* '' */
  &.icon-wheelchair:before { content: '\e822'; } /* '' */
  &.icon-cog:before { content: '\e823'; } /* '' */
  &.icon-window:before { content: '\e824'; } /* '' */
  &.icon-comment:before { content: '\e825'; } /* '' */
  &.icon-driver:before { content: '\e826'; } /* '' */
  &.icon-window-tinted:before { content: '\e827'; } /* '' */
  &.icon-phone:before { content: '\e828'; } /* '' */
  &.icon-info:before { content: '\e829'; } /* '' */
  &.icon-emo-unhappy:before { content: '\e82a'; } /* '' */
  &.icon-air-conditioner:before { content: '\e82b'; } /* '' */
  &.icon-emo-happy:before { content: '\e82c'; } /* '' */
  &.icon-warn:before { content: '\e82d'; } /* '' */
  &.icon-lock-open:before { content: '\e82e'; } /* '' */
  &.icon-cookie:before { content: '\e82f'; } /* '' */
  &.icon-add:before { content: '\e830'; } /* '' */
  &.icon-cancel:before { content: '\e831'; } /* '' */
  &.icon-login:before { content: '\e832'; } /* '' */
  &.icon-logout:before { content: '\e833'; } /* '' */
  &.icon-search:before { content: '\e834'; } /* '' */
  &.icon-peoples:before { content: '\e835'; } /* '' */
  &.icon-user-male:before { content: '\e836'; } /* '' */
  &.icon-leaf:before { content: '\e837'; } /* '' */
  &.icon-chat:before { content: '\e838'; } /* '' */
  &.icon-spin:before { content: '\e839'; } /* '' */
  &.icon-flash:before { content: '\e83a'; } /* '' */
  &.icon-download:before { content: '\e83b'; } /* '' */
  &.icon-pencil-1:before { content: '\e83c'; } /* '' */
  &.icon-credit-card:before { content: '\e83d'; } /* '' */
  &.icon-bus:before { content: '\e83e'; } /* '' */
  &.icon-child:before { content: '\e83f'; } /* '' */
  &.icon-filter:before { content: '\e840'; } /* '' */
  &.icon-calendar:before { content: '\e841'; } /* '' */
  &.icon-pencil:before { content: '\e842'; } /* '' */
  &.icon-chart-bar:before { content: '\e843'; } /* '' */
  &.icon-eye-off:before { content: '\e844'; } /* '' */
  &.icon-eye:before { content: '\e845'; } /* '' */
  &.icon-updown-circle:before { content: '\e846'; } /* '' */
  &.icon-export:before { content: '\e847'; } /* '' */
  &.icon-arrows-cw:before { content: '\e848'; } /* '' */
  &.icon-print:before { content: '\e849'; } /* '' */
  &.icon-bold:before { content: '\e84a'; } /* '' */
  &.icon-italic:before { content: '\e84b'; } /* '' */
  &.icon-ccw:before { content: '\e84c'; } /* '' */
  &.icon-cw:before { content: '\e84d'; } /* '' */
  &.icon-align-left:before { content: '\e84e'; } /* '' */
  &.icon-align-right:before { content: '\e84f'; } /* '' */
  &.icon-align-center:before { content: '\e850'; } /* '' */
  &.icon-cancel-circled:before { content: '\e851'; } /* '' */
  &.icon-link:before { content: '\e852'; } /* '' */
  &.icon-picture:before { content: '\e853'; } /* '' */
  &.icon-block:before { content: '\e854'; } /* '' */
  &.icon-align-justify:before { content: '\e855'; } /* '' */
  &.icon-filter-1:before { content: '\f0b0'; } /* '' */
  &.icon-docs:before { content: '\f0c5'; } /* '' */
  &.icon-floppy:before { content: '\e856'; } /* '' */
  &.icon-list-bullet:before { content: '\f0ca'; } /* '' */
  &.icon-list-numbered:before { content: '\f0cb'; } /* '' */
  &.icon-underline:before { content: '\f0cd'; } /* '' */
  &.icon-mail-alt:before { content: '\f0e0'; } /* '' */
  &.icon-chat-empty:before { content: '\f0e6'; } /* '' */
  &.icon-paste:before { content: '\f0ea'; } /* '' */
  &.icon-quote-left:before { content: '\f10d'; } /* '' */
  &.icon-quote-right:before { content: '\f10e'; } /* '' */
  &.icon-code:before { content: '\f121'; } /* '' */
  &.icon-shield:before { content: '\f132'; } /* '' */
  &.icon-tree:before { content: '\f1bb'; } /* '' */
  &.icon-sliders:before { content: '\f1de'; } /* '' */
  &.icon-whatsapp:before { content: '\f232'; } /* '' */
  &.icon-twitter:before { content: '\f309'; } /* '' */
  &.icon-facebook-squared:before { content: '\f30e'; } /* '' */
  &.icon-globe:before { content: '\e857'; } /* '' */
  &.icon-users:before { content: '\e858'; } /* '' */
  &.icon-ok-circle:before { content: '\e859'; } /* '' */
  &.icon-check:before { content: '\f30f'; } /* '' */
}

.icon-2 {
  &:before {
    font-family: "br-icons-2";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes */
    font-variant: normal;
    text-transform: none;

    /* fix buttons height */
    /* line-height: 1em; */

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    vertical-align: middle;
  }

  @font-face {
    font-family: 'br-icons-2';
    src: url('../fonts/br-icons-2.eot?51431517');
    src: url('../fonts/br-icons-2.eot?51431517#iefix') format('embedded-opentype'),
    url('../fonts/br-icons-2.woff2?51431517') format('woff2'),
    url('../fonts/br-icons-2.woff?51431517') format('woff'),
    url('../fonts/br-icons-2.ttf?51431517') format('truetype'),
    url('../fonts/br-icons-2.svg?51431517#br-icons-2') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  [class*='icon-']:before {
    display: inline-block;
    font-family: 'br-icons-2';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  &.icon-air-conditioner:before{content:'\0041';}
  &.icon-alarm:before{content:'\0042';}
  &.icon-coffee-machine:before{content:'\0043';}
  &.icon-fridge:before{content:'\0044';}
  &.icon-leather:before{content:'\0045';}
  &.icon-microphone:before{content:'\0046';}
  &.icon-obscured-glasses:before{content:'\0047';}
  &.icon-panoramic:before{content:'\0048';}
  &.icon-plugs:before{content:'\0049';}
  &.icon-radio:before{content:'\004a';}
  &.icon-reading-light:before{content:'\004b';}
  &.icon-services:before{content:'\004c';}
  &.icon-tv:before{content:'\004d';}
  &.icon-usb:before{content:'\004e';}
  &.icon-wc:before{content:'\004f';}
  &.icon-wheelchair:before{content:'\0050';}
  &.icon-wifi:before{content:'\0051';}
  &.icon-check:before{content:'\0052';}
  &.icon-edit:before{content:'\0053';}
  &.icon-lock:before{content:'\0054';}
  &.icon-share:before{content:'\0055';}
  &.icon-star-empty:before{content:'\0056';}
  &.icon-star-full:before{content:'\0057';}
  &.icon-zoom-in:before{content:'\0058';}
  &.icon-zoom-out:before{content:'\0059';}
  &.icon-blue-clock:before{content:'\005a';}
  &.icon-luggages:before{content:'\0061';}
  &.icon-kitchen:before{content:'\0062';}
  &.icon-services:before{content:'\0063';}
  &.icon-refresh:before{content:'\0064';}
  &.icon-view:before{content:'\0065';}
  &.icon-copy:before{content:'\0066';}
  &.icon-garbage:before{content:'\0067';}
  &.icon-moon:before{content:'\0068';}
  &.icon-alarm_active:before{content:'\0069';}
}
/*
   Animation example, for spinners
*/
.animate-spin {
  animation: spin 0.5s infinite linear;
  display: inline-block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}

