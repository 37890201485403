// -----------------------------------------------------------------------------
// This file contains all the animation used.
// -----------------------------------------------------------------------------
@keyframes collapse {
  0% { max-height: 999px; }
  100% { max-height: 0px; }
}
@keyframes uncollapse {
  0% { max-height: 0px; }
  100% { max-height: 999px;}
}
@keyframes slide {
  0% { max-width: 999px; }
  100% { max-width: 0px; }
}
@keyframes unslide {
  0% { max-width: 0px; }
  100% { max-width: 999px;}
}

@-webkit-keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
