.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba($color-grey-black, 0.7);
  padding: 15px;
  text-align:center;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;

    background-color: $color-content;
    position: relative;
    width: auto;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    border-radius: 2.5px;
    max-width: 600px;
    .close {
      position: absolute;
      right: -2px;
      top: -4px;
      font-size: $font-size-h1;
      cursor: pointer;
    }
  }
}
