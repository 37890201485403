// -----------------------------------------------------------------------------
// This file contains all thigs related to the typography.
// -----------------------------------------------------------------------------


@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

$text-font: 'Roboto', sans-serif !default;

$font-size-small: 12px !default;
$font-size-regular: 14px !default;
$font-size-medium: 16px !default;
$font-size-large: 18px !default;

$font-size-subhead: 16px !default;
$font-size-title: 20px !default;
$font-size-headline: 24px !default;
$font-size-h1: 34px !default;
$font-size-h2: 45px !default;
$font-size-h3: 56px !default;
$font-size-h4: 112px !default;
